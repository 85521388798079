import ava from "../../assets/svg/ava.jpg"
import aca from "../../assets/download.wav"
import { ChatSaga } from "./saga";
import { pusher } from "./constants";
const initialState = {
    data: [],
    activeChat: null,
    main: "main",
    loading: false,
    refs: false,
    channels: [],
    users: [],
    pusher_channels:[],
    SearchEnable: false,
    user_loading: false,
    newChats: [],
    date: "Today",
    chatUsers: [],
    call: null,
    fetch: true,
    ref: false,
    isCallIncoming: false,
    incomeCallData: null,
    incomeCallType: null,
    caller: [],
    callerChannel: null,
    callInProgress: false,
    isReachTheFinalMes: false
}
const showDate = (d) => {
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let now = new Date();
    let nowString = `${now.getFullYear()}-${(now.getMonth() + 1) > 9 ? (now.getMonth() + 1).toString() : ("0" + (now.getMonth() + 1).toString())}-${(now.getDate()) > 9 ? now.getDate() : "0" + parseInt(now.getDate()).toString()}`

    let day = new Date(d)
    day = days[day.getDay()]
    if (d === nowString)
        return ("Today")
    else if ((new Date(nowString) - new Date(d)) === 86400000) {
        return ("Yesterday")
    }
    else if ((new Date(nowString) - new Date(d)) < (86400000 * 6))
        return (day)
    else
        return (d)
}
export const ChatReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case "USER_END_CALL": {
            return ({
                ...state,
                call: null,
                callInProgress: false,
                isCallIncoming: false
            })
        }
        case "ME_END_CALL": {
            const userId = JSON.parse(localStorage.getItem("user")).id
            let chs = pusher.subscribe(`presence-video-call-${state.activeChat?.id?.toString(16)}`)
            chs.trigger(`client-end-call-${userId}`, {
                type: 'signal',
                data: {
                    userId
                }
            })
            return ({
                ...state,
                call: null,
                callInProgress: false,
                isCallIncoming: false
            })
        }
        case "VIDEO_CALL": {
            return ({
                ...state,
                call: "vid",
                callInProgress: true
            })
        }
        case "AUDIO_CALL": {
            return ({
                ...state,
                call: "aud",
                callInProgress: true
            })
        }
        case "REFUSE_CALL": {
            const userId = JSON.parse(localStorage.getItem("user")).id
            let chs = pusher.subscribe(`presence-video-call-${state.callerChannel?.id?.toString(16)}`)
            chs.trigger(`client-refuse-${userId}`, {
                type: 'signal',
                data: { userId }
            })
            return ({
                ...state,
                call: false,
                isCallIncoming: false,
                incomeCallData: null,
                caller: null,
                callerChannel: null
            })
        }
        case "ANSWER_CALL": {
            if (state.incomeCallType === "audio") {
                return ({
                    ...state,
                    call: "aud",
                    activeChat: { ...state.callerChannel },
                    main: 'chat',
                    isCallIncoming: false,
                    callInProgress: true
                })
            } else {
                return ({
                    ...state,
                    call: "vid",
                    activeChat: { ...state.callerChannel },
                    main: 'chat',
                    isCallIncoming: false,
                    callInProgress: true
                })
            }

        }
        case "INCOMING_VOICE_CALL": {
            if (state.callInProgress) {
                return ({
                    ...state
                })
            } else {
                return ({
                    ...state,
                    isCallIncoming: true,
                    incomeCallData: payload.signal,
                    caller: payload.caller,
                    callerChannel: payload.callerChannel,
                    incomeCallType: "audio"
                })
            }
        }
        case "INCOMING_CALL": {
            if (state.callInProgress) {
                return ({
                    ...state
                })
            } else {
                return ({
                    ...state,
                    isCallIncoming: true,
                    incomeCallData: payload.signal,
                    caller: payload.caller,
                    callerChannel: payload.callerChannel,
                    incomeCallType: "video"
                })
            }
        }
        case "CALL": {
            let pa = null
            if (payload) {
                pa = state.data.filter((s) => s.pusher_channel_name === payload.channel)[0]

            }
            return ({
                ...state,
                call: pa
            })
        }
        case "PUSHER_CHANNEL":{
           let  arr=state.pusher_channels
            if(state.pusher_channels.filter((s)=>s.id===payload.id).length===0){
                arr.push(payload)
            }
            return({
                ...state,
                pusher_channels:arr
            })
        }
        case "PUSHER_CH": {
            return ({
                ...state,
                channels: [...state.channels, payload]
            })
        }
        case "SEARCH_CHAT": {
            return ({
                ...state,
                SearchEnable: payload
            })
        }
        case "CONV_DATWE": {
            let mesDate = new Date(payload)
            mesDate = `${mesDate.getFullYear()}-${(mesDate.getMonth() + 1) > 9 ? (mesDate.getMonth() + 1).toString() : ("0" + (mesDate.getMonth() + 1).toString())}-${(mesDate.getDate()) > 9 ? mesDate.getDate() : "0" + parseInt(mesDate.getDate()).toString()}`
            return ({
                ...state,
                date: showDate(mesDate)
            })
        }
        case "SEND_MES_RED_NEW": {
            return ({
                ...state,
                activeChat: payload.channel,
                data: [payload.channel, ...state.data],
                main: "chat",
                ref: !state.ref
            })
        }
        case "CHAT_LOADING_USER": {
            return ({
                ...state,
                user_loading: true
            })
        }
        case "CHAT_DONE_USER": {
            return ({
                ...state,
                user_loading: false
            })
        }
        case "SEARCH_USER_RED": {
            return ({
                ...state,
                users: payload
            })
        }
        case "PUSHER_RED": {
           
            return ({
                ...state,
                channels: payload
            })
        }
        case "REFS": {
            return ({
                ...state,
                refs: !state.refs
            })
        }
        case "IS_TYPING_TRUE": {
           
            let id = payload.id
            let uid = payload.uid
            let active = null
            let chs = []
            if (uid !== parseInt(localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).id)) {
                state.data.map((ch) => {
                    if (parseInt(ch.id) === parseInt(id))
                        chs.push({ ...ch, status: payload.desc })
                    else chs.push(ch)
                })
                if (state.activeChat && state.activeChat.id) {
                    active = { ...state.activeChat, status: payload.desc }
                }
                return ({
                    ...state,
                    data: [...chs],
                    activeChat: active

                })
            }
            else
                return ({
                    ...state
                })
        }
        case "WATCH_CHANNEL_RED": {
            let id = payload
            let newChats = []
            let active = null
            state.data.map((a) => {
                if (a.id === id) {
                    let m = []
                    a.messages.map((mes) => {
                        let newst = []
                        let st = mes.message_status
                        st.map((sta) => {
                            let newdate = new Date()
                            if (sta.user_id !== JSON.parse(localStorage.getItem("user")).id) {
                                newst.push({ ...sta, is_watched: true, watched_at: sta.watched_at ? sta.watched_at : newdate.toLocaleString().toString() })
                            }
                            else {
                                if (sta.watched_at)
                                    newst.push({ ...sta, is_watched: true, is_received: 1 })
                                else
                                    newst.push({ ...sta, is_watched: true, is_received: 1, watched_at: new Date().toLocaleString().toString() })

                            }
                        })
                        m.push({ ...mes, message_status: newst })
                    })
                    newChats.push({ ...a, messages: m })
                    if (state.activeChat && state.activeChat.id) {
                        active = state.activeChat
                        if (active.id === payload) {
                            active = { ...a, messages: m }
                        }
                    }
                }
                else {
                    newChats.push(a)
                }
            })
            return ({
                ...state,
                data: newChats,
                activeChat: active,
                newChats: state.newChats.filter((a) => a.id !== payload)
            })
        }
        case "REC_CHANNEL_RED": {
            let id = payload
            let newChats = []
            let active = null
            state.data.map((a) => {
                if (a.id === id) {
                    let m = []
                    a.messages.map((mes) => {
                        let newst = []
                        let st = mes.message_status
                        st.map((sta) => {
                            let newdate = new Date()
                            if (sta.user_id !== JSON.parse(localStorage.getItem("user")).id) {
                                newst.push({ ...sta, is_received: 1, received_at: sta.received_at ? sta.received_at : newdate.toLocaleString().toString() })
                            }
                            else {
                                if (sta.received_at)
                                    newst.push({ ...sta, is_received: 1 })
                                else
                                    newst.push({ ...sta, is_received: 1, received_at: new Date().toLocaleString().toString() })

                            }
                        })
                        m.push({ ...mes, message_status: newst })
                    })
                    newChats.push({ ...a, messages: m })
                    if (state.activeChat && state.activeChat.id) {
                        active = state.activeChat
                        if (active.id === payload) {
                            active = { ...a, messages: m }
                        }
                    }
                }
                else {
                    newChats.push(a)
                }
            })
            return ({
                ...state,
                data: newChats,
                activeChat: active,
                newChats: [...state.newChats]
            })
        }
        case "OPEN-CHAT": {
            if (payload && payload.id) {
                let s = state.newChats.filter((a) => a.id !== payload.id)
                return ({
                    ...state,
                    activeChat: state.data.filter((a) => a.id === payload.id).length > 0 ? state.data.filter((a) => a.id === payload.id)[0] : payload,
                    newChats: [...s]
                })
            }
            else {
                return ({
                    ...state,
                    activeChat: null,
                    newChats: state.newChats,
                    fetch: true
                })
            }
        }
        case "SEND-MESSAGE": {
            let ac = payload.act;

            let chat = state.data
            chat.map((a) => {
                if (a.id === ac.id) {
                    a.messages.push(payload.message)
                }
            })
            let arr = []
            chat.map((a) => {
                if (a.id === ac.id) {
                    arr.push(a)
                }
            })
            chat.map((a) => {
                if (a.id !== ac.id) {
                    arr.push(a)
                }
            })
            return ({
                ...state,
                data: arr,
                activeChat: arr.filter((t) => t.id === state.activeChat?.id)[0],
                ref: !state.ref

            })
        }
        case "SEND_MES_RED": {
            let ac = payload.cid;

            let chat = state.data
            let act = null
            let chatData = []


            chat.map((a) => {
                if (a.id === ac) {
                    let mar = []
                    let sele = a.messages
                    sele.map((m) => {
                        if (m.mid && m.mid === payload.mid&&mar.filter((S)=>S.id===payload.id).length===0) {
                            mar.push(payload)
                        }
                        else {
                            mar.push(m)
                        }
                    })
                    if (payload.recive && payload.sender_user_id !== JSON.parse(localStorage.getItem("user")).id&&mar.filter((S)=>S.id===payload.id).length===0) {
                        mar.push(payload)
                    }
                    chatData.push({ ...a, messages: [...mar] })
                }
                else {
                    chatData.push(a)
                }
            })
            if (state.activeChat && state.activeChat.id && state.activeChat.id === ac) {
                act = chatData.filter((a) => a.id === ac)[0]
            }

            else {
                if (state.activeChat && state.activeChat.id) {
                    act = state.activeChat
                }
            }
            let arr = []
            chatData.map((a) => {
                if (a.id === ac) {
                    arr.push(a)
                }
            })
            chatData.map((a) => {
                if (a.id !== ac) {
                    arr.push(a)
                }
            })
            let news = []
            if (payload.recive && state.activeChat && state.activeChat && payload.cid !== state.activeChat.id) {
                if (state.newChats.filter((a) => a.id === payload.cid).length === 0)
                    news = [...state.newChats, state.data.filter((f) => f.id === payload.cid)[0]]
                else
                    if (!state.activeChat && !state.activeChat.id && payload.recive)
                        news = [...state.newChats, state.data.filter((f) => f.id === payload.cid)[0]]
            }
            if (state.activeChat && state.activeChat.id) {

            }
            else {

                news = [...state.newChats, state.data.filter((f) => f.id === payload.cid)[0]]
            }
            
            return ({
                ...state,
                data: [...arr],
                activeChat:act? { ...act }:null,
                newChats: [...news],
                ref: !state.ref
            })
        }
        case "MAIN": {
            return ({
                ...state,
                main: payload
            })
        }
        case "GET_CHAT_RED": {
            let arr = []
            let chatData = []
            let users = []
            payload.map((a) => {
                let unique = a.channel_members.filter((df) => df.user_id !== (JSON.parse(localStorage.getItem("user")).id))[0]
                users.push(unique.user_id)
                chatData.push({ ...a, messages: a.messages.reverse() })
            })
            let temp = state.activeChat
            if (state.activeChat && state.activeChat.id && payload.filter((a) => a.id === state.activeChat.id).length > 0) {
                temp = payload.filter((a) => a.id === state.activeChat.id)[0]
            }
            if (state.data.length !== payload.length) {

                payload.map((adsd) => {
                    if (state.data.filter((ch) => ch.id === adsd.id).length === 0) {
                        if (adsd.messages.filter((mes) => mes.message_status.filter((st) => st.user_id === JSON.parse(localStorage.getItem("user")).id)[0]?.is_watched === false).length > 0)
                            arr.push(adsd)
                    }
                })
            }
            return ({
                ...state,
                data: chatData,
                activeChat: temp?.id?{ ...temp }:null,
                newChats: arr,
                chatUsers: users
            })
        }
        case "CHAT_LOADING": {
            return ({
                ...state,
                loading: true
            })
        }
        case "CHAT_DONE": {
            return ({
                ...state,
                loading: false
            })
        }
        case "DEL_CHAT": {
            return ({
                ...state,
                data: state.data.filter((a) => a.id !== payload)
            })
        }
        case "GRP": {
            if (payload?.mes?.length === 0) {
                return {
                    ...state,
                    isReachTheFinalMes: true
                }
            }
            let arr = []
            let active = state.activeChat
            state.data.map((ch) => {
                if (ch.id === payload.ch) {
                    let mrr = []
                    payload.mes.map((m) => {
                        if (mrr.filter((s) => s.id === m.id).length === 0) {
                            mrr = [m, ...mrr]
                        }
                    })
                    ch.messages.map((m) => {
                        if (mrr.filter((s) => s.id === m.id).length === 0) {
                            mrr.push(m)
                        }
                    })
                    arr.push({ ...ch, messages: mrr })
                }
                else {
                    arr.push(ch)
                }
            })
            return ({
                ...state,
                data: arr,
                activeChat: arr.filter((s) => s.id === state.activeChat.id)[0],
                fetch: true
            })
        }

        case "GET_CHAT_PAGE": {
            return ({
                ...state,
                fetch: false
            })
        }
        default:
            return state
    }
}
