import React, { useRef } from "react";
import { FILE_SERVER } from "../../../../../constants";
import avat from "../../../../../assets/svg/avat.png";
import mang from "../../../../../assets/svg/manager.svg";
import irq from "../../../../../assets/svg/irq.svg";
import { useDispatch, useSelector } from "react-redux";
import EmployeeModal from "./EmployeeModal";
import AxiosInstance from "../../../../../helpers/axiosInstance";
import Spinner from "../../../Spinner";
import { useEffect } from "react";
import { store } from "../../../../../redux/store";
const base_user =
  store?.getState().whatsapp.fetched_url === true
    ? store?.getState()?.whatsapp.base_user
    : process.env.REACT_APP_USER_BASE_URL;
const base =
  store?.getState().whatsapp.fetched_url === true
    ? store?.getState()?.whatsapp.base_url
    : process.env.REACT_APP_BASE_URL;
function EmployItem(props) {
  const getTimeInlocalTimeZone = (d) => {
    let a = new Date(d.getTime() + 3 * 60 * 60 * 1000);

    return a;
  };
  const loading = useRef(true)
let loading_req=useRef(false)
let loading_req_dept=useRef(false)
  const isToday = (date) => {
    let dates = new Date(date);
    dates = getTimeInlocalTimeZone(dates);
    let now = new Date();
    let nowString = `${now.getFullYear()}-${now.getMonth() + 1 > 9
      ? (now.getMonth() + 1).toString()
      : "0" + (now.getMonth() + 1).toString()
      }-${now.getDate() > 9
        ? now.getDate()
        : "0" + parseInt(now.getDate()).toString()
      }`;
    let StartDate = `${dates.getFullYear()}-${dates.getMonth() + 1 > 9
      ? (dates.getMonth() + 1).toString()
      : "0" + (dates.getMonth() + 1).toString()
      }-${dates.getDate() > 9
        ? dates.getDate()
        : "0" + parseInt(dates.getDate()).toString()
      }`;

    if (Math.abs(new Date(nowString) - new Date(StartDate)) < 86400000) {
      return true;
    } else {
      return false;
    }
  };
  const differBetweenDates = (a, b) => {
    const diffTime = a - b;
    const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
    let diffHours = Math.round(diffTime / (1000 * 60 * 60));
    let diffMinutes = Math.round(diffTime / (1000 * 60));
    if (Math.abs(diffDays) > 0) return diffDays + "d";
    if (Math.abs(diffHours) > 0) return diffHours + "h";
    if (Math.abs(diffMinutes) > 0) return diffMinutes + "m";
  };
  const NewdifferBetweenDates = (bnb) => {

    const diffTime = parseInt(bnb);
    const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
    let diffHours = Math.round(diffTime / (1000 * 60 * 60));
    let diffMinutes = Math.round(diffTime / (1000 * 60));
   
    if (Math.abs(diffDays) > 0) return diffDays.toString() + "d";
    if (Math.abs(diffHours) > 0) return diffHours.toString() + "h";
    if (Math.abs(diffMinutes) > 0) return diffMinutes.toString() + "m";
  };
  const isBreak = () => {
    let now = new Date();
    let nowString = `${now.getFullYear()}-${now.getMonth() + 1 > 9
      ? (now.getMonth() + 1).toString()
      : "0" + (now.getMonth() + 1).toString()
      }-${now.getDate() > 9
        ? now.getDate()
        : "0" + parseInt(now.getDate()).toString()
      }`;
    let startBreak = new Date(nowString + " " + a.start_break_at);
    let endBreak = new Date(nowString + " " + a.end_break_at);

    if (endBreak - now > 0 && now - startBreak > 0) {
      let num = now.getTime() - startBreak.getTime();
      num = num / 1000;

      return (parseInt(num / 60));
    } else {
      return false;
    }
  };
  const jobs = useSelector((state) => state.jobs.jobs);
  const statues = useSelector((state) => state.employee.statues);
  let a = props.child;
  const { lang_id, setLang_id } = props;
  const countries = useSelector((state) => state.countries.countries);
  const getStatus = () => {
    let now = new Date();
    let nowString = `${now.getFullYear()}-${now.getMonth() + 1 > 9
      ? (now.getMonth() + 1).toString()
      : "0" + (now.getMonth() + 1).toString()
      }-${now.getDate() > 9
        ? now.getDate()
        : "0" + parseInt(now.getDate()).toString()
      }`;
    if (a.employee_daily_log) {
      let workStart = a?.employee_daily_log
      if (workStart) {
        let normStartTime = nowString + " " + a.start_working_at;
        let actualStartTime = workStart.start_working_at;
        // let actualStartTime=fake[fake.length-1].start_working_at;
        let ndate = new Date(normStartTime);
        let adate = new Date(actualStartTime);
        adate = getTimeInlocalTimeZone(adate);
        
        if(workStart.start_working_delay!==null)
        return NewdifferBetweenDates(workStart.start_working_delay*1000);
        else return false
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const data = useSelector((state) => state.employee.newDepartment);
  const dept = useRef();
  const flag = useRef();
  const code = useRef([]);
  const getDept = async () => {
    loading.current = true
    loading_req.current=true
    let req = await AxiosInstance.get(
      base +
      "/wallet-users-backend/public/api/v1/departments/show/" +
      a.department_id
    ).then((data) => {
      loading.current = false
      loading_req_dept.current = false
      let textName = data.data.data.translations?.filter(
        (de) => de.language_code === (localStorage.getItem("APP_LANG") ?? "en")
      )[0].name;
      let textCode = data.data.data.translations?.filter(
        (de) => de.language_code === (localStorage.getItem("APP_LANG") ?? "en")
      )[0].short_name;
      let parName =
        data.data.data.deep_parent_department_name &&
        data.data.data.deep_parent_department_name.name;
      let parShort =
        data.data.data.deep_parent_department_name &&
        data.data.data.deep_parent_department_name.short_name;
      if (parName && parName.length > 0 && parShort && parShort.length > 0) {
        dept.current = `${parName} | ${textName}`;
        code.current = [textCode, parShort];
      } else {
        dept.current = `${textName}`;
        code.current = [textCode];
      }
    });
    loading.current = false
    loading_req_dept.current = false

  };
  useEffect(() => {
    if (a.department_id&&!loading_req_dept.current&&!dept.current) getDept();
  }, []);
  useEffect(()=>{
    if (a.work_office_region_id&&!a.work_country_id&&!loading_req.current&&!flag.current) {
      
      getCountry();}
  },[a])
  const dispatch=useDispatch()
  const getCountry = async () => {
    try {
      loading_req.current=true
      let req = await AxiosInstance.get(
        "api/v1/regions/show/" + a.work_office_region_id
      ).then((data) => {
      loading_req.current=false
        flag.current = data.data?.data?.country;
        dispatch({type:"EDIT-EMP",payload:{id:a.id,work_country_id:data.data?.data?.country?.id||-1}})
      })
      
    } catch (e) {
      loading_req.current=false
      flag.current = { id: "-1" }
    }

  };
  const getEmployeStatus = () => {
    if(!props.forCustomer){
     if(statues.filter((s)=>s.id===a.user_status_id)[0]?.slug==="online"){
    return("active-emp")
   }
   if(statues.filter((s)=>s.id===a.user_status_id)[0]?.slug==="in_break"){
    return("br")
   }
   if(statues.filter((s)=>s.id===a.user_status_id)[0]?.slug==="offline"||!a.user_status_id){
    return("deactive-emp")
   }}
  };
  useEffect(() => {
    if (a.department_id&&!dept.current) getDept();
    if (a.work_office_region_id&&!flag.current) getCountry();
  }, [localStorage.getItem("APP_LANG")])
  const isEndWorkToday = () => {
    let now = new Date();
    let nowString = `${now.getFullYear()}-${now.getMonth() + 1 > 9
      ? (now.getMonth() + 1).toString()
      : "0" + (now.getMonth() + 1).toString()
      }-${now.getDate() > 9
        ? now.getDate()
        : "0" + parseInt(now.getDate()).toString()
      }`;
    if (a.employee_daily_log) {
      let workStart = a.employee_daily_log
      // let workStart=fake
      if (workStart) {
        let normStartTime = nowString + " " + a.end_working_at;
        let actualStartTime = workStart.end_working_at ?? now.toISOString();
        let ndate = new Date(normStartTime);
        let adate = new Date(actualStartTime);
        adate = getTimeInlocalTimeZone(adate);
        
        if (workStart.end_working_delay) {
          return NewdifferBetweenDates(workStart.end_working_delay*1000);
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const isActive=()=>{
    if(statues.filter((s)=>s?.id===a?.user_status_id)[0]?.slug==="online"){
      return true
    }
    else{
      return false
    }
  }
  const getNum = (str) => {
    if (typeof str === "string") {
      var numb = str.match(/\d/g);
      numb = numb?.join("");
      return parseInt(numb);
    } else return str;
  };

  return (
    <>
      {props.open && lang_id === a.id && (
        <EmployeeModal
          Active_Ids={props.Active_Ids}
          ActiveAdd={() => props.ActiveAdd(data.id)}
          languageFollower={props.languageFollower}
          setExpand={(e) => {
          }}
          data={data}
          {...props}
        />
      )}
      {((flag.current && (props.arr.length === 0 || props.arr.includes(flag.current.id))) ||(props.arr.length === 0&&a.work_country_id===-1)|| (props.arr.length === 0 && !a.work_office_region_id && !flag.current)) ?
        <div
          className={
            "emp-item " + `${getEmployeStatus()} ${isBreak() > 0 && "break-emp"}`
          }
          onClick={() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
            props.makeFollower(1, props.child.full_name);
            props.setLang_id(props.child.id);
            props.Read(props.child);
          }}
        >
          <img
            className="emp-photo"
            src={a.avatar ? FILE_SERVER + a.avatar : avat}
          />
          <div className="emp-name">
            {a.full_name ? a.full_name : "Not Entered"}
          </div>
          <div className="emp-dept">
            {loading.current ? <Spinner /> : <>{dept.current}</>}

          </div>
          <div className="emp-dept-icon">
            {code.current &&
              code.current.map((c) => (
                <svg
                  className="dept-child"
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="15"
                  viewBox="0 0 23 15"
                >
                  <g id="_15x23_flag_photo" data-name="15x23 flag photo">
                    <rect
                      id="Rectangle_4601"
                      data-name="Rectangle 4601"
                      width="23"
                      height="15"
                      rx="2"
                      fill="#ddd"
                    />
                  </g>
                  <text
                    text-anchor="middle"
                    id="p8"
                    transform="translate(0 2)"
                    fill="#404040"
                    font-size="8"
                    font-family="SegoeUI, Segoe UI"
                    letter-spacing="0.025em"
                  >
                    <tspan x="50%" y="9">
                      {c}
                    </tspan>
                  </text>
                </svg>
              ))}
            <span style={{ marginLeft: "5px" }} className="job-name">
              Officer
            </span>
          </div>
          <div className="emp-info">
            <div className="emp-region">
              {!flag.current ? (
                <>
                  {(a.work_office_region_id &&a?.work_country_id!==-1) ?<Spinner />:<></>}
                </>

              ) : (
                <>
                  {a.work_office_region_id && flag.current.flag_photo_path ? (
                    <svg
                      width={"15"}
                      height="15"
                      data-src={FILE_SERVER + flag.current.flag_photo_path}
                    ></svg>
                  ) : (
                    countries &&
                    countries?.filter((c) => c.id === flag.current.id).length >
                    0 && (
                      <svg
                        className={`no-trans sm active-count-svg`}
                        xmlns="http://www.w3.org/2000/svg"
                        width="23"
                        height="15"
                        viewBox="0 0 23 15"
                      >
                        <g
                          textAnchor="middle"
                          id="Group_7626"
                          data-name="Group 7626"
                          transform="translate(-274 -138)"
                        >
                          <g
                            id="_15x23_flag_photo"
                            data-name="15x23 flag photo"
                            transform="translate(274 138)"
                          >
                            <rect
                              id="Rectangle_4601"
                              data-name="Rectangle 4601"
                              width="23"
                              height="15"
                              rx="2"
                              fill="#404040"
                            />
                          </g>
                          <text
                            textAnchor="middle"
                            id="CFO"
                            transform="translate(274 139)"
                            fill="#F7F7F7"
                            font-size="9"
                            font-family="SegoeUI, Segoe UI"
                          >
                            <tspan textAnchor="middle" x="50%" y="10">
                              {
                                countries
                                  ?.filter((c) => c.id === flag.current.id)[0]
                                  ?.region_translations.filter(
                                    (a) => a.language_code === (localStorage.getItem("APP_LANG") ?? "en")
                                  )[0]?.region_description
                              }
                            </tspan>
                          </text>
                        </g>
                      </svg>
                    )
                  )}
                </>
              )}
              <span>RTA700{props.child.id}</span>
            </div>
            <div className="emp-job-type">
              {a.job_title_id &&
                jobs.filter((j) => j.id === a.job_title_id)[0] &&
                a.job_title_id &&
                (jobs.filter((j) => j.id === a.job_title_id)[0]
                  .flat_photo_path ? (
                  <svg
                    data-src={
                      FILE_SERVER +
                      jobs.filter((j) => j.id === a.job_title_id)[0]
                        .flat_photo_path
                    }
                  ></svg>
                ) : (
                  <svg width={"10"} height="10" data-src={mang}></svg>
                ))}
              <span>
                {a.job_title_id &&
                  jobs
                    ?.filter((j) => j.id === a.job_title_id)[0]
                    ?.translations?.filter((t) => t.language_code === (localStorage.getItem("APP_LANG") ?? "en"))[0].name}
              </span>
            </div>


          </div>
          {(a.status === "in" || a.status === "out" || a.status === "br") &&(!props.forCustomer) &&<div className="status_emp">
            <div className="status_emp_item">
              {Math.abs(getNum(getStatus())) > 0 && <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="34"
                height="14"
                viewBox="0 0 34 14"
              >
                <g
                  className="active_gg"
                  id="Group_10218"
                  data-name="Group 10218"
                  transform="translate(-220 -520)"
                >
                  <text
                    id="_-10_m"
                    data-name="-10 m"
                    transform="translate(233 531)"
                    fill="#f75858"
                    font-size="8"
                    font-family="SegoeUI, Segoe UI"
                    letter-spacing="-0.015em"
                  >
                    <tspan x="0" y="0">
                      {getNum(getStatus()) < 0 ? "+" : "-"}
                    </tspan>
                    <tspan y="0" fill="#8e8e8e">
                      {typeof getStatus() === "string" &&
                        getStatus()?.split("-")[
                        getStatus()?.split("-").length - 1
                        ]}
                    </tspan>
                    <tspan y="0" fill="#8e8e8e" font-size="10">
                      {" "}
                    </tspan>
                  </text>
                  <g
                    id="Mask_Group_302"
                    data-name="Mask Group 302"
                    transform="translate(220 523)"
                  >
                    <g id="exit" transform="translate(0 0.003)">
                      <path
                        id="Path_21321"
                        data-name="Path 21321"
                        d="M6.754.993h3.737a.5.5,0,1,1,0,1H6.754a.763.763,0,0,0-.763.761V9.228a.763.763,0,0,0,.763.761h3.737a.5.5,0,0,1,0,1H6.754A1.762,1.762,0,0,1,4.993,9.228V2.752A1.762,1.762,0,0,1,6.754.993Z"
                        transform="translate(-4.993 -0.993)"
                        fill="#404040"
                      />
                      <path
                        id="Path_21322"
                        data-name="Path 21322"
                        d="M6.325,6.491H1.489a.5.5,0,1,1,0-1H6.315L4.786,3.86a.5.5,0,1,1,.728-.682l2.3,2.452a.493.493,0,0,1,.124.184.486.486,0,0,1,.02.1.473.473,0,0,1,.016.078s0,0,0,0a.5.5,0,0,1-.134.344L5.514,8.817a.5.5,0,1,1-.728-.682Z"
                        transform="translate(2.03 -0.993)"
                        fill="#25bc00"
                      />
                    </g>
                  </g>
                </g>
              </svg>}
            </div>
            <div className="status_emp_item">
              {isBreak() > 0 && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="36"
                  height="14"
                  viewBox="0 0 36 14"
                >
                  <g
                    className="active_gg"
                    id="Group_10217"
                    data-name="Group 10217"
                    transform="translate(-267 -520)"
                  >
                    <text
                      id="_10_m"
                      data-name="+10 m"
                      transform="translate(280 531)"
                      fill="#157cff"
                      style={{ fontSize: "8px", fontFamily: "SegoeUI" }}
                      font-size="8"
                      font-family="SegoeUI, Segoe UI"
                      letter-spacing="-0.015em"
                    >
                      <tspan x="0" y="0">
                        +
                      </tspan>
                      <tspan y="0" fill="#8e8e8e">
                        {isBreak()}
                      </tspan>
                      <tspan y="0" fill="#8e8e8e" font-size="10">
                        {" "}
                      </tspan>
                    </text>
                    <g
                      id="Mask_Group_301"
                      data-name="Mask Group 301"
                      transform="translate(267 523)"
                    >
                      <g id="tea">
                        <path
                          id="Path_21315"
                          data-name="Path 21315"
                          d="M1.772,1.72a.385.385,0,0,1-.063.523.7.7,0,0,0-.181.831l.188.4a.155.155,0,0,0,.142.091.14.14,0,0,0,.066-.016A.155.155,0,0,0,2,3.342l-.189-.4a.385.385,0,0,1,.1-.458.7.7,0,0,0,.111-.948l-.291-.392a.156.156,0,1,0-.25.186Z"
                          fill="#404040"
                        />
                        <path
                          id="Path_21316"
                          data-name="Path 21316"
                          d="M3.734.95a.854.854,0,0,1-.067.873A1.141,1.141,0,0,0,3.5,2.888l.188.57a.157.157,0,0,0,.148.108.185.185,0,0,0,.05-.008.157.157,0,0,0,.1-.2l-.189-.57a.85.85,0,0,1,.114-.766A1.155,1.155,0,0,0,4.011.8L3.72.248a.156.156,0,1,0-.277.144Z"
                          fill="#404040"
                        />
                        <path
                          id="Path_21317"
                          data-name="Path 21317"
                          d="M5.723,1.72a.385.385,0,0,1-.063.523.7.7,0,0,0-.181.831l.188.4a.155.155,0,0,0,.142.091.14.14,0,0,0,.066-.016.155.155,0,0,0,.075-.208l-.189-.4a.385.385,0,0,1,.1-.458.7.7,0,0,0,.111-.948l-.291-.392a.156.156,0,1,0-.252.186Z"
                          fill="#404040"
                        />
                        <path
                          id="Path_21318"
                          data-name="Path 21318"
                          d="M1.661,8.734H6.128A4.162,4.162,0,0,0,7.37,7.17s0,0,0-.006,0,0,0,0a4.6,4.6,0,0,0,.413-1.883c0-.147-.009-.294-.025-.453H.025A4.4,4.4,0,0,0,1.661,8.734Z"
                          fill="#404040"
                        />
                        <path
                          id="Path_21319"
                          data-name="Path 21319"
                          d="M9.964,5.177a.154.154,0,0,0-.119-.056H8.1c0,.048,0,.1,0,.144s0,.113,0,.169H9.644A2.134,2.134,0,0,1,7.755,7.069c-.031.077-.064.152-.1.227l-.042.089A2.548,2.548,0,0,0,10,5.3.153.153,0,0,0,9.964,5.177Z"
                          fill="#404040"
                        />
                        <path
                          id="Path_21320"
                          data-name="Path 21320"
                          d="M3.894,9.836c2.106,0,3.5-.42,3.823-.789H.07C.391,9.416,1.786,9.836,3.894,9.836Z"
                          fill="#404040"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              )}
            </div>
            <div className="status_emp_item">
             
              {a?.employee_daily_log?.end_working_delay && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="36"
                  height="14"
                  viewBox="0 0 36 14"
                >
                  <g
                    className="active_gg"
                    id="Group_10216"
                    data-name="Group 10216"
                    transform="translate(-313 -520)"
                  >
                    <g
                      id="Mask_Group_302"
                      data-name="Mask Group 302"
                      transform="translate(313 523)"
                    >
                      <g id="exit" transform="translate(0 0.003)">
                        <path
                          id="Path_21321"
                          data-name="Path 21321"
                          d="M9.229.993H5.492a.5.5,0,1,0,0,1H9.229a.763.763,0,0,1,.763.761V9.228a.763.763,0,0,1-.763.761H5.492a.5.5,0,1,0,0,1H9.229A1.762,1.762,0,0,0,10.99,9.228V2.752A1.762,1.762,0,0,0,9.229.993Z"
                          transform="translate(-0.99 -0.993)"
                          fill="#404040"
                        />
                        <path
                          id="Path_21322"
                          data-name="Path 21322"
                          d="M6.325,6.491H1.489a.5.5,0,1,1,0-1H6.314L4.786,3.86a.5.5,0,1,1,.728-.682l2.3,2.452a.493.493,0,0,1,.124.184.486.486,0,0,1,.02.1.473.473,0,0,1,.016.078s0,0,0,0a.5.5,0,0,1-.134.344L5.514,8.817a.5.5,0,1,1-.728-.682Z"
                          transform="translate(-0.99 -0.992)"
                          fill="#8e8e8e"
                        />
                      </g>
                    </g>
                    <text
                      id="_15_m"
                      data-name="+15 m"
                      transform="translate(326 531)"
                      fill="#157cff"
                      font-size="8"
                      font-family="SegoeUI, Segoe UI"
                      letter-spacing="-0.015em"
                    >
                      <tspan x="0" y="0">
                        
                      </tspan>
                      <tspan y="0" fill="#8e8e8e">
                        {NewdifferBetweenDates(a.employee_daily_log.end_working_delay*1000)}
                      </tspan>
                      <tspan y="0" fill="#8e8e8e" font-size="10">
                        {" "}
                      </tspan>
                    </text>
                  </g>
                </svg>
              )}
            </div>
          </div>}

          <div className="deactive-emp-status">
            {isBreak() > 0 ?
              <svg id="Component_120_1" data-name="Component 120 – 1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="23" height="27" viewBox="0 0 23 27">

                <g id="_15x15_photo_back" data-name="15x15 photo back" transform="translate(2)">
                  <g id="Mask_Group_300" data-name="Mask Group 300" >
                    <g id="tea">
                      <path id="Path_21309" data-name="Path 21309" d="M2.658,2.58a.577.577,0,0,1-.094.785,1.047,1.047,0,0,0-.272,1.247l.281.6a.233.233,0,0,0,.213.136.21.21,0,0,0,.1-.023A.233.233,0,0,0,3,5.013l-.284-.6a.578.578,0,0,1,.152-.687A1.042,1.042,0,0,0,3.033,2.3L2.6,1.713a.234.234,0,1,0-.375.279Z" fill="#404040" />
                      <path id="Path_21310" data-name="Path 21310" d="M5.6,1.425a1.281,1.281,0,0,1-.1,1.31,1.712,1.712,0,0,0-.255,1.6l.281.855a.236.236,0,0,0,.223.162.278.278,0,0,0,.075-.012.235.235,0,0,0,.148-.3l-.284-.855a1.275,1.275,0,0,1,.171-1.148,1.733,1.733,0,0,0,.157-1.828L5.581.373a.234.234,0,1,0-.415.216Z" fill="#404040" />
                      <path id="Path_21311" data-name="Path 21311" d="M8.585,2.58a.577.577,0,0,1-.094.785A1.044,1.044,0,0,0,8.22,4.613l.281.6a.233.233,0,0,0,.213.136.21.21,0,0,0,.1-.023.233.233,0,0,0,.113-.312l-.284-.6a.578.578,0,0,1,.152-.687A1.045,1.045,0,0,0,8.96,2.3l-.436-.588a.235.235,0,0,0-.377.279Z" fill="#404040" />
                      <path id="Path_21312" data-name="Path 21312" d="M2.491,13.1h6.7a6.243,6.243,0,0,0,1.863-2.346s0-.007,0-.009,0,0,0-.007a6.894,6.894,0,0,0,.619-2.824c0-.22-.014-.441-.037-.68H.038A6.595,6.595,0,0,0,2.491,13.1Z" fill="#404040" />
                      <path id="Path_21313" data-name="Path 21313" d="M14.946,7.765a.231.231,0,0,0-.178-.084H12.145c0,.073,0,.145,0,.216s0,.169,0,.253h2.32A3.2,3.2,0,0,1,11.632,10.6c-.047.115-.1.227-.148.34l-.063.134C14.4,11.039,14.988,7.99,15,7.957A.229.229,0,0,0,14.946,7.765Z" fill="#404040" />
                      <path id="Path_21314" data-name="Path 21314" d="M5.841,14.754c3.159,0,5.252-.63,5.735-1.184H.105C.586,14.124,2.679,14.754,5.841,14.754Z" fill="#404040" />
                    </g>
                  </g>
                </g>
                <text id="_19_m" data-name="19 m" transform="translate(0 25)" fill="#404040" style={{ fontSize: "8px", fontFamily: "ArialMT, Arial" }} font-size="8" font-family="ArialMT, Arial"><tspan style={{ fontSize: "9px", fontFamily: "ArialMT, Arial" }} x="0" y="0">{isBreak()}m</tspan></text>
              </svg>

              :
              <svg
                id="_15x15_photo_back"
                data-name="15x15 photo back"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="15"
                height="15"
                viewBox="0 0 15 15"
              >
                <g id="Mask_Group_303" data-name="Mask Group 303">
                  <g
                    id="Group_10220"
                    data-name="Group 10220"
                    transform="translate(-128.109 -151.492)"
                  >
                    <g id="file-4" transform="translate(127.987 151.491)">
                      <path
                        id="Path_21323"
                        data-name="Path 21323"
                        d="M4.154,52.231a3.938,3.938,0,1,0,3.938,3.938A3.938,3.938,0,0,0,4.154,52.231Z"
                        transform="translate(0 -45.172)"
                        fill="#f75858"
                      />
                      <path
                        id="Path_21323_-_Outline"
                        data-name="Path 21323 - Outline"
                        d="M4.154,52.166a4,4,0,1,1-4,4,4,4,0,0,1,4-4Zm0,7.876A3.873,3.873,0,1,0,.281,56.169a3.873,3.873,0,0,0,3.873,3.873Z"
                        transform="translate(0 -45.172)"
                        fill="#707070"
                      />
                      <path
                        id="Path_21324"
                        data-name="Path 21324"
                        d="M34.137,3.792a.437.437,0,0,1-.437-.437V0H27.428a1.314,1.314,0,0,0-1.313,1.313V6.2q.216-.02.437-.02a4.806,4.806,0,0,1,3.71,1.751h4.748a.438.438,0,0,1,0,.875H30.84a4.777,4.777,0,0,1,.507,1.75h3.666a.438.438,0,0,1,0,.875H31.346a4.815,4.815,0,0,1-2.029,3.5h6.862a1.314,1.314,0,0,0,1.313-1.313V3.792Zm.875,2.392H28.595a.438.438,0,0,1,0-.875h6.417a.438.438,0,0,1,0,.875Z"
                        transform="translate(-22.399)"
                        fill="#404040"
                      />
                      <path
                        id="Path_21325"
                        data-name="Path 21325"
                        d="M88.706,1.9V4.556h2.661Z"
                        transform="translate(-76.531 -1.639)"
                        fill="#404040"
                      />
                    </g>
                    <path
                      id="Union_14"
                      data-name="Union 14"
                      d="M1816.95,4861.827a.431.431,0,0,1,0-.611l.763-.763-.764-.763a.432.432,0,0,1,.611-.61l.763.763.763-.763a.432.432,0,0,1,.61.61l-.763.763.763.763a.432.432,0,0,1-.61.61l-.763-.763-.763.764a.432.432,0,0,1-.61,0Z"
                      transform="translate(-1686.067 -4697.979)"
                      fill="#fff"
                    />
                    <path
                      id="Union_14_-_Outline"
                      data-name="Union 14 - Outline"
                      d="M1817.255,4861.824a.3.3,0,0,0,.214-.089l.763-.764a.13.13,0,0,1,.183,0l.763.762a.3.3,0,0,0,.427-.427l-.763-.763a.13.13,0,0,1,0-.183l.763-.763a.3.3,0,0,0-.213-.516.3.3,0,0,0-.213.088l-.763.764a.131.131,0,0,1-.183,0l-.763-.764a.3.3,0,0,0-.429.428l.764.763a.13.13,0,0,1,0,.183l-.763.763a.3.3,0,0,0,.214.518m0,.129a.432.432,0,0,1-.3-.738l.763-.763-.764-.763a.432.432,0,0,1,.611-.61l.763.763.763-.763a.432.432,0,0,1,.61.61l-.763.763.763.763a.432.432,0,0,1-.61.61l-.763-.763-.763.764a.432.432,0,0,1-.305.127Z"
                      transform="translate(-1686.067 -4697.979)"
                      fill="#707070"
                    />
                  </g>
                </g>
              </svg>}

          </div>
        </div>
        : <>
          {(props.arr.length === 0 && !props.arr.includes(a.work_office_region_id)) && <div className="emp-item loading">

          </div>}
        </>

      }
    </>
  );
}

export default EmployItem;
