import { store } from "../store";

export const GET_DATA = "GET_DATA_SAGA";
export const ERR = "ERR";
export const REMOTE_URL =
  "https://ramaaz.dev/market_staging/market_service/public";
export const GET_LANGS_URL = "/api/v1/languages/all_languages";
export const ADD_LANGS_URL = "/api/v1/languages/create";
export const UPD_LANGS_URL = "/api/v1/languages/update";
export const ADD_SYS_LANG = "/api/v1/languages/addLanguageToSystem";
export const REM_SYS_LANG = "/api/v1/languages/removeLanguageFromSystem";
export const REGION_URL = "";
export const CLIENTS_URL = "";
export const TRANSFER_URL = "";
