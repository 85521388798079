import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Application from '../../../../assets/svg/Application';
import Filter from '../../../../assets/svg/Filter';
import Search from '../../../../assets/svg/search';
import Sort from '../../../../assets/svg/Sort';
import Translated from '../../../../assets/svg/Translated';
import Curre from "../Languages/svgs/Langs.svg";
import './translate.css'
import { Table } from 'ka-table';
import { hideNewRow, saveNewRow, showNewRow } from 'ka-table/actionCreators';
import { DataType } from 'ka-table/enums';
import "ka-table/style.scss";
import { trans } from '../../../../Internationalization'
const getFile = async (file_path) => {
  const response = await fetch(file_path);
  const parsed = await response.json();
  return (parsed);
}
const getHead = (languages) => {
  let temp_head = [];
  let index = 3;
  temp_head[0] = {
    key: 'addColumn', style: { width: 53 },
  }
  temp_head[1] = { key: 'id', title: 'ID', dataType: DataType.Number, visible: false }
  temp_head[2] = { key: 'key', title: 'KEY', dataType: DataType.String }
  languages.map((lang) => {
    temp_head[index] = { key: lang.language_code.toUpperCase(), title: lang.language_code.toUpperCase(), dataType: DataType.String }
    index++
  })

  return temp_head
}
const AddButton = ({ dispatch }) => {
  return (
    <div className="plus-cell-button">
      <img
        src="https://komarovalexander.github.io/ka-table/static/icons/plus.svg"
        alt="Add New Row"
        title="Add New Row"
        onClick={() => dispatch(showNewRow())}
      />
    </div>
  );
};

const SaveButton = (props) => {
  const saveNewData = () => {
    props.dispatch(
      saveNewRow(props.maxId, {
        validate: true,
      })
    );
  };
  return (
    <div className="buttons">
      <img
        src="https://komarovalexander.github.io/ka-table/static/icons/save.svg"
        className="save-cell-button"
        alt="Save"
        title="Save"
        onClick={saveNewData}
      />
      <img
        src="https://komarovalexander.github.io/ka-table/static/icons/close.svg"
        className="close-cell-button"
        alt="Cancel"
        title="Cancel"
        onClick={() => props.dispatch(hideNewRow())}
      />
    </div>
  );
};
const changeColumns = (dispatch, cols) => {
  dispatch({ type: "colomns_change", payload: cols })
}
const changeData = (dispatch, data) => {
  dispatch({ type: "data_change", payload: data })
}
function TranslateComponent(props) {
  const tablePropsInit = useSelector((state) => state.tablePropsInit);
  const [tableProps, changeTableProps] = useState(tablePropsInit);
  const [lang, setLang] = useState(null)
  const languages = useSelector(state => state.langs.languages)
  const dispatch = useDispatch()
  const translated_lang = useSelector((state) => state.auth.lang)
  const loading_langs = useSelector((state) => state.langs.loading);
  const [files, setFiles] = useState([]);
  const [keys, setKeys] = useState([]);
  const [head, setHead] = useState(null);
  const [rows, setRows] = useState(null);
  const [isReady, setIsReady] = useState(false)
  useEffect(() => {
    let temp = [];
    let temp_keys = [];
    let temp_head = languages.length > 0 && getHead(languages);

    let temp_data = []
    languages.map((lang, index) => {
      if (lang.file_path) {
        getFile(`${process.env.REACT_APP_BASE_FILE_URL}${lang.file_path}`).then(file => {
          if (index === 0) {
            temp[index] = file;
            temp_keys.push(...Object.keys(file))
            Object.keys(file).map((key, index) => {
              temp_data.push({ id: index, key: key })
            })
            Object.values(file).map((value, index1) => temp_data[index1] = { ...temp_data[index1], [lang.language_code.toUpperCase()]: value })
          }
          else {
            temp[index] = file;
            Object.keys(file).map((key) => {
              if (!temp_keys.find(temp_key => temp_key === key)) {
                temp_data.push({ id: temp_keys.length, key: key })
                temp_keys.push(key)
              }
            })
            Object.values(file).map((value, index1) => {
              let var_index = temp_data.findIndex(element => element.key === Object.keys(file)[index1])
              temp_data[var_index] = { ...temp_data[var_index], [lang.language_code.toUpperCase()]: value }
            })
          }
        })
      }
      else {
        temp[index] = null;
      }
    })
    setRows(temp_data)
    setFiles(temp)
    setKeys(temp_keys)
    setHead(temp_head)
  }, [languages])
  useEffect(() => {
    setIsReady(false)
    if (rows && head && head.length > 0) {
      changeColumns(dispatch, head)
      changeData(dispatch, rows)
      setIsReady(true)
    } else {
      setIsReady(false)
    }
  }, [rows, head, files])
  const [searchText, setSearchText] = useState('');
  return (
    <>
      <ToastContainer />
      <div className={`languages-page ${props.active && "Active-page"}`}>

        <div className='lang-top'>
          <div className='lang-info'>
            <div className='lang-icon header-icon'>
              <img alt="Languages" src={Curre} />
            </div>
            <div className='lang-name'>{trans("translate")}</div>

          </div>
          <div className='top-options'>
            <div className='top-option'>
              <span><Search /></span>
            </div>
            <div className='top-option langs'>
              <span><Translated /></span>
              <div className='lang-items'>
                {languages.map(langs => <div onClick={() => dispatch({ type: "TRANSLATE", payload: langs.language_code })} onMouseLeave={() => setLang(null)} onMouseEnter={() => setLang(langs.language_code)} className={'lang-item' + ` ${translated_lang?.toLowerCase() === langs.language_code?.toLowerCase() && "selected-lang"}`}>{langs.language_code.toUpperCase()}</div>)}
              </div>
            </div>
            <div className='top-option'>
              <span><Sort /></span>
            </div>
            <div className='top-option'>
              <span><Filter /></span>
            </div>
            <div className='top-option'>
              <span><Application /></span>
            </div>
            <svg className={'lines'} xmlns="http://www.w3.org/2000/svg" width="0.5" height="25.5" viewBox="0 0 0.5 25.5">
              <line id="Line_992" data-name="Line 992" y1="25" transform="translate(0.25 0.25)" fill="none" stroke="#8e8e8e" strokeLinecap="round" strokeWidth="0.5" />
            </svg>
          </div>
          <div className={`lang-add currency-add`}>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
              <defs>
                <clipPath id="clip-path">
                  <rect id="Rectangle_4527" data-name="Rectangle 4527" width="15" height="15" transform="translate(324 333)" fill="none" />
                </clipPath>
              </defs>
              <g id="Mask_Group_16" data-name="Mask Group 16" transform="translate(-324 -333)" clip-path="url(#clip-path)">
                <path id="add-3" d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z" transform="translate(324 333)" fill="#404040" />
              </g>
            </svg>

          </div>
        </div>
        <div className='trans'>
          {isReady && <>
            <input type='search' placeholder={trans("search_here")} value={searchText} onChange={(event) => {
              setSearchText(event.currentTarget.value);
            }} className='top-element' />
            <button className='validate-button' onClick={() => {
              dispatch({ type: "UploadLanguagesFiles", payload: { langs: languages, data: tablePropsInit.data } })
            }}>{trans("save")}</button>
            <Table {...tablePropsInit}
              dispatch={dispatch}
              searchText={searchText}
              childComponents={{
                noDataRow: {
                  content: () => 'No Data Found'
                },
                cellEditor: {
                  content: (props) => {
                    if (props.column.key === 'addColumn') {
                      return <SaveButton {...props} maxId={tablePropsInit.data.length} />;
                    }
                  },
                },
                headCell: {
                  content: (props) => {
                    if (props.column.key === 'addColumn') {
                      return <AddButton {...props} />;
                    }
                  },
                },
              }}
            />
          </>}
        </div>
      </div>
    </>
  )
}

export default TranslateComponent