
const initialState = {

  languages:[],
  pageFollower:[],
  newLang:{translations:[],icon:null,"son_languages":[],name_in_native_language:""},
  mode:"read",
  open:false,
  modalData:{name:"",icon:null,code:""},
  Active_Ids:[],
  loading:false,
  request_running:false,
  request_data:null,
  message:"",
  error:false,
  view_language:"en",
  loading_percent:0,
  sucess_message:null,
  img_upload:{type:"",status:false}
}

 export const LangReducer=(state = initialState, { type, payload, message }) => {
  switch (type) {
    case "IMG-UPLOAD-LANG":{
      return({
        ...state,
        img_upload:{...payload}
      })
    }
    case "SET-LANG-RED":{
      let arr=[]
      state.languages.map((lan)=>{
        if(lan.language_code===payload.child.language_code){
          arr.push({...lan,is_used_in_system:payload.value})
          
        }
        else arr.push({...lan})
      })
      return({
        ...state,
        languages:[...arr],
       
      })
    }
    case "SET-LANGUAGE-R":{
      
      return({
        ...state,
        sucess_message:parseInt(payload)===0?"Activated":"Deactivated"
      })
    }
    case "GENERAL-LOADING":{
      return({
        ...state,
        loading:true,
        loading_percent:payload?payload:0
      })
    }
    case "request-running":{
      return({
        ...state,
        request_running:true,
        request_data:payload
      })
    }
    case "GENERAL-ERR":{
      return({
        ...state,
        request_running:false,
        request_data:payload.data,
        loading:false,
        error:true,
        message:payload.message
      })
    }
    case "request-running-red":{
      
      return({
        ...state,
        request_running:false,
        request_data:payload,
        error:false,
        

        message:""
      })
    }
    case "VIEW-LANGUAGE":{
      return({
        ...state,
        view_language:payload
      })
    }
    case "GENERAL-DONE":{
      return({
        ...state,
        loading:false,
        loading_percent:0,
        open:false

      })
    }
    case "LEVEL-DONE":{
      return({
        ...state,
        loading:false
      })
    }
    case "GET-LANG-REDUCER":{
      let langs = []
      payload.map((j)=>{
        if(j.language_code?.toLowerCase()==="en")
        langs.push(j)
      })
      payload.map((j)=>{
        if(j.language_code?.toLowerCase()!=="en")
        langs.push(j)
      })
      return({...state,languages:[...langs],request_data:null,request_running:false,error:false,message:""})
    }
    case "NEW-LANG":{
      return{
        ...state,
        newLang:{...payload}
      }
    }
    case "OPEN-LANG":{
      return({
        ...state,
        open:payload
      })
    }
    case "MODE-LANG":{
      return({
        ...state,
        mode:payload,
        sucess_message:null
      })
    }
    case "MOD-DATA-LANG":{
      return({
        ...state,
        modalData:{...payload}
      })
    }
    
  default:
    return state
  }
}
  