import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { forwardMessage } from '../../../../../constants';
import store from '../../../../../redux/store';
import Alert from './Alert';
import ChatUsers from './ChatUsers';
import Contact from './Contact';

function ForwardList(props) {
  const dispatch = useDispatch()
  let forwarderMessage = store.getState().whatsapp?.forwarderMessage || null;
  const chatsArray = useSelector((state) => state.chat.data)

  let activeChat = store.getState().whatsapp?.activeChat || null;

  const setId = (ml) => {
    let id = ml;

    dispatch({ type: "CHAT_ID", payload: ml });
  };
  function handleSetUser(contact, id) {
    setId(null);
    dispatch({ type: "OPEN-CHAT", payload: null })


    setId(contact);
    dispatch({ type: "READ_CHAT", id: contact.id })

    forwardMessage(forwarderMessage, contact)
    setId(contact);

  }
  const openAdmin = () => {

    if (props.chatss.length > 0) {
      handleSetUser(props.chatss.filter((s) => s.is_admin === 1)[0], props.chatss.filter((s) => s.is_admin === 1)[0].id);



    }
  }
  return (
    <>
      {<ChatUsers forwarderMessage={forwarderMessage} forward={() => { ; }} chats={chatsArray} />}
      <div className="sidebar__contacts">
        {props.chats.length > 0 && <Alert noAdmin={false} noGroup={true} chat={props.chatss.filter((s) => s.is_admin === 1)[0]} name={props.chatss.filter((s) => s.is_admin === 1)[0]?.whatsapp_contact?.name ? props.chatss.filter((s) => s.is_admin === 1)[0]?.whatsapp_contact?.name : "+" + props.chatss.filter((s) => s.is_admin === 1)[0]?.whatsapp_contact?.number} new={0} onClick={() => openAdmin()} />}
        {props.chats.filter((s, ind) => !(s.is_admin === 1)).map((contact, index) => {
          return (
            <Contact
              onClick={() => { handleSetUser(contact, contact?.id) }}
              key={index}
              contact={contact}
            />
          )
        })}
      </div>
    </>
  )
}

export default ForwardList