import { all, fork, put, takeEvery } from "@redux-saga/core/effects";
import * as types from "./constants";
import {
  wait,
  done,
  error,
  set_session,
  set_token,
  register,
  LoginSucces,
  SignupSucces,
  _setCountries,
} from "./actions";
import AxiosInstance from "../../helpers/axiosInstance";
import { remote_server, user_server } from "./constants";
import translations from "../../assets/translation/index.json";
// import MonitoringService from "../../monitoringService"
import {
  requestFirebaseNotificationPermission,
} from "../../firebaseInit";
import { store } from "../store";
import axios from "axios";
import { pusher } from "../chat/constants";
function* getRoles(action) {
  try {
    let AxiosInstaceRole = axios.create({
      baseURL: store.getState().whatsapp.fetched_url === true ? store.getState()?.whatsapp.base_url : types.remote_role_server,
      headers: {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).access_token,
        "Content-Type": "application/json",
        current_role_id: JSON.parse(localStorage.getItem("user")).role_id
      },
    });

    yield put(wait());
    let response = yield AxiosInstaceRole.get("/api/v1/user_permissions/mine");
    yield put({ type: "ROLES_AUTH", payload: response.data.data.map((s) => { return (s.fixed_name) }) });
    yield put(done());
  }
  catch (e) {
    yield put(done());
  }
}
function* watchGetRoles() {
  yield takeEvery("GET_AUTH_ROLES", getRoles)
}
function* login(action) {
  let response;
  try {
    const remoteServer =
      store.getState().whatsapp.fetched_url === true
        ? store.getState()?.whatsapp.base_url + "/api/v1/auth/username/login"
        : types.remote_server + "/api/v1/auth/username/login";
    // 
    yield put(wait());
    response = yield AxiosInstance.post(
      store.getState().whatsapp.fetched_url === true
        ? store.getState()?.whatsapp.base_url +
        "/wallet-users-backend/public" +
        "/api/v1/auth/username/login"
        : types.remote_server + "/api/v1/auth/username/login",
      JSON.stringify(action.payload)
    ).then((res) => {
      requestFirebaseNotificationPermission()
        .then((firebaseToken) => {
          // console.log(firebaseToken)
          // eslint-disable-next-line no-console
          if (res.data.data) {
            try {
              store.dispatch({
                type: "STORE_TOKEN",
                payload: {
                  id: res.data.data.id,
                  token: firebaseToken,
                  user: res.data.data
                },
              });
            } catch (e) {
              console.error(e);
            }
          }
        })
        .catch((err) => {
          return err;
        });
      return res
    })
    let responses = yield AxiosInstance.post(
      store.getState().whatsapp.fetched_url === true
        ? store.getState()?.whatsapp.base_user +
        "/api/v1/auth/login"
        : process.env.REACT_APP_USER_BASE_URL + "/api/v1/auth/login",
      JSON.stringify({ user_name: action.payload.username, password: action.payload.password })
    );
    yield put({
      type: "TOKEN_WHATSAPP",
      payload: responses.data.access_token,
    });
    let chs = pusher.subscribe(`whatsapp-user-${response.data.data.id}-channel`)
    chs.bind("PrivateMessageReceivedEvent", (da) => {
      // console.log("event PrivateMessageReceivedEvent triggered")
      if (da.contact) {
        store.dispatch({ type: "CONTACT_ADD", payload: da.contact })
      }
      store.dispatch({
        type: "MessageReceivedEvent",
        payload: da
      })

    })
    chs.bind("AddChatEvent", (da) => {
      // console.log("event AddChatEvent triggered")
      if (da.whatsapp_chat_id) { store.dispatch({ type: "GET_WA_ONE_CHAT", id: da.whatsapp_chat_id }) }
    })
    chs.bind("RemoveChatEvent", (da) => {
      // console.log("event RemoveChatEvent triggered")
      if (da.whatsapp_chat_id) {store.dispatch({ type: "REM_WA_ONE_CHAT", id: da.whatsapp_chat_id }) }


    })
    let ch = pusher.subscribe("employees_channel")
    ch.bind("pusher:subscription_succeeded", data => {

    })
    ch.bind("pusher:subscription_error", data => {

    })
    ch.bind("ReadChatMessagesEvent", data => {
      // console.log("event ReadChatMessagesEvent triggered")

      store.dispatch({ type: "READ_CHAT_PUSHER", id: data.chat_id })
    })
    ch.bind("MessageReceivedEvent", (da) => {
      // console.log("event MessageReceivedEvent triggered")

      if (da.contact) { store.dispatch({ type: "CONTACT_ADD", payload: da.contact }) }
      store.dispatch({
        type: "MessageReceivedEvent",
        payload: da
      })

    })
    ch.bind("EmployeeStatusChangedEvent", (da) => {
      if (localStorage.getItem("user"))
      store.dispatch({ type: "GET-EMP" })
    })
    yield put(LoginSucces(response.data.data));
    console.log(response.data.data)
    let asd = new Date();
    let a = `${asd.getHours() > 9
      ? asd.getHours().toString()
      : "0" + asd.getHours().toString()
      }:${asd.getMinutes() > 9
        ? asd.getMinutes().toString()
        : "0" + asd.getMinutes().toString()
      }`;
    localStorage.setItem("session-start", a);
    yield put(done());
  } catch (e) {
    yield put(error());
  }
}

function* watchlogin() {
  yield takeEvery(types.LOGIN_SAGA, login);
}
export const Translate = (pattern, language) => {
  let result = translations[`${language}`][`${pattern}`];
  return result;
};
function* Signup(action) {
  let response;
  try {
    yield put(wait());
    response = yield AxiosInstance.post(
      remote_server + "/api/v1/auth/signup",
      JSON.stringify({
        username: action.payload.name,
        role_id: 2,
        id_token: action.payload.id,
        password: action.payload.pass,
      })
    );
    yield put(register(response.data.data));
    yield put(done());
  } catch (e) {
    yield put(error());
  }
}
function* watchSignup() {
  yield takeEvery(types.SIGNUP_SAGA, Signup);
}
function* Forget(action) {
  let response;
  try {
    yield put(wait());
    const remoteServer =
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url
        : types.remote_server;
    response = yield AxiosInstance.post(
      store.getState().whatsapp.fetched_url === true
        ? store.getState()?.whatsapp.base_url +
        "/api/v1/auth/resetPasswordUsingOTP"
        : types.remote_server + "/api/v1/auth/resetPasswordUsingOTP",
      JSON.stringify({
        id_token: action.payload.id,
        password: action.payload.pass,
      })
    );
    yield put(SignupSucces(response.data.data));
    yield put(done());
  } catch (e) {
    if (e instanceof Error) {
    }
  }
}
function* watchForget() {
  yield takeEvery(types.FORGET_SAGA, Forget);
}
function* sms(action) {
  let resp;
  try {
    yield put(wait());
    resp = yield AxiosInstance.post(
      types.sms_server + "/api/v1/otp/verification_code",
      JSON.stringify({
        phoneNumber: action.payload,
        sid: "VA7e1bd88980dd98fff9b75e0f8f47ce89",
      })
    );
    yield put(set_session(resp.data.data.sessionInfo));
    yield put(done());
  } catch (e) {
    yield put(error());
  }
}
function* watchsms() {
  yield takeEvery(types.SMS, sms);
}
function* Verifysms(action) {
  let resp;
  try {
    yield put(wait());
    resp = yield AxiosInstance.post(
      types.sms_server + "/api/v1/otp/verifyPhoneNumber",
      JSON.stringify({
        code: action.payload.code,
        sessionInfo: action.payload.id,
      })
    );
    yield put(set_token(resp.data.data.idToken));
    let resp1 = yield AxiosInstance.post(
      types.user_server + "/api/v2/auth/signup",
      JSON.stringify({
        id_token: resp.data.data.idToken,
        username: "",
        roles: [2],
      })
    );
    yield put({
      type: "SET_TOKEN",
      data: resp1.data.data.access_token,
      user: resp1.data.data,
    });
    yield put({ type: "get_addresses", id: resp1.data.data.id });
    yield put(done());
  } catch (e) {
    yield put(error());
  }
}
function* _getCountriesSaga() {
  let resp;
  try {
    yield put(wait());
    resp = yield AxiosInstance.get(
      `https://antiksef.online/resturant_staging/users_service/public/api/v1/countries`
    );
    yield put(_setCountries(resp.data.data));
    yield put(done());
  } catch (e) {
    yield put(error());
  }
}
function* watchTosms() {
  yield takeEvery(types.VERIFY, Verifysms);
}
function* watchGetCountries() {
  yield takeEvery(types.GET_COUNTRIES_SAGA, _getCountriesSaga);
}

export function* AuthSaga() {
  yield all([
    fork(watchSignup),
    fork(watchlogin),
    fork(watchForget),
    fork(watchsms),
    fork(watchTosms),
    fork(watchGetCountries),
    fork(watchGetRoles)
  ]);
}
