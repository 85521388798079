import React, { useState, useEffect, useRef } from "react";
import Dashboard from "./Dashboard";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import category from "../Routes/Languages/svgs/category.svg";
import { dashboardOptions } from "./constants";
import avat from "../../../assets/svg/avat.png";
import gear from "../../../assets/svg/gear.svg";
import wa from "../../../assets/svg/whatsapp.svg";
import * as types from "../../../redux/auth/constants";
import "../chat/index.css";
import ChatWidget from "../chat";
import ProfileNo from "../WhatsApp/assets/images/profileNo.png";
import { FILE_SERVER, isNew } from "../../../constants";
import Whatsapp from "./Whatsapp";
import { isSupported } from "@firebase/messaging";
import off from "./off.png"
import {
  _LoginWhatsapp,
  getMessagesWithChats,
  getMessagesWithPagination,
  getUnFetchedMessages,
  MessagesFetched,
} from "../../../redux/whatsapp/actions";
import { store } from "../../../redux/store";
import { logout } from "../../../redux/auth/actions";
import { pusher } from "../../../redux/chat/constants";
import { _getAppLang, _changeAppLang } from '../../../Internationalization'
import CallComponent from "../../../Agora/CallComponent";
import Setting from "./Setting";
import AxiosInstance from "../../../helpers/axiosInstance";
function MainPage(props) {
  const getStyle = () => {
    let a = window.pageYOffset || document.documentElement.scrollTop;
    // 
    if (a === 0) return { position: "absolute", zIndex: 99 };
    else return { position: "fixed", zIndex: "99999" };
  };
  const pass = useSelector((state) => state.auth.pass);
  const wa_loading = useSelector((state) => state.whatsapp.wa_loading);
  const sending = useSelector((state) => state.whatsapp.sending);
  const wa_sending = useSelector((state) => state.whatsapp.sending);
  const fetched = useSelector((state) => state.whatsapp.fetched);
  const chats = useSelector((state) => state.whatsapp.chats);
  const contacts = useSelector((state) => state.whatsapp.contacts);
  const newChats = useSelector((state) => state.chat.newChats);
  const isCallIncoming = useSelector(state => state.chat.isCallIncoming)
  const getNew = () => {
    let a = [];
    chatData.filter((s)=>s.channel_type.slug!=="team").map((c) => {
      if (isNew(c.messages) > 0) a.push(c);
    });
    return a;
  };
  const getNewWhats = () => {
    let a = [];
    chats.map((d) => {
      if (parseInt(d.unread) > 0) {
        a.push(d)
      }
    })

    return a
  }
  const setPass = (a) => {
    dispatch({ type: "PASS", payload: a });
  };
  const viewedList = useSelector((state) => state.categories.viewedList);
  const chatData = useSelector((state) => state.chat.data);
  const categories = useSelector((state) => state.categories.categories);
  const refresh = useSelector((state) => state.whatsapp.refresh);
  const user_name = store.getState()?.whatsapp?.username;
  const password = store.getState()?.whatsapp?.password;
  const [chat, openChat] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const [successLogin, setSuccessLogin] = useState(false);
  const WAP= useSelector((state)=>state.whatsapp.WAP);
  const setWA=(a)=>{
    dispatch({type:"OPEN-WA",payload:a})
  }
  const [ActiveLink, setActiveLink] = useState(null);
  const [language, setLanguage] = useState(_getAppLang());
  useEffect(() => {
    if (
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).id &&
      pass
    ) {
      dispatch({type:"GET_AUTH_ROLES"})
      dispatch({ type: "REGION_ASYNC_SAGA" });
      dispatch({ type: "GET_CHAT" });
      dispatch({ type: "GET-EMP" });
    } else {
      props.history.push("/auth/signup");
    }
  }, []);
  const messagesAll = store.getState().whatsapp?.activeChat?.messages || { data: [] };

  useEffect(() => {
    let bool = true
    if (!props.match.params.route) {
      if (bool)
        setActiveLink(null);
    } else if (props.match.params.route?.toLowerCase() === "categories") {
      dashboardOptions.map((m) => {
        m.childreen.map((s) => {
          if (s.name?.toLowerCase() === props.match.params.route?.toLowerCase()) {
            if (bool)
              setActiveLink(s);
          }
        });
      });
    } else {
      dashboardOptions.map((m) => {
        m.childreen.map((s) => {
          if (s.name?.toLowerCase() === props.match.params.route?.toLowerCase()) {
            if (bool)
              setActiveLink(s);
          }
        });
      });
    }
    return () => (bool = false)
  }, [props.match.params]);
  const ref = useSelector((state) => state.categories.ref);

  const dispatch = useDispatch();
  const AddLayout = (laoyout, level, element) => {
    let res = [];
    if (
      element !== null &&
      !element.target.classList.contains("plus-con") &&
      !element.target.classList.contains("disable_click")
    ) {
      if (viewedList.filter((f) => f.name === laoyout.name).length > 0) {
        res = viewedList.filter((k, i) => i < level);
      } else {
        res = viewedList.filter((k, i) => i < level);

        if (res.length === 0) res.push(laoyout);
        else res[level] = laoyout;
      }
      let arr = viewedList;
      dispatch({ type: "ADD_LAYOUT", layout: res });
    }
  };
  const [foc, setFocue] = useState(false);
  const getTime = (d) => {
    const a = new Date();
    if (!d)
      return `${(a.getHours() > 9
        ? a.getHours().toString()
        : "0" + a.getHours().toString()) +
        ":" +
        (a.getMinutes() > 9
          ? a.getMinutes().toString()
          : "0" + a.getMinutes().toString())
        }`;
    else return `${localStorage.getItem("session-start")}`;
  };
  let [ap, setp] = useState(true);
  let [ti, setTi] = useState(0);
  const getSeconds=async ()=>{
    // response = await AxiosInstance.post(
    //   store.getState().whatsapp.fetched_url === true
    //     ? store.getState()?.whatsapp.base_url +
    //         "/wallet-users-backend/public" +
    //         "/api/v1/auth/username/login"
    //     : types.remote_server + "/api/v1/auth/username/login",
    //   JSON.stringify(action.payload)
    // );
  }
  const setTime=async ()=>{
    let response = await AxiosInstance.get(
      store.getState().whatsapp.fetched_url === true
        ? store.getState()?.whatsapp.base_url +
            "/wallet-users-backend/public" +
            "/api/v1/auth/username/login"
        : types.remote_server + `/api/v1/employee_daily_logs/register_active_time/${ti}`
    );
  }
  useEffect(() => {
    if (localStorage.getItem("user")) {
      let u=JSON.parse(localStorage.getItem("user"))?.employee
      getSeconds()
      setTi(parseInt(u?.employee?.employee_daily_log?.executed_working_time)||0);
    } else {
      ;
    }
  }, []);
  useEffect(() => {
    if (token) {
      setTimeout(() => {
        setp(!ap);
        let af = parseInt(localStorage.getItem("time"));
        if(parseInt(af) % 300 === 0){
          dispatch({ type: "GET_WA_CHATS", isFirst: true });
        }
        if (parseInt(af) % 20 === 0) {
          setTime();
         
        }
        localStorage.setItem("time", af + 1);
        
        setTi(af);
      }, 1000);
    }
  }, [ap]);
  const token = store.getState()?.whatsapp?.userTokenWhatsapp;
  const user = store.getState()?.auth?.user;
  const offline = useSelector((state) => state.auth.offline)
  useEffect(() => {
    token && dispatch({ type: "GET_WA_CHATS", isFirst: true }); 
    // const interval = setInterval(() => {
    //   if (!wa_loading && !sending && token && token?.length > 0) {
    //     if (navigator.onLine)
    //     dispatch({ type: "GET_WA_CHATS", isFirst: false });
    //   }
    // }, 28000);
    return (() => {
      ;
    })
  }, [token, user]);
  const getTimes = (a) => {
    if (!a) {
      if (ti < 3600) {
        return (
          <>
            <span className="sem"> / {parseInt(ti / 60)} </span> m
          </>
        );
      } else {
        return (
          <>
            <span className="sem">
              {" "}
              / {parseFloat(ti / 60 / 60).toFixed(2)}{" "}
            </span>{" "}
            h
          </>
        );
        return;
      }
    } else {
      return (
        <>
          <span className="sem">
            {" "}
            / {parseFloat(ti / 60 / 60 - 8).toFixed(2)}{" "}
          </span>{" "}
          h
        </>
      );
    }
  };
  const call = useRef(false)
  return (
    <div className="main">
      {isCallIncoming && <CallComponent reply={() => setWA(true)} />}
      {/* {!navigator.onLine && <div className="off_label">
        <img className="off_img" src={off} />
        <span>You are Offline</span></div>} */}
      {WAP && <Whatsapp open={WAP} close={() => setWA(false)} />}
      {openSetting && <Setting open={openSetting} close={() => setOpenSetting(false)} />}
      {/* {localStorage.getItem("user") && (
        <ChatWidget open={chat} openChat={(e) => openChat(e)} />
      )} */}
      <div
        className={"avatar-account" + ` ${foc && "active-h"}`}
        onMouseLeave={() => setFocue(false)}
      >
        <span className="abs-span" onMouseEnter={() => setFocue(true)}></span>
        {localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).avatar &&
          JSON.parse(localStorage.getItem("user")).avatar.length > 0 ? (
          <img
            onMouseEnter={() => setFocue(true)}
            src={FILE_SERVER + JSON.parse(localStorage.getItem("user")).avatar}
          />
        ) : (
          <img onMouseEnter={() => setFocue(true)} src={avat} />
        )}

        <div className="time-info">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="15"
              height="15"
              viewBox="0 0 15 15"
            >
              <g id="Mask_Group_178" data-name="Mask Group 178">
                <g id="deadline" transform="translate(0.636 0)">
                  <path
                    id="Path_19125"
                    data-name="Path 19125"
                    d="M11.747,5.209a2,2,0,0,0,.311-1.324.778.778,0,0,0-.4-.5.7.7,0,0,0-.556-.052,1.415,1.415,0,0,0-.73.813,5.917,5.917,0,0,0-3.2-.77l-.083-.8c.1-.019.2-.04.319-.059a4.4,4.4,0,0,0,1.365-.4,1.115,1.115,0,0,0,.216-.162A.761.761,0,0,0,9.237,1.3,1.084,1.084,0,0,0,8.7.518,2.041,2.041,0,0,0,7.289.426L4.86.9c-.062.012-.131.024-.2.038-.742.133-1.98.357-1.852,1.472a.873.873,0,0,0,.376.654,1.159,1.159,0,0,0,.644.169,2.565,2.565,0,0,0,.7-.112c.271-.081.544-.143.825-.2l.155.768a5.936,5.936,0,0,0-2.465,1.6A.252.252,0,0,0,2.965,5.2a2.306,2.306,0,0,0-1.208-.333.766.766,0,0,0-.72.366.624.624,0,0,0,.1.661,2.052,2.052,0,0,0,.758.449c.1.045.2.093.288.136a5.986,5.986,0,0,0,5.231,8.87,6.157,6.157,0,0,0,1.194-.119A5.99,5.99,0,0,0,11.747,5.209Zm-.495-1.434a.222.222,0,0,1,.19.024.3.3,0,0,1,.164.2,1.452,1.452,0,0,1-.209.873,5.752,5.752,0,0,0-.63-.485.143.143,0,0,0,.021-.031,1.057,1.057,0,0,1,.464-.585ZM4.4,2.672a1.26,1.26,0,0,1-.958,0,.407.407,0,0,1-.169-.319C3.2,1.728,3.894,1.55,4.741,1.4l.207-.038L7.377.884A3.224,3.224,0,0,1,7.969.815a1,1,0,0,1,.492.107.631.631,0,0,1,.311.433.3.3,0,0,1-.1.264.772.772,0,0,1-.133.1,4.049,4.049,0,0,1-1.21.34c-.162.029-.311.057-.437.086-.326.076-.663.138-.991.2a14.094,14.094,0,0,0-1.5.333Zm1.415.157L5.982,2.8c.214-.04.43-.081.649-.126l.078.742c-.155.019-.309.043-.464.074-.1.019-.195.043-.29.067ZM2.09,5.915a2.886,2.886,0,0,1-.575-.3c-.055-.074-.076-.138-.057-.174s.131-.107.3-.107a1.861,1.861,0,0,1,.963.264.174.174,0,0,0,.045.017c-.119.15-.233.3-.338.464-.1-.05-.214-.1-.34-.159Zm6.429,8.854A5.52,5.52,0,1,1,6.338,3.947a5.575,5.575,0,0,1,1.1-.109A5.52,5.52,0,0,1,8.519,14.769Z"
                    transform="translate(-0.982 -0.347)"
                    fill="#404040"
                  />
                  <path
                    id="Path_19126"
                    data-name="Path 19126"
                    d="M9.591,5.7a.235.235,0,0,0-.319.083L7.663,8.55a.851.851,0,0,0-.43-.024.882.882,0,0,0,.174,1.747.859.859,0,0,0,.174-.017.882.882,0,0,0,.692-1.039A.86.86,0,0,0,8.057,8.8L9.676,6.022A.235.235,0,0,0,9.591,5.7Zm-2.1,4.1a.416.416,0,1,1-.164-.815.492.492,0,0,1,.083-.007.415.415,0,0,1,.228.069.415.415,0,0,1-.147.754Z"
                    transform="translate(-0.982 -0.347)"
                    fill="#404040"
                  />
                  <path
                    id="Path_19127"
                    data-name="Path 19127"
                    d="M5.825,12.86A3.888,3.888,0,0,1,3.488,8.6,4.288,4.288,0,0,1,7.251,5.135a.233.233,0,1,0-.05-.464A4.755,4.755,0,0,0,3.029,8.514a4.387,4.387,0,0,0,2.589,4.762.232.232,0,0,0,.1.024.231.231,0,0,0,.1-.44Z"
                    transform="translate(-0.982 -0.347)"
                    fill="#215bf8"
                  />
                  <path
                    id="Path_19128"
                    data-name="Path 19128"
                    d="M13.8,7.71a4.783,4.783,0,0,0,.04,1.1A4.956,4.956,0,0,0,13.977,9.9,3.064,3.064,0,0,0,14.008,8.8,3.066,3.066,0,0,0,13.8,7.71Z"
                    transform="translate(-0.982 -0.347)"
                    fill="#404040"
                  />
                  <path
                    id="Path_19129"
                    data-name="Path 19129"
                    d="M14.7,9.082a2.968,2.968,0,0,0-.221-1.213,9.915,9.915,0,0,0,.055,1.217,10.435,10.435,0,0,0,.033,1.217A2.953,2.953,0,0,0,14.7,9.082Z"
                    transform="translate(-0.982 -0.347)"
                    fill="#404040"
                  />
                  <path
                    id="Path_19130"
                    data-name="Path 19130"
                    d="M1.833,13.164c-.045,0-.043.262.136.511s.423.335.44.295-.152-.176-.3-.392-.223-.421-.271-.414Z"
                    transform="translate(-0.982 -0.347)"
                    fill="#404040"
                  />
                  <path
                    id="Path_19131"
                    data-name="Path 19131"
                    d="M1.327,13.124a1.349,1.349,0,0,0,.152.92,1.339,1.339,0,0,0,.6.713c.033-.036-.247-.333-.452-.782s-.252-.854-.3-.851Z"
                    transform="translate(-0.982 -0.347)"
                    fill="#404040"
                  />
                </g>
              </g>
            </svg>
          </span>
          <div className="times-in">
            <div className="time-hour">{getTime()}</div>
            <div className="times-work">
              <span className="sem">8</span> h {getTimes()}
            </div>
          </div>
          <div className="times-in out-time">
            <div className="time-hour">{getTime(true)}</div>
            <div className="times-work">
              <span className="sem">8</span> h {getTimes(true)}
            </div>
          </div>
        </div>
        {
          <div className="account-options">
            <svg
              onClick={() => {

                logout(() => { ; })
                dispatch({ type: "log_out" })
                localStorage.removeItem("user");
                props.history.push("/");
                props.history.push("/auth/signup");
              }}
              id="_15x15_photo_back"
              data-name="15x15 photo back"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="15"
              height="15"
              viewBox="0 0 15 15"
            >
              <g id="Mask_Group_179" data-name="Mask Group 179">
                <g id="layer1" transform="translate(0 0)">
                  <path
                    id="path52"
                    d="M8.992,825.387a.752.752,0,0,0-.743.762v6.029a.751.751,0,1,0,1.5,0v-6.029a.752.752,0,0,0-.759-.762Zm4.376,1.511c-.024,0-.049,0-.073,0a.754.754,0,0,0-.416,1.33,6,6,0,1,1-7.779.022.756.756,0,0,0,.084-1.062.748.748,0,0,0-1.058-.084A7.556,7.556,0,0,0,9,840.387a7.556,7.556,0,0,0,4.845-13.3.749.749,0,0,0-.481-.185Z"
                    transform="translate(-1.5 -825.387)"
                    fill="#f80000"
                  />
                </g>
              </g>
            </svg>
            <svg
              onClick={() => {

                setPass(false);
                dispatch({ type: "log_out" })
                props.history.push("/auth/signup");
              }}
              id="_15x15_photo_back"
              data-name="15x15 photo back"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="15"
              height="15"
              viewBox="0 0 15 15"
            >
              <g id="Mask_Group_180" data-name="Mask Group 180">
                <g id="padlock" transform="translate(1.875)">
                  <g id="Group_7828" data-name="Group 7828">
                    <path
                      id="Path_19132"
                      data-name="Path 19132"
                      d="M12.188,6.563V4.688a4.219,4.219,0,0,0-8.437,0V6.563A1.381,1.381,0,0,0,2.344,7.969v6.094A1.41,1.41,0,0,0,3.75,15.469h8.438a1.41,1.41,0,0,0,1.406-1.406V7.969A1.381,1.381,0,0,0,12.188,6.563Zm-7.5-1.875a3.281,3.281,0,0,1,6.563,0V6.563H4.688Zm3.75,6.938v1.031a.469.469,0,0,1-.937,0V11.625a1.365,1.365,0,0,1-.937-1.312,1.406,1.406,0,0,1,2.813,0A1.365,1.365,0,0,1,8.438,11.625Z"
                      transform="translate(-2.344 -0.469)"
                      fill="#404040"
                    />
                  </g>
                </g>
              </g>
            </svg>
            <svg
              className={`${language === "ar" && "active-language"}`}
              onClick={() => {
                setLanguage("ar")
                _changeAppLang("ar")
                dispatch({ type: "TRANSLATE", payload: "ar" })
              }}
              id="Group_4725"
              data-name="Group 4725"
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
            >
              <path
                id="Exclusion_1"
                data-name="Exclusion 1"
                d="M13,15H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H13a2,2,0,0,1,2,2V13A2,2,0,0,1,13,15ZM9.162,8.17h1.559l1.3,2.4a.4.4,0,0,0,.4.262.387.387,0,0,0,.415-.374.552.552,0,0,0-.085-.284L11.561,8.044a1.854,1.854,0,0,0,1.309-1.87,1.886,1.886,0,0,0-2.077-1.941H8.756a.387.387,0,0,0-.407.419v5.759a.407.407,0,1,0,.813,0V8.171Zm-5.654.714H6.087L6.665,10.5a.389.389,0,0,0,.4.311.371.371,0,0,0,.406-.356.665.665,0,0,0-.049-.226l-2.1-5.673a.511.511,0,0,0-.51-.389.525.525,0,0,0-.533.393L2.18,10.23a.665.665,0,0,0-.049.226.371.371,0,0,0,.407.356.387.387,0,0,0,.4-.311l.574-1.617Zm2.348-.69H3.742L4.786,5.222h.027l1.043,2.97Zm4.865-.741H9.162v-2.5h1.522A1.194,1.194,0,0,1,12.03,6.2,1.166,1.166,0,0,1,10.721,7.453Z"
                transform="translate(0 0)"
                fill="#8e8e8e"
              />
            </svg>
            <svg
              className={`${language === "en" && "active-language"}`}
              onClick={() => {
                setLanguage("en")
                dispatch({ type: "TRANSLATE", payload: "en" })
                _changeAppLang("en")
              }}
              id="Group_4727"
              data-name="Group 4727"
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
            >
              <path
                id="Exclusion_4"
                data-name="Exclusion 4"
                d="M13,15H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H13a2,2,0,0,1,2,2V13A2,2,0,0,1,13,15ZM8.286,5.582h.042l3.572,5.1a.506.506,0,0,0,.49.278.433.433,0,0,0,.48-.462V4.476a.419.419,0,1,0-.838,0V9.437H11.99L8.422,4.321a.514.514,0,0,0-.49-.283c-.309,0-.479.155-.479.437v5.992a.439.439,0,0,0,.413.494.4.4,0,0,0,.419-.438V5.583ZM2.554,4.047a.4.4,0,0,0-.424.438V10.4a.4.4,0,0,0,.424.438H6a.382.382,0,1,0,0-.762H2.977V7.743H5.835a.372.372,0,0,0,.4-.377.368.368,0,0,0-.4-.376H2.977V4.811H6a.382.382,0,1,0,0-.763Z"
                transform="translate(0 0)"
                fill="#8e8e8e"
              />
            </svg>
          </div>
        }
      </div>
      <div className="account-cont" style={getStyle()}>
        <div className="lang-top account-top">
          <div className={`top-account-icons`}>
       {(localStorage.getItem("user")&&JSON.parse(localStorage.getItem("user")).role_id===1)&&   <svg onClick={()=>setOpenSetting(true)} style={{marginRight:"10px",height:"22px",fill: openSetting?"#5d5d5d":"#8e8e8e"}} data-src={gear}></svg>}
            <div className="chat-icon" style={{ marginRight: "15px" }}>
              {getNewWhats().length > 0 && !WAP && (
                <div
                  className="chat-top-icons wa-icons"
                  style={{
                    left: "10px",
                  }}
                >
                  {getNewWhats() &&
                    getNewWhats()
                      .slice(0, 3)
                      .map((a, index) => (
                        <div key={index}
                          className="chat-avatar"
                          style={{ left: `0px` }}
                          onClick={() => {

                            dispatch({ type: "CHAT_ID", payload: a });
                            dispatch({ type: "READ_CHAT", id: a.id })
                            setWA(true);
                          }}
                        >
                          <img
                            src={
                              contacts.filter((s)=>s.id===a.whatsapp_contact_id)[0]?.profile_photo || a.profile_photo || ProfileNo
                            }
                          />
                        </div>
                      ))}
                </div>
              )}

              <svg onClick={() => setWA(true)} data-src={wa} ></svg>
              {!chat && getNew().length > 0 && (
                <div
                  onClick={() => {
                    dispatch({ type: "MAIN", payload: "main" });
                    openChat(true);
                    setWA(true);
                  }}
                  className="points-cont"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7"
                    height="7"
                    viewBox="0 0 7 7"
                  >
                    <circle
                      id="Ellipse_355"
                      data-name="Ellipse 355"
                      cx="3.5"
                      cy="3.5"
                      r="3.5"
                      fill="#007cff"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7"
                    height="7"
                    viewBox="0 0 7 7"
                  >
                    <circle
                      id="Ellipse_355"
                      data-name="Ellipse 355"
                      cx="3.5"
                      cy="3.5"
                      r="3.5"
                      fill="#007cff"
                    />
                  </svg>
                </div>
              )}
            </div>
            <svg
              style={{ marginRight: "20px" }}
              id="_20X20_photo_Back"
              data-name="20X20 photo Back"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <g id="Mask_Group_218" data-name="Mask Group 218">
                <g id="bell">
                  <path
                    id="Path_19249"
                    data-name="Path 19249"
                    d="M17.816,14.094a5.582,5.582,0,0,1-1.982-4.271V7.5a5.838,5.838,0,0,0-5-5.767v-.9a.833.833,0,0,0-1.667,0v.9a5.837,5.837,0,0,0-5,5.767V9.823A5.588,5.588,0,0,1,2.177,14.1a1.458,1.458,0,0,0,.948,2.566h13.75a1.458,1.458,0,0,0,.941-2.572Z"
                    fill="#8e8e8e"
                  />
                  <path
                    id="Path_19250"
                    data-name="Path 19250"
                    d="M10,20a3.13,3.13,0,0,0,3.062-2.5H6.938A3.13,3.13,0,0,0,10,20Z"
                    fill="#8e8e8e"
                  />
                </g>
              </g>
            </svg>
            {/* <div className="chat-icon">
              {getNew().length > 0 && !chat && (
                <div
                  className="chat-top-icons"
                  style={{
                    right: `-${(getNew().slice(0, 3).length - 1) * 3}px`,
                  }}
                >
                  {getNew() &&
                    getNew()
                      .slice(0, 3)
                      .map((a, index) => (
                        <div
                        key={index}
                          className="chat-avatar"
                          style={{ left: `${8.5 * index}px` }}
                          onClick={() => {
                            dispatch({ type: "WATCH_CHANNEL", payload: a.id });
                            dispatch({ type: "OPEN-CHAT", payload: a });
                            dispatch({ type: "MAIN", payload: "chat" });
                            openChat(true);
                          }}
                        >
                          <img
                            src={
                              a.channel_members.filter(
                                (a) =>
                                  parseInt(a.user_id) !==
                                  parseInt(
                                    JSON.parse(localStorage.getItem("user")).id
                                  )
                              )[0].user &&
                                a.channel_members.filter(
                                  (a) =>
                                    parseInt(a.user_id) !==
                                    parseInt(
                                      JSON.parse(localStorage.getItem("user")).id
                                    )
                                )[0].user.avatar
                                ? FILE_SERVER +
                                a.channel_members.filter(
                                  (a) =>
                                    parseInt(a.user_id) !==
                                    parseInt(
                                      JSON.parse(localStorage.getItem("user"))
                                        .id
                                    )
                                )[0].user.avatar
                                : avat
                            }
                          />
                        </div>
                      ))}
                </div>
              )}

              <svg
                onClick={() => {
                  dispatch({ type: "GET_CHAT", payload: true });
                  dispatch({ type: "MAIN", payload: "main" });
                  openChat(true);
                }}
                id="_20X20_photo_Back"
                data-name="20X20 photo Back"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <g id="Mask_Group_217" data-name="Mask Group 217">
                  <g
                    id="Bubble_Message"
                    data-name="Bubble Message"
                    transform="translate(-1.403 -1.402)"
                  >
                    <path
                      id="Path_19247"
                      data-name="Path 19247"
                      d="M20.5,17.327A5.881,5.881,0,0,0,19.05,9.516a6.072,6.072,0,0,0-9.782,4.722c0,5.014,5.615,7.121,8.57,5.484a.351.351,0,0,1,.363.007,5.908,5.908,0,0,0,2.983.641.162.162,0,0,0,.121-.278,2.814,2.814,0,0,1-.809-2.765Z"
                      fill={chat ? "#404040" : "#8e8e8e"}
                    />
                    <path
                      id="Path_19248"
                      data-name="Path 19248"
                      d="M18.768,8.469a8.9,8.9,0,0,0-8.456-6.033,8.837,8.837,0,0,0-8.909,8.8,7.086,7.086,0,0,0,1.3,4.54,4.133,4.133,0,0,1-1.19,4.07.242.242,0,0,0,.178.41,8.721,8.721,0,0,0,4.39-.944.515.515,0,0,1,.535-.011,7.006,7.006,0,0,0,3.7.734,9.086,9.086,0,0,0,1.438-.114,6.668,6.668,0,0,1-3.194-5.68A6.784,6.784,0,0,1,18.768,8.469Z"
                      fill={chat ? "#404040" : "#8e8e8e"}
                    />
                  </g>
                </g>
              </svg>
              {!chat && getNew().length > 0 && (
                <div
                  onClick={() => {
                    dispatch({ type: "MAIN", payload: "main" });
                    openChat(true);
                  }}
                  className="points-cont"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7"
                    height="7"
                    viewBox="0 0 7 7"
                  >
                    <circle
                      id="Ellipse_355"
                      data-name="Ellipse 355"
                      cx="3.5"
                      cy="3.5"
                      r="3.5"
                      fill="#007cff"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7"
                    height="7"
                    viewBox="0 0 7 7"
                  >
                    <circle
                      id="Ellipse_355"
                      data-name="Ellipse 355"
                      cx="3.5"
                      cy="3.5"
                      r="3.5"
                      fill="#007cff"
                    />
                  </svg>
                </div>
              )}
            </div> */}
          </div>
        </div>
      </div>

      <Dashboard
        foc={foc}
        ActiveLink={ActiveLink}
        setActiveLink={(e) => setActiveLink(e)}
        viewedList={viewedList}
        AddLayout={(laoyout, level, element) =>
          AddLayout(laoyout, level, element)
        }
      />
    </div>
  );
}

export default withRouter(MainPage);
