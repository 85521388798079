import React, { useEffect, useRef, useState } from 'react'
import { IntersectionElement } from 'react-intersection'
import Spinner from '../Spinner'
import ChatMessage from './ChatMessages'

function ChatConvo({ reply, messages, dateOfConversation, activeChat, setVid,setImg }) {
  const showRoute = (mes, prev, next) => {
    if (prev && prev.message_content && prev.message_content.length > 0 && prev.message_content[0].file_path === "false") {
      return "lonely"
    }
    let type = "lonely";
    if (!prev && !next) {
      return "lonely";
    }
    if (mes.type === "call") return type;
    if (
      (prev &&
        (mes.sender_user_id !== prev.sender_user_id ||
          (mes.sender_user_id === prev.sender_user_id &&
            prev.type === "call")) &&
        next &&
        mes.sender_user_id === next.sender_user_id &&
        next.sender_user_id !== "call") ||
      (!prev &&
        mes.sender_user_id === next.sender_user_id &&
        next.sender_user_id !== "call")
    ) {
      type = "first-chat";
    } else if (
      prev &&
      next &&
      mes.sender_user_id === prev.sender_user_id &&
      prev.type !== "call" &&
      mes.sender_user_id === next.sender_user_id &&
      next.sender_user_id !== "call"
    ) {
      type = "middle-chat";
    } else if (
      prev &&
      mes.sender_user_id === prev.sender_user_id &&
      prev.type !== "call" &&
      ((next && mes.sender_user_id !== next.sender_user_id) ||
        !next ||
        next.sender_user_id === "call")
    ) {
      type = "last-chat";
    }
    return type;
  };
  const showMessages = (mess) => {
    return (<>
      {mess.map((mes, i) => {
        return (
          // <ChatMessage
          //     type={showRoute(
          //       mes,
          //       activeChat?.messages[i - 1],
          //       activeChat?.messages[i + 1]
          //     )}
          //     marg={
          //       (i !== 0 &&
          //         mes.sender_user_id !== 
          //           activeChat.messages[i - 1].sender_user_id) ||
          //       mes.type === "call" ||
          //       (i !== 0 && activeChat.messages[i - 1].type === "call")
          //     }
          //     message={mes}
          //   />
          <ChatMessage setVid={(s)=>setVid(s)} setImg={(ds) => setImg(ds)} GetMessage={(a, b) => GetMessage(a, b)} reply={() => reply(mes)} a={mes} i={i} activeChat={activeChat}

          />
        )
      })}
    </>)
  }
  const [loading, setLoading] = useState(true)
  const lastMsgRef = useRef()
  const DatesMessages = (mes) => {
    let rr = mes
    let lr = mes
    lr?.sort((a, b) =>
      parseInt(new Date(b.created_at)) - parseInt(new Date(a.created_at))
    )
    rr?.sort((a, b) =>
      parseInt(new Date(a.created_at)) - parseInt(new Date(b.created_at))
    )
    let newMes = []
    rr.map((a) => {
      let mesDate = new Date(a.created_at)
      mesDate = `${mesDate.getFullYear()}-${(mesDate.getMonth() + 1) > 9 ? (mesDate.getMonth() + 1).toString() : ("0" + (mesDate.getMonth() + 1).toString())}-${(mesDate.getDate()) > 9 ? mesDate.getDate() : "0" + parseInt(mesDate.getDate()).toString()}`
      if (newMes.filter((item) => item.date === mesDate).length === 0)
        newMes.push({ date: mesDate, messages: [] })
    })
    rr.map((a) => {
      let mesDate = new Date(a.created_at)
      mesDate = `${mesDate.getFullYear()}-${(mesDate.getMonth() + 1) > 9 ? (mesDate.getMonth() + 1).toString() : ("0" + (mesDate.getMonth() + 1).toString())}-${(mesDate.getDate()) > 9 ? mesDate.getDate() : "0" + parseInt(mesDate.getDate()).toString()}`
      if (newMes.filter((newM) => newM.date === mesDate)?.length > 0) {
        let obj = newMes.filter((newMs) => newMs.date === mesDate)[0]
        // if((obj.messages.filter((s)=>s.id===a.id).length===0)||(!a.id))
        // if((obj.messages.filter((s)=>s.mid===a.mid).length===0))
        obj.messages = [...obj.messages, a]
        newMes.map((it) => {
          if (it.date === obj.date) {
            it = { ...obj }
          }
        })

      }

    })
    if (newMes.length > 0)
      return (newMes)
    else {
      let mesDate = new Date()
      mesDate = `${mesDate.getFullYear()}-${(mesDate.getMonth() + 1) > 9 ? (mesDate.getMonth() + 1).toString() : ("0" + (mesDate.getMonth() + 1).toString())}-${(mesDate.getDate()) > 9 ? mesDate.getDate() : "0" + parseInt(mesDate.getDate()).toString()}`

      return [{ date: mesDate, messages: [] }]
    }
  }
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
      lastMsgRef?.current?.scrollIntoView()
    }, 300);
  }, [])
  let now = new Date();
  let nowString = `${now.getFullYear()}-${(now.getMonth() + 1) > 9 ? (now.getMonth() + 1).toString() : ("0" + (now.getMonth() + 1).toString())}-${(now.getDate()) > 9 ? now.getDate() : "0" + parseInt(now.getDate()).toString()}`

  const showDate = (d) => {
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    let day = new Date(d)
    day = days[day.getDay()]
    if (d === nowString)
      return ("Today")
    else if ((new Date(nowString) - new Date(d)) === 86400000) {
      return ("Yesterday")
    }
    else if ((new Date(nowString) - new Date(d)) < (86400000 * 6))
      return (day)
    else
      return (d)
  }
  const GetMessage = (msgId, quoteId) => {

    if (messages.filter((f) => f.id === quoteId).length > 0) {

      var numb = quoteId?.toString()?.match(/\d/g);
      numb = numb?.join("");
      let el = document.querySelector(`#d${numb}`)
      if (el) {
        el.scrollIntoView({ block: "center" })

        setTimeout(() => {
          el.classList.add("backdrop_msg")
        }, 300)
        setTimeout(() => {
          el.classList.remove("backdrop_msg")
        }, 1200)
      }
    }
  }
  return (
    <>
      {(loading) && <Spinner no={true} />}
      <div className={`chat__date-wrapper ${DatesMessages(messages)[0].date === dateOfConversation.current && "scroledk"}`}>
        {dateOfConversation.current && <span className="chat__date"> {showDate(dateOfConversation.current)}</span>}
      </div>
      <>
        {DatesMessages(messages).map((date, dateIndex) => (
          <>
            {!(activeChat?.id && activeChat?.id < 0) ? <IntersectionElement onChange={(e) => { if (e.isIntersecting) dateOfConversation.current = date.date }}>

              <div className="dates-element" aria-details={date.date} key={dateIndex} style={{ position: "relative", zIndex: `${messages.length > 0 ? messages.length - dateIndex : 1}` }}>
                {date.messages.length < 4 && <div className={`chat__date-wrapper normal-date-wrapper`}>
                  <span className="chat__date normal-date"> {showDate(date.date)}</span>
                </div>}
                <div className={`${activeChat?.isGroup === 1 && "group_widg"} chat__msg-group`} >
                  {showMessages(date.messages)}

                </div>
              </div>
            </IntersectionElement>
              :
              <div className="dates-element" aria-details={date.date} key={dateIndex} style={{ position: "relative", zIndex: `${messages.length > 0 ? messages.length - dateIndex : 1}` }}>
                {date.messages.length < 4 && <div className={`chat__date-wrapper normal-date-wrapper`}>
                  <span className="chat__date normal-date"> {showDate(date.date)}</span>
                </div>}

                <div className={`${activeChat?.isGroup === 1 && "group_widg"} chat__msg-group`}>
                  {showMessages(date.messages)}

                </div>
              </div>
            }
            <div style={{ position: "absolute", bottom: "20px" }} ref={lastMsgRef}></div>
          </>
        ))}
      </>
      <div id="scroled"></div>
    </>
  )
}

export default ChatConvo