
import axios from "axios";
import { API } from "./API"
const uploadFile = async (file_name,file, onUploadProgress) => {

    let formData = new FormData();
    formData.append("file", file);
    formData.append("file_name", file_name);
    
    return axios.post(process.env.REACT_APP_BASE_URL+"/wallet_files_service/public/s3/upload_file", formData);
}

export const upload = async (file) => {
    let currentFile = file;
   let a=""
     await uploadFile(currentFile.name.split('.')[0] , file)
        .then((response) => {
            a =response.data.data.file_path
          return response.data.data.file_path;
        })
        .catch((e) => {
          console.error(e);
        });
    return(a)
  };