import { store } from "../store";

export const LOGIN_SAGA = "LOGIN_SAGA";
export const LOGIN_REDUCER = "LOGIN_REDUCER";
export const SIGNUP_SAGA = "SIGNUP_SAGA";
export const SIGNUP_REDUCER = "SIGNUP_REDUCER";
export const FORGET_SAGA = "FORGET_SAGA";
export const FORGET_REDUCER = "FORGET_REDUCER";
export const GET_COUNTRIES_SAGA = "GET_COUNTRIES_SAGA";
export const WAIT = "WAIT";
export const DONE = "DONE";
export const ERROR = "ERROR";
export const sms_server =
  "https://ramaaz.dev/e-wallet-staginv3/sms_service/public";
export const remote_server =
  store?.getState().whatsapp.fetched_url === true
    ? store?.getState()?.whatsapp.base_url+ "/wallet-users-backend/public"
    : process.env.REACT_APP_BASE_URL + "/wallet-users-backend/public";
    export const remote_server_brodcast =
  store?.getState().whatsapp.fetched_url === true
    ? store?.getState()?.whatsapp.base_url+ "/wallet-users-backend/public"
    : process.env.REACT_APP_BASE_URL_BRODCAST + "/wallet-users-backend/public";
    export const remote_role_server =
  store?.getState().whatsapp.fetched_url === true
    ? store?.getState()?.whatsapp.base_url+ "/wallet-permission-backend/public"
    : process.env.REACT_APP_BASE_URL + "/wallet-permission-backend/public";
export const user_server =
  "https://antiksef.online/resturant_staging/users_service/public";

export const SMS = "SMS";
export const VERIFY = "VERIFY";
