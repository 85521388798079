import React from 'react'
import { useSelector } from 'react-redux'
import { forwardToChat } from '../../../../../constants'
import ChatUser from './ChatUser'

function ChatUsers(props) {
const chatsArray=useSelector((state)=>state.chat.data)?.filter((s)=>s?.channel_type.slug!=="team")
  const emps= useSelector(state => state.employee.employee)
  const getChat=(em)=>{
    if(chatsArray.filter((s)=>s.channel_members.filter((m)=>m.user_id===em.id).length>0).length>0){
      return(chatsArray.filter((s)=>s.channel_members.filter((m)=>m.user_id===em.id).length>0)[0])
    }
    else{
      return({
        id:Math.random(),
        messages:[],
        total_unread_message_count:0,
        channel_members:[{user_id:em.id,role_id:em.role_id,user:em},{user_id:JSON.parse(localStorage.getItem("user")).id,role_id:JSON.parse(localStorage.getItem("user")).role_id,user:JSON.parse(localStorage.getItem("user"))}],
        channel_type:{slug:"chat"}
      })
    }
  }
  const getChats=()=>{
    let arr=[]
    // getUnEmployeeChats().slice(0,8).map((s)=>arr.push(s))
    // emps.filter((s)=>s.id!==JSON.parse(localStorage.getItem("user")).id).map((s)=>{
    //   if(arr.filter((f)=>f.channel_members.filter((d)=>d.user_id===s.id).length>0).length===0)
    //   arr.push(getChat(s))
    // })
    return(chatsArray.filter((s)=>s.channel_type.slug!=="team"))
  }
  return (
    <div className="chat-users-container">
      {getChats().map((em,index)=>(
        <ChatUser forwardChat={()=>{
          if(props.forwarderMessage){
            forwardToChat(props.forwarderMessage,em)
          }
        }} key={index} chat={em} photo={em}/>
      ))}
       {/* {getUnEmployeeChats().slice(0,8).map((em)=>(
           <ChatUser chat={em} photo={em}/>
        ))}
        {getUnEmployeeChats().length <8&&emps.filter((s)=>s.id!==JSON.parse(localStorage.getItem("user")).id).slice(0,(8-chatsArray.length)).map((em)=>(
       <ChatUser chat={getChat(em)} photo={getChat(em)}/>
        ))}
       
        {chatsArray.length<8&&emps.filter((s)=>s.id!==JSON.parse(localStorage.getItem("user")).id&&notInclude(s.id)).slice(0,8-(chatsArray.length+getUnEmployeeChats().length)).map((em)=>(
           (!getChat(em).channel_translations)&&<ChatUser chat={getChat(em)} photo={getChat(em)}/>
        ))} */}
    </div>
  )
}

export default ChatUsers