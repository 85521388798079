import category from "../Routes/Languages/svgs/category.svg"
import Countries from "../Routes/Languages/svgs/Countries.svg"
import Langs from "../Routes/Languages/svgs/Langs.svg"
import Currencies from "../Routes/Languages/svgs/Currencies.svg"
import HRM from "../Routes/Languages/svgs/HRM.svg"
import Transfer from "../../../assets/svg/Transfer.svg"
export const dashboardOptions = [
    // {
    //     id: 1,
    //     name: "Setting",
    //     key:"setting",
    //     icon: null,
    //     add: false,
    //     childreen: [
    //         {
    //             id: 2,
    //             name: "Categories",
    //             key:"categories",
    //             add: true,
    //             icon: category,
    //             routes: "category"
    //         }

    //     ]
    // },
    {
        id: 2,
        name: "Work Desk",
        key: "work_desk",
        icon: null,
        add: false,
        childreen: [
            {
                id: 2,
                name: "Transfer",
                key: "transfer",
                add: true,
                icon: Transfer,
                routes: "Transfer"
            },
            {
                id: 2,
                name: "Accounting",
                key: "accounting",
                add: true,
                icon: category,
                routes: "accounting"
            },
            // {
            //     id: 3,
            //     name: "Monitoring",
            //     key: "monitoring",
            //     add: true,
            //     icon: category,
            //     routes: "monitoring"
            // }

        ],
    },

    {
        id: 3,
        name: "System",
        key: "system",
        icon: null,
        add: null,
        childreen: [
            //{id:11, name:"Languages",  routes:"full-content", add:true, icon:Langs, childreen:[]},
            { id: 11, name: "Languages", key: "languages", routes: "full-content", add: true, icon: Langs, childreen: [] },
            { id: 12, name: "Translate", key: "translate", routes: "full-content", add: true, icon: Langs, childreen: [] },
            { id: 2, name: "Countries", key: "countries", routes: "full-content", add: true, icon: Countries, childreen: [] },
            { id: 2, name: "Currencies", key: "currencies", routes: "full-content", add: true, icon: Currencies, childreen: [] },]
    },
    {
        id: 3,
        name: "Management",
        key: "management",
        icon: null,
        add: null,
        childreen: [{ id: 1, name: "HRM", key: "hrm", routes: "HRM", add: true, icon: HRM, childreen: [] },

        ]
    }
    // {id:2,
    //     name:"System",
    //     icon:null,
    //     add:null,
    //     childreen:[
    //     //{id:11, name:"Languages",  routes:"full-content", add:true, icon:Langs, childreen:[]},
    //     {id:11, name:"Languages",  routes:"full-content", add:true, icon:Langs, childreen:[]},
    //     {id:12, name:"Translate",  routes:"full-content", add:true, icon:Langs, childreen:[]},
    //     {id:2, name:"Countries",  routes:"full-content", add:true, icon:Countries, childreen:[]},
    //     {id:2, name:"Currencies",  routes:"full-content", add:true, icon:Currencies, childreen:[]},]
    // },
    // {id:3,
    //     name:"Management",
    //     icon:null,
    //     add:null,
    //     childreen:[{id:1, name:"HRM",  routes:"HRM", add:true, icon:HRM, childreen:[]},

    // ]
    //     }
]
export const langsdata = [
    { id: 1, name: "Arabic", icon: "AR", childs: [{ id: 11, name: "Syria", icon: "SY", childs: [{ id: 111, name: "Damascus", icon: "DA", childs: [] }, { id: 112, name: "Aleppo", icon: "AL", childs: [] }, { id: 113, name: "Latakia", icon: "LA", childs: [] }] }, { id: 12, name: "Iraq", icon: "IQ", childs: [] }, { id: 12, name: "Lebanon", icon: "LB", childs: [] }] },
    { id: 2, name: "Turkish", icon: "TR", childs: [] },
    { id: 3, name: "English", icon: "EN", childs: [] }
]
export const GetTransValidate = (arr) => {
    let bool = true
    arr.map((name) => {
        if (name.name.length === 0)
            bool = false
    })
    return bool
}