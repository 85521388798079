
import accounting from  "../../../assets/svg/accounting.svg"
import sales from  "../../../assets/svg/sales.svg"
import CFO from  "../../../assets/svg/CFO.svg"
import operations from  "../../../assets/svg/operations.svg"
const initialState = {
  view:"Currencies",
  currencies:[],
  pageFollower:["currencies"],
  newCurrency:{translations:[{name:"",language_code:"en"},{name:"",language_code:"ar"},{name:"",language_code:"tr"}],icon:null},
  mode:"read",
  open:false,
  modalData:{name:"",icon:null,code:""},
  loading:false,
  Active_Ids:[],
  request_running:false,
  request_data:null,
  message:"",
  error:false,
  img_upload:{type:"",status:false}

}
 export const CurrReducer=(state = initialState, { type, payload }) => {
  switch (type) {
    case "IMG-UPLOAD-CUR":{
      return({
        ...state,
        img_upload:{...payload}
      })
    }
    case "request-running-curr":{
      return({
        ...state,
        request_running:true,
        request_data:payload
      })
    }
    case "GENERAL-ERR-curr":{
      return({
        ...state,
        request_running:false,
        request_data:payload.data,
        error:true,
        message:payload.message
      })
    }
    case "request-running-curr-red":{
      
      return({
        ...state,
        request_running:false,
        request_data:payload,
        error:false,
        message:""
      })
    }
    case "GENERAL-LOADING-CUR":{
      return({
        ...state,
        loading:true
      })
    }
    case "VIEW-CUR":{
      return({
        ...state,
        view_language:payload
      })
    }
    case "GENERAL-DONE-CUR":{
      return({
        ...state,
        loading:false,
        open:false,
        

      })
    }
    case "LEVEL-DONE-CUR":{
      return({
        ...state,
        loading:false
      })
    }
    case "GET-CUR-REDUCER":{
      return({...state,currencies:[...payload],request_data:null,request_running:false,error:false,message:""})

    }
    case "Active-add":{
      return({
        ...state,
        Active_Ids:payload
      })
    }
  case "CUR-VIEW":
    return { 
      ...state,
       view:payload,
      pageFollower:[payload]
      }
    case "NEW-CUR":{
      
    
      return{
        ...state,
        data:{...payload}
      }
    }
    case "OPEN-CUR":{
      return({
        ...state,
        open:payload
      })
    }
    case "MODE-CUR":{
      return({
        ...state,
        mode:payload
      })
    }
    case "MOD-DATA-CUR":{
      return({
        ...state,
        modalData:{...payload}
      })
    }
    
  default:
    return state
  }
}
  