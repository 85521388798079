import Icon from "../../../components/Icon";
import React, { useEffect } from "react";

import { store } from "../../../../../../redux/store";
import { useRef } from "react";
import date from 'date-and-time';
import Spinner from "../../../../Spinner";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Message from "./Message";
import { toast } from "react-toastify";
// import { IntersectionElement } from "react-intersection";
const Convo = ({img,setImg,setVid,vid,getAudioMessages,  messages: allMessages, lastMsgRef ,replyMessage,dateOfConversation}) => {
 const dispatch=useDispatch()
 const qoutedMessage=useSelector((state)=>state.whatsapp.qoutedMessage)
  const GetMessage=(msgId,quoteId)=>{
  
    if(allMessages.filter((f)=>f.id===quoteId).length>0){
      var numb = quoteId
      numb = numb
     let el= document.querySelector(`#s${numb}`)
     if(el){
     el.scrollIntoView({block:"center"})

     setTimeout(()=>{
      el.classList.add("backdrop_msg")
     },300)
     setTimeout(()=>{
      el.classList.remove("backdrop_msg")
     },1200)}
    }
    else{
      dispatch({type:"GET_MES",cid:activeChat.id,qid:allMessages[0].id,sid:quoteId})
    }
  }
  useEffect(()=>{
    setTimeout(() => {
      if(qoutedMessage)
     {       
      var numb = qoutedMessage
      numb = numb
      
     let el= document.querySelector(`#s${numb}`)
     if(el){
      
      el.scrollIntoView({block:"center"})
    }
    }
    }, 1000);
  },[qoutedMessage])

  // var viewedDate=useRef("")
  useEffect(()=>{},[dateOfConversation])

  const activeChat = store.getState().whatsapp?.activeChat;
  const getSowedDate=()=>{
   return(nowString)
  }
  const [play,setPlay]=useState(false)

  const active=useSelector((state)=>state.whatsapp.activeChat)
  const is_fetch=useSelector((state)=>state.whatsapp.is_fetch)
  const CopyText=(text)=>{
    if(text&&text.length>0){
      let elem=document.querySelector("#text-copy");
      elem.value=text
      elem.select()
      document.execCommand('Copy');
      toast.success("Copied!")
    }
  }
  const showMessages=(mes)=>{
    return(
      <>
    {mes?.map((a,i)=>{
      if(a.whatsapp_chat_id===activeChat?.id||a.cid===activeChat?.id||a.chat_id===activeChat?.id)
     return(<Message copy={()=>CopyText(a.body)} getAudioMessages={()=>getAudioMessages()} key={i} GetMessage={(m,n)=>GetMessage(m,n)} replyMessage={replyMessage} active={active} img={img} vid={vid} setVid={(e)=>setVid(e)} setImg={(e)=>setImg(e)} activeChat={activeChat} a={a} i={i}/>)
  }
    )}
      </>
      )
  }
  const getMessageTime = (dss) => {
    let no = new Date();
    let d = new Date(dss?.sent_at);
    if (no.getHours() === d.getHours()) {
      return d
    }
    d = d.getTime() + 3 * 60 * 60 * 1000;
    return d
  };
  const  DatesMessages=(mes)=>{
    let rr=mes

 rr.sort((a,b)=>
    parseInt(new Date(a.sent_at).getTime()) - parseInt(new Date(b.sent_at).getTime())
 )
 let arr=[]
 rr.map((s)=>{
  arr.push({...s,sent_at:getMessageTime(s)})
 })
    let newMes=[]
    arr.map((a)=>{
    let mesDate=new Date(a.sent_at)
    mesDate=`${mesDate.getFullYear()}-${(mesDate.getMonth()+1)>9?(mesDate.getMonth()+1).toString():("0"+(mesDate.getMonth()+1).toString())}-${(mesDate.getDate())>9?mesDate.getDate():"0"+parseInt(mesDate.getDate()).toString()}`
    if(newMes.filter((item)=>item.date===mesDate).length===0)
    newMes.push({date:mesDate,messages:[]})
    })
    arr.map((a)=>{
    let mesDate=new Date(a.sent_at)
    mesDate=`${mesDate.getFullYear()}-${(mesDate.getMonth()+1)>9?(mesDate.getMonth()+1).toString():("0"+(mesDate.getMonth()+1).toString())}-${(mesDate.getDate())>9?mesDate.getDate():"0"+parseInt(mesDate.getDate()).toString()}`
    if(newMes.filter((newM)=>newM.date===mesDate)?.length>0){
        let obj=newMes.filter((newMs)=>newMs.date===mesDate)[0]
        obj.messages=[...obj.messages,a]
        newMes.map((it)=>{
          if(it.date===obj.date){
            it={...obj}
          }
        })

      }
      
    })
   if(newMes.length>0)
    return(newMes )
    else{
      let mesDate=new Date()
      mesDate=`${mesDate.getFullYear()}-${(mesDate.getMonth()+1)>9?(mesDate.getMonth()+1).toString():("0"+(mesDate.getMonth()+1).toString())}-${(mesDate.getDate())>9?mesDate.getDate():"0"+parseInt(mesDate.getDate()).toString()}`
     
      return [{date:mesDate,messages:[]}]
    }
  }
  useEffect(()=>{
    setTimeout(() => {
  
      lastMsgRef?.current?.scrollIntoView()
    }, 300);
  },[])
  let now=new Date();
  const showDate=(d)=>{
    var days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];

    let day=new Date(d)
    day=days[day.getDay()]
    if(d===nowString)
    return("Today")
    else if((new Date(nowString) - new Date(d))===86400000){
      return("Yesterday")
    }
    else if((new Date(nowString) - new Date(d))<(86400000*6))
    return(day)
    else
    return(d)
  }
  let nowString=`${now.getFullYear()}-${(now.getMonth()+1)>9?(now.getMonth()+1).toString():("0"+(now.getMonth()+1).toString())}-${(now.getDate())>9?now.getDate():"0"+parseInt(now.getDate()).toString()}`

  return (
      <>
      <textarea style={{opacity:0,height:'0px'}} id="text-copy"/>
       {(is_fetch)&&<Spinner no={true}/>}

     {/* <div className={`chat__date-wrapper ${DatesMessages(allMessages)[0].date===dateOfConversation.current&&"scroledk"}`}>
     { dateOfConversation.current&&     <span className="chat__date"> {showDate(dateOfConversation.current)}</span>}
          </div> */}
         {allMessages&&allMessages&&
         <>
         { DatesMessages(allMessages).map((date,dateIndex)=>(
         <>
          {!active?.new?
          // <IntersectionElement key={dateIndex} onChange={(e)=>{if(e.isIntersecting)dateOfConversation.current=date.date}}>
          
          <div className="dates-element"  aria-details={date.date} key={dateIndex} style={{position:"relative",zIndex:`${allMessages.length-dateIndex}`}}>
     {  <div className={`chat__date-wrapper normal-date-wrapper`}>
       <span className="chat__date normal-date"> {showDate(date.date)}</span>
        </div>}
        <div className={`${"group_widg"} chat__msg-group`}>
            {showMessages(date.messages)}
           
        </div>
      </div>
        // </IntersectionElement>
      :
      <div className="dates-element"  aria-details={date.date} key={dateIndex} style={{position:"relative",zIndex:`${allMessages.length-dateIndex}`}}>
      {date.messages.length<4&&  <div className={`chat__date-wrapper normal-date-wrapper`}>
        <span className="chat__date normal-date"> {showDate(date.date)}</span>
         </div>}
       
         <div className={`${"group_widg"} chat__msg-group`}>
             {showMessages(date.messages)}
            
         </div>
       </div>  
      }
         </>
        
         ))}
         </>
         }
      
      </>
  );
};

export default Convo;