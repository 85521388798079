
import accounting from  "../../../assets/svg/accounting.svg"
import doll from  "../../../assets/svg/doll.svg"
import turk from  "../../../assets/svg/turk.svg"
import syr from  "../../../assets/svg/syr.svg"
import Irq from "../../../assets/svg/irq.svg"
import leb from "../../../assets/svg/leb.svg"
const initialState = {
  view:"Countries",
  test:[],
  countries:[],
  pageFollower:["Employees"],
  newDepartment:{id:1,short_name:"",translations:[{name:"",code:"",language_code:"en"}],company_phone_codes:"",country_id:"",photo_path:null,flat_photo_path:null},
  mode:"read",
  open:false,
  modalData:{name:"",icon:null,code:""},
  loading:false,
  Active_Ids:[],
  request_running:false,
  request_data:null,
  message:"",
  company_regions:[],
  regions:[],
  error:false,
  img_upload:{type:"",status:false}

}
 export const CompainesReducer=(state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_COM_REG_RED":{
      return({
        ...state,
        newDepartment:{...state.newDepartment,company_regions:[...state.newDepartment.company_regions,payload]}
      })
    }
    case "IMG-UPLOAD-COM":{
      return({
        ...state,
        img_upload:{...payload}
      })
    }
    case "request-running-COM":{
      return({
        ...state,
        request_running:true,
        request_data:payload
      })
    }
    case "GENERAL-ERR-COM":{
      return({
        ...state,
        request_running:false,
        request_data:payload.data,
        error:true,
        message:payload.message
      })
    }
    case "request-running-COM-red":{
      
      return({
        ...state,
        request_running:false,
        request_data:payload,
        error:false,
        message:""
      })
    }
    case "GENERAL-LOADING-COM":{
      return({
        ...state,
        loading:true
      })
    }
    case "VIEW-COM":{
      return({
        ...state,
        view_language:payload
      })
    }
    case "GENERAL-DONE-COM":{
      return({
        ...state,
        loading:false,
        open:false,
        

      })
    }
    case "LEVEL-DONE-COM":{
      return({
        ...state,
        loading:false
      })
    }
    case "GET-COM-REDUCER":{
      const arr =[]
      payload.map((k)=>{
        let ad=[]
        k.region_translations.map((l)=>{
          ad.push({...l,name:l.region_name})
        })
        arr.push({...k,translations:[...ad]})
      })
      return({...state,countries:[...arr],request_data:null,request_running:false,error:false,message:""})

    }
    case "Active-add":{
      return({
        ...state,
        Active_Ids:payload
      })
    }
  case "COM-VIEW":
    return { 
      ...state,
       view:payload,
      pageFollower:[payload]
      }
    case "NEW-COM":{
      
    
      return{
        ...state,
        newDepartment:{...payload}
      }
    }
    case "OPEN-COM":{
      return({
        ...state,
        open:payload
      })
    }
    case "MODE-COM":{
      return({
        ...state,
        mode:payload
      })
    }
    case "MOD-DATA-COM":{
      return({
        ...state,
        modalData:{...payload}
      })
    }
    
  default:
    return state
  }
}
  