import React from "react";
import { useSelector } from "react-redux";
import mes from "../../../../../assets/svg/mess.svg"
import { FILE_SERVER } from "../../../../../constants";
import ProfileNo from "../../../WhatsApp/assets/images/profileNo.png";

const Alert = (props) => {
	const emps= useSelector(state => state.employee.employee)
	const isNew =(ch,i)=>{
		let  a=ch&&ch?.filter((mes)=>((mes.sender_user_id!==JSON.parse(localStorage.getItem("user"))?.id&&mes.sender_user_id===i)||(mes?.sender_mobile_phone===i))&&mes.message_status.filter((st)=>st.user_id===JSON.parse(localStorage.getItem("user")||(st.user_id===null))?.id)[0]?.is_watched===false).length
	  return(a)
	  }
	return (
	<div className="chat-section">
	{!(props.noAdmin)&&props.chat?.id&&
	<div className="groubManager" style={{width:props.noGroup&&"100%"}} onClick={()=>props.onClick()}>
		<img src={props?.chat?.whatsapp_contact.profile_photo??ProfileNo}  className="avatar-group"/>
		<div className="groupText">
		<div className="manager-label">General Manager</div>
		<div className="manager-name">{props.name}</div>
		</div>


		{parseInt(props.new)>0&&<div className="unread-mes-component">
			<svg data-src={mes}></svg>
		<div className='new-mes'>{props.new}</div>
		</div>}
	</div>
	}
	{!props.noGroup&&props.chatG?.id&&<div onClick={()=>props.openChat()} className="groubChat">
		<div className="groubChatName">
		All Vision Team
		</div>
		<div className="groupChatUsers">
		{props.chatG.channel_members.filter((s)=>s.user_id===null).length>0&&<div  className={`${props.chatG&&isNew(props.chatG?.messages,props.chatG.channel_members.filter((s)=>s.user_id===null)[0].mobile_phone)>0&&"newUser"} groupUser`}>
					<img src={props.chatG.channel_members.filter((s)=>s.user_id===null)[0]?.profile_photo?props.chatG.channel_members.filter((s)=>s.user_id===null)[0]?.profile_photo:ProfileNo} />
					<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
					<g id="Rectangle_5026" data-name="Rectangle 5026" fill="none" stroke="#0a5cf7" strokeWidth="0.5">
						<rect width="25" height="25" rx="8" stroke="none"/>
						<rect x="0.25" y="0.25" width="24.5" height="24.5" rx="7.75" fill="none"/>
					</g>
					</svg>
					<div className="unread-mes">
						{props.chatG&&isNew(props.chatG?.messages,props.chatG.channel_members.filter((s)=>s.user_id===null)[0].mobile_phone)>0&&isNew(props.chatG?.messages,props.chatG.channel_members.filter((s)=>s.user_id===null)[0].mobile_phone)}
					</div>
				</div>}
			{emps.filter((m)=>m.id!==JSON.parse(localStorage.getItem("user")).id).slice(0,7).map((s,index)=>(
				<div key={index} className={`${props.chatG&&isNew(props.chatG?.messages,s.id)>0&&"newUser"} groupUser`}>
					<img src={s.avatar?FILE_SERVER+s.avatar:ProfileNo} />
					<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
					<g id="Rectangle_5026" data-name="Rectangle 5026" fill="none" stroke="#0a5cf7" strokeWidth="0.5">
						<rect width="25" height="25" rx="8" stroke="none"/>
						<rect x="0.25" y="0.25" width="24.5" height="24.5" rx="7.75" fill="none"/>
					</g>
					</svg>
					<div className="unread-mes">
						{props.chatG&&isNew(props.chatG?.messages,s.id)>0&&isNew(props.chatG?.messages,s.id)}
					</div>
				</div>
			))}
			
		</div>
	</div>}
	</div>);
};

export default Alert;
