import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LanguagetItem from "./LanguagetItem.js"
import LanguagesModal from "./LanguagesModal"
function LangBody(props) {
    const dispatch = useDispatch()
    const requested_data = useSelector((state)=>state.langs.request_data)

    const ActiveAdd =(e)=>{
      dispatch({type:"Active-add-LANG",payload:e})
    }
    const {lang_id, setLang_id}= props
    const langs = useSelector(state => state.langs.languages)
    const {languageFollower,makeFollower, setLanguageFollower} = props
    const data = useSelector(state => state.langs.newLang)
    const mode = useSelector(state => state.langs.mode)
    const Active_Ids = useSelector(state => state.langs.Active_Ids)
       return (
        <>
    { (lang_id===0||lang_id===null)&&props.open&&<LanguagesModal Active_Ids={Active_Ids} ActiveAdd={()=>props.ActiveAdd(data.id)} languageFollower={props.languageFollower} setExpand={(e)=>{}} data={data} {...props}/>}
        <div className='lang-body'>
          {
            langs.map((dept)=>(
              <>
              
             {dept.translations&&dept.translations.length>0&& <LanguagetItem
              lang={props.lang}
              ActiveAdd={(e)=>ActiveAdd(e)}
              open={props.open}
              Close={()=>props.Close()}
              Add={(e)=>props.Add(e)}
              Update={(e)=>props.Update(e)}
              Read={(e)=>props.Read(e)}
              setModalData={(e)=>props.setModalData(e)}
              setOpen={(e)=>props.setOpen(e)}
               child={dept}
               Active_Ids={Active_Ids}
               languageFollower={props.languageFollower}
               level={0}
               makeFollower={(level,value,id)=>makeFollower(level,value,id)}
               lang_id={lang_id}
               setLang_id={(e)=>setLang_id(e)}
               />}</>
             
            ))
          }
          {((props.open&&(props.lang_id===null||props.lang_id===0)&&mode==="add")||(requested_data&&requested_data.parent_language_code===null&&mode==="add"))   && <LanguagetItem
              open={props.open}
              lang={props.lang}
              Close={()=>props.Close()}
              Add={(e)=>props.Add(e)}
              Update={(e)=>props.Update(e)}
              new={"new"}
              Read={(e)=>props.Read(e)}
              setOpen={(e)=>props.setOpen(e)}
               child={data}
               languageFollower={props.languageFollower}
               level={1}
               makeFollower={(level,value,id)=>makeFollower(level,value,id)}
               lang_id={lang_id}
               setLang_id={(e)=>setLang_id(e)}
               />}
        </div>
        </>
      )
}
export default LangBody