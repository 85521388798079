import React, { useEffect } from 'react'
import JobItem from './JobItem'
import { useDispatch, useSelector } from 'react-redux'
import JobModal from './JobModal'

function JobTitles(props) {
  const dispatch = useDispatch()
  const requested_data = useSelector((state)=>state.jobs.request_data)

  const ActiveAdd =(e)=>{
    dispatch({type:"Active-add",payload:e})
  }
  const {lang_id, setLang_id}= props
  const {languageFollower,makeFollower, setLanguageFollower} = props
  const jobs = useSelector(state => state.jobs.jobs)
  const data = useSelector(state => state.jobs.data)
  const mode = useSelector(state => state.jobs.mode)
  const Active_Ids = useSelector(state => state.jobs.Active_Ids)
  return (
    <>
{ lang_id===0||lang_id===null&&props.open&&<JobModal Active_Ids={Active_Ids} ActiveAdd={()=>props.ActiveAdd(data.id)} languageFollower={props.languageFollower} setExpand={(e)=>{}} data={data} {...props}/>}
    <div className='lang-body'>
      {
        jobs.map((dept)=>(
          <JobItem
          lang={props.lang}
          ActiveAdd={(e)=>ActiveAdd(e)}
          open={props.open}
          Close={()=>props.Close()}
          Add={(e)=>props.Add(e)}
          Update={(e)=>props.Update(e)}
          Read={(e)=>props.Read(e)}
          setModalData={(e)=>props.setModalData(e)}
          setOpen={(e)=>props.setOpen(e)}
           child={dept}
           
           languageFollower={props.languageFollower}
           level={1}
           makeFollower={(level,value,id)=>makeFollower(level,value,id)}
           lang_id={lang_id}
           setLang_id={(e)=>setLang_id(e)}
           />
        ))
      }
      {((props.open&&mode==="add")||(requested_data&&mode==="add"))&& <JobItem
          open={props.open}
          Close={()=>props.Close()}
          lang={props.lang}
          Add={(e)=>props.Add(e)}
          Update={(e)=>props.Update(e)}
          new={"new"}
          Read={(e)=>props.Read(e)}
          setOpen={(e)=>props.setOpen(e)}
           child={requested_data?requested_data:data}
           languageFollower={props.languageFollower}
           level={1}
           makeFollower={(level,value,id)=>makeFollower(level,value,id)}
           lang_id={lang_id}
           setLang_id={(e)=>setLang_id(e)}
           />}
    </div>
    </>
  )
}

export default JobTitles