import React, { useState } from 'react'
import { useRef } from 'react';
import Tesseract from 'tesseract.js';
import "./index.css"
import preprocessImage from './preprocess';
function OCR() {

    const [imagePath, setImagePath] = useState("");
  const [text, setText] = useState("");
 
  const handleChange = (ev) => {
    setImagePath(URL.createObjectURL(ev.files[0]));
  }
 
  const handleClick = (v) => {
  
    Tesseract.recognize(
      v,'eng',
      { 
        logger: m => {;}
      }
    )
    .catch (err => {
      console.error(err);
    })
    .then(result => {
      // Get Confidence score
      
      setText(result.data.text);
      document.getElementById("text").innerHTML=result.data.text
  
    })
  }

  return (
    <div className="App">
      <main className="App-main" id="App-main">
        <h3>Actual imagePath uploaded</h3>
        <img id="image"
           src={imagePath} className="App-image" alt="logo"/>
        
          <h3>Extracted text</h3>
        <div className="text-box">
          <p ><pre id="text"></pre>  </p>
        </div>
        <input type="file" onChange={(e)=>handleChange(e.target)} />
        <button onClick={(e)=>handleClick(imagePath)} style={{height:50}}> convert to text</button>
      </main>
    </div>
  );
}

export default OCR