import { all, fork, put, takeEvery } from "@redux-saga/core/effects";
import AxiosInstance, {
  AxiosFileInstance,
} from "../../../helpers/axiosInstance";
import { toast } from "react-toastify";
import { ADD_DEPT_URL, GET_DEPT_URL, UPD_DEPT_URL } from "./constants";
import store from "../../store";
import { GET_LANGS_URL } from "../../LinkedLanguages/constants";
function* getAllDepartments(action) {
  let response;
  try {
    yield put({ type: "GENERAL-LOADING-DEPT" });
    response = yield AxiosInstance.get(
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url +
            "/wallet-users-backend/public" +
            GET_DEPT_URL
        : process.env.REACT_APP_BASE_URL +
            "/wallet-users-backend/public" +
            GET_DEPT_URL
    );
    let langs = yield AxiosInstance.get(GET_LANGS_URL);
    yield put({ type: "GET-LANG-REDUCER", payload: langs.data.data });
    yield put({ type: "GENERAL-DONE" });
    yield put({ type: "GET-DEPT-REDUCER", payload: response.data.data });
    yield put({ type: "GENERAL-DONE-DEPT" });
  } catch (e) {
    yield put({ type: "GENERAL-DONE-DEPT" });

    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchGetAllDepartments() {
  yield takeEvery("GET-DEPT", getAllDepartments);
}
function* AddDepartments(action) {
  let response, responses;
  try {
    yield put({ type: "GENERAL-DONE-DEPT" });
    yield put({ type: "request-running-dept", payload: action.payload });
    response = yield AxiosInstance.post(
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url +
            "/wallet-users-backend/public" +
            ADD_DEPT_URL
        : process.env.REACT_APP_BASE_URL +
            "/wallet-users-backend/public" +
            ADD_DEPT_URL,
      JSON.stringify(action.payload)
    );
    yield put({ type: "request-running-dept-red", payload: action.payload });
    setTimeout(() => {
      store.dispatch({ type: "request-running-dept-red", payload: null });
    }, 4000);
    yield put({ type: "GENERAL-DONE-DEPT" });
    responses = yield AxiosInstance.get(
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url +
            "/wallet-users-backend/public" +
            GET_DEPT_URL
        : process.env.REACT_APP_BASE_URL +
            "/wallet-users-backend/public" +
            GET_DEPT_URL
    );
    yield put({ type: "GET-DEPT-REDUCER", payload: responses.data.data });
  } catch (e) {
    yield put({
      type: "GENERAL-ERR-dept",
      payload: { data: action.payload, message: e.message },
    });
    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchAddDepartments() {
  yield takeEvery("ADD-DEPT", AddDepartments);
}
function* UpdateDepartments(action) {
  let response, responses;
  try {
    yield put({ type: "GENERAL-DONE-DEPT" });
    yield put({ type: "request-running-dept", payload: action.payload });
    response = yield AxiosInstance.post(
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url +
            "/wallet-users-backend/public" +
            UPD_DEPT_URL
        : process.env.REACT_APP_BASE_URL +
            "/wallet-users-backend/public" +
            UPD_DEPT_URL,
      JSON.stringify(action.payload)
    );
    yield put({ type: "request-running-dept-red", payload: action.payload });
    setTimeout(() => {
      store.dispatch({ type: "request-running-dept-red", payload: null });
    }, 4000);
    yield put({ type: "GENERAL-DONE-DEPT" });
    responses = yield AxiosInstance.get(
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url +
            "/wallet-users-backend/public" +
            GET_DEPT_URL
        : process.env.REACT_APP_BASE_URL +
            "/wallet-users-backend/public" +
            GET_DEPT_URL
    );

    yield put({ type: "GET-DEPT-REDUCER", payload: responses.data.data });
    yield put({ type: "GENERAL-DONE-DEPT" });
  } catch (e) {
    yield put({
      type: "GENERAL-ERR-dept",
      payload: { data: action.payload, message: e.message },
    });
    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchUpdDepartments() {
  yield takeEvery("UPD-DEPT", UpdateDepartments);
}

export function* DepartmentsSaga() {
  yield all([
    fork(watchGetAllDepartments),
    fork(watchUpdDepartments),
    fork(watchAddDepartments),
  ]);
}
