import React, { useEffect, useRef, useState } from 'react'
import Curre from "../Languages/svgs/HRM.svg"
import DepartmentIcon from './Department/DepartmentIcon'
import Employee from './Employee/EmployeeIcon'
import "./HRM.css"
import JobIcon from './JobTitle/JobIcon'
import OfficesIcon from './Offices/OfficesIcon'
import WorkFormIcon from './WorkForm/WorkFormIcon'
import { useSelector, useDispatch } from "react-redux"
import Departments from './Department/Departments'
import JobTitles from './JobTitle/JobTitle'
import Offices from './Offices/Offices'
import FollowerIcon from "../Languages/svgs/FollowerIcon"
import WorkForm from './WorkForm/WorkForm'
import EmployeePage from './Employee/EmployeePage'
import { BallPulseSync } from 'react-pretty-loading'
import Loading from '../../Loading'
import Search from '../../../../assets/svg/search'
import Translated from '../../../../assets/svg/Translated'
import Sort from '../../../../assets/svg/Sort'
import Filter from '../../../../assets/svg/Filter'
import Application from '../../../../assets/svg/Application'
import SearchBar from '../Elements/SearchBar'
import DisplayView from '../Elements/DisplayView'
import StatusBar from '../Elements/StatusBar'
import { trans, _changeAppLang } from '../../../../Internationalization'
function HRM(props) {
  const [display, setDisplay] = useState(true)
  const [lang_id, setLang_id] = useState(null)
  const departments = useSelector(state => state.department.departments)
  const jobs = useSelector(state => state.jobs.jobs)
  const [lang, setLang] = useState(null)
  const translated_lang = useSelector((state) => state.auth.lang)
  var filterDept = useRef([])
  var filterCount = useRef([])
  var workFilter = useRef([])

  var searchName = useRef("")

  const setFilterDept = (a) => {
    filterDept.current = a
  }
  const setFilterCount = (a) => {
    filterCount.current = a
  }
  const filterCountries = (a, b) => {
    if (b) {
      setFilterCount(filterCount.current.filter((s) => s.id !== a.id))
    }
    else {
      setFilterCount([...filterCount.current, a])
    }
    setTimeout(() => {
      getEmployees(false)
    }, 100);
  }
  const filterDepartments = (a, b) => {
    if (b) {
      setFilterDept(filterDept.current.filter((s) => s.id !== a.id))
    }
    else {
      setFilterDept([...filterDept.current, a])
    }
    setTimeout(() => {
      getEmployees(false)

    }, 100);
  }

  const setsearchName = (w) => {
    searchName.current = w
  }
  const onChange = (e) => {
    setsearchName(e)
    setTimeout(() => {
      getEmployees(false)

    }, 100);
  }
  const emp_loading = useSelector((state) => state.employee.emp_loading)
  const employee = useSelector(state => state.employee.employee)
  const getEmployees = (bool) => {
    let arr = [], brr = []
    filterCount.current.map((a) => arr.push(a.id))
    filterDept.current.map((a) => brr.push(a.id))
    if (!emp_loading) dispatch({
      type: "GET-EMP", bool: bool, payload: {
        filter: {
          full_name: searchName.current,
          department_ids: brr,

        }
      }
    })
  }
  const getFiltered = () => {
    let arr = [], brr = []
    filterCount.current.map((a) => arr.push(a.id))
    filterDept.current.map((a) => brr.push(a.id))
    return employee.filter((a) => {
      if (brr.length === 0 && arr.length === 0 && searchName.current === 0) return true
      let dbool = false, cbool = false, sbool = false
      if (brr.includes(a.department_id)) { dbool = true } else { dbool = false }
      if (a.full_name && a.full_name.includes(searchName.current)) { sbool = true } else { sbool = false }
      if (brr.length === 0) { dbool = true }

      if (searchName.current.length === 0) { sbool = true }
      return (sbool && dbool)

    })
  }
  const getLang = () => {
    if (lang) {
      return (lang)
    }
    else {
      return translated_lang
    }
  }
  const dispatch = useDispatch()
  useEffect(() => {

  }, [languageFollower])
  const makeFollower = (level, value, id) => {
    if (id === 0) {
      setLang_id(null)

    }
    else setLang_id(id)
    if (languageFollower.length >= level) {
      let arr = languageFollower
      arr[level] = { name: value, id: id }
      arr = arr.filter((a, index) => index <= level)
      setLanguageFollower([...arr])
    } else {
      let arr = languageFollower
      setLanguageFollower([...languageFollower, { name: value, id: id }])

    }
  }
  const view = useSelector(state => state.department.view)
  const languages = useSelector(state => state.langs.languages)
  const loading_languages = useSelector(state => state.langs.loading)
  const loading_department = useSelector(state => state.department.loading)
  const loading_jobs = useSelector(state => state.jobs.loading)
  const [languageFollower, setLanguageFollower] = useState([{ name: "Employees", id: 0 }])
  //DEPARTMENT VARIABLES AND FUNCTIONS START
  useEffect(() => {
    if (props.active) {
      if (view === "Departments") {
        dispatch({ type: "GET-JOB" })
        dispatch({ type: "GET-DEPT" })
      } else if (view === "Job Title") {
        dispatch({ type: "GET-JOB" })
      }
      else if (view === "Employees") {


        getEmployees(true)

      }
    }
  }, [view, props.active])
  const open = useSelector(state => state.department.open)
  const setOpen = (e) => {
    dispatch({ type: "OPEN", payload: e })
  }
  const mode = useSelector(state => state.department.mode)
  useEffect(() => {
    setOpenJob(false)
    setOpen(false)

  }, [view])
  const setMode = (e) => {
    dispatch({ type: "MODE", payload: e })
  }
  const setModalData = (e) => {
    dispatch({ type: "MOD-DATA", payload: { name: e.name, icon: e.icon } })
  }
  const setData = (e) => {
    dispatch({ type: "NEW-DEPARTMENT", payload: { ...e } })
  }
  const Update = (e) => {
    dispatch({ type: "request-running-dept-red", paylod: null })
    setMode("update");
    let obj = { translations: [], icon: null, language_code: "" }

    if (e.translations.length !== languages.length) {
      obj.icon = e.icon
      obj.language_code = e.language_code
      languages.map((d) => {
        if (e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase()).length > 0)
          obj.translations = [...obj.translations, e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase())[0]]
        else
          obj.translations = [...obj.translations, { name: "", language_code: d.language_code }]
      })
      setData({ ...e, translations: [...obj.translations] });
    }
    else {
      setData({ ...e });
    }
    setModalData({ name: e.translations.filter((a) => a.language_code === (localStorage.getItem("APP_LANG") ?? "en"))[0].short_name.toUpperCase() + " ", icon: null, code: "Department" });
    setMode("update");
    setOpen(true);
  }
  const Read = (e) => {
    let obj = { translations: [], icon: null, language_code: "" }

    if (e.translations.length !== languages.length) {
      obj.icon = e.icon
      obj.language_code = e.language_code
      languages.map((d) => {
        if (e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase()).length > 0)
          obj.translations = [...obj.translations, e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase())[0]]
        else
          obj.translations = [...obj.translations, { name: "", language_code: d.language_code }]
      })
      setData({ ...e, translations: [...obj.translations] });
    }
    else {
      setData({ ...e });
    }

    setModalData({ name: e.translations.filter((a) => a.language_code === (localStorage.getItem("APP_LANG") ?? "en"))[0].name, icon: e.icon, code: e.translations.filter((a) => a.language_code === (localStorage.getItem("APP_LANG") ?? "en"))[0].short_name })
    setMode("read");
    setOpen(true);
  }
  const Close = () => {
    let obj = { translations: [], icon: null, code: "", fill_photo_path: null, flat_photo_path: null, outline_photo_path: null, png_photo_path: null }
    languages.map((a) => {
      obj = { ...obj, translations: [...obj.translations, { name: "", short_name: "", language_code: a.language_code }] }
    })
    setOpen(false);
    setData(obj);
    setModalData({ name: "", icon: null, code: "" })
  }
  const Add = (e) => {
    setOpen(false);
    dispatch({ type: "request-running-dept-red", paylod: null })

    let obj = { translations: [], fill_photo_path: null, flat_photo_path: null, outline_photo_path: null, png_photo_path: null, code: "" }
    languages.map((a) => {
      obj = { ...obj, translations: [...obj.translations, { name: "", short_name: "", language_code: a.language_code }] }
    })
    if (e) {
      obj = { ...obj, parent_department_id: e.id }
      setModalData({ name: e.translations.filter((a) => a.language_code === localStorage.getItem("APP_LANG"))[0].short_name + trans("departments"), icon: null, code: "Department" });
    }
    else {
      obj = { ...obj, parent_department_id: null }
      setModalData({ name: trans("department"), icon: null, code: "Department" })
    }
    setData(obj);
    setMode("add");
    setTimeout(() => {
      setOpen(true);
    }, 100);

  }
  //DEPARTMENTS END
  //EMPLOYEE START
  const openEmp = useSelector(state => state.employee.open)
  const modeEmp = useSelector(state => state.employee.mode)
  const setOpenEmp = (e) => {
    dispatch({ type: "OPEN-EMP", payload: e })
  }
  const setModeEmp = (e) => {
    dispatch({ type: "MODE-EMP", payload: e })
  }
  const setModalDataEmp = (e) => {
    dispatch({ type: "MOD-DATA-EMP", payload: { name: e.name, icon: e.icon } })
  }
  const setDataEmp = (e) => {
    dispatch({ type: "NEW-EMP", payload: { ...e } })
  }
  const UpdateEmp = (e) => {
    dispatch({ type: "request-running-EMP-red", paylod: null })
    setModeEmp("update");

    if ((e.contact_infos && e.contact_infos.length !== 4) || (!e.contact_infos)) {
      setDataEmp({
        ...e,
        department_id: 7,
        contact_infos: [{
          "country_id": null,
          "phone_number": "",
          "contact_name": "",
          "contact_method": "",
          "contact_time": "",
          "is_main": true,
          "is_close_person": false,
          "is_reference_by": false
        },
        {
          "country_id": null,
          "phone_number": "",
          "contact_name": "",
          "contact_method": "",
          "contact_time": "",
          "is_main": true,
          "is_close_person": false,
          "is_reference_by": false
        },
        {
          "country_id": null,
          "phone_number": "",
          "contact_name": "",
          "contact_method": "",
          "contact_time": "",
          "is_main": true,
          "is_close_person": true,
          "is_reference_by": false
        },
        {
          "country_id": null,
          "phone_number": "",
          "contact_name": "",
          "contact_method": "",
          "contact_time": "",
          "is_main": true,
          "is_close_person": false,
          "is_reference_by": true
        },
        ]
      })
    }
    else {
      setDataEmp(e)

    }
    setModeEmp("update");
    setOpenEmp(true);
  }
  const ReadEmp = (e) => {
    if (e.contact_infos && e.contact_infos.length !== 4) {
      setDataEmp({
        ...e, contact_infos: [{
          "country_id": null,
          "phone_number": "",
          "contact_name": "",
          "contact_method": "",
          "contact_time": "",
          "is_main": true,
          "is_close_person": false,
          "is_reference_by": false
        },
        {
          "country_id": null,
          "phone_number": "",
          "contact_name": "",
          "contact_method": "",
          "contact_time": "",
          "is_main": true,
          "is_close_person": false,
          "is_reference_by": false
        },
        {
          "country_id": null,
          "phone_number": "",
          "contact_name": "",
          "contact_method": "",
          "contact_time": "",
          "is_main": true,
          "is_close_person": true,
          "is_reference_by": false
        },
        {
          "country_id": null,
          "phone_number": "",
          "contact_name": "",
          "contact_method": "",
          "contact_time": "",
          "is_main": true,
          "is_close_person": false,
          "is_reference_by": true
        },
        ]
      })
    }
    else {
      setDataEmp(e)

    }
    setModeEmp("read");
    setOpenEmp(true);
  }
  const CloseEmp = () => {
    let obj = {
      department_id: null,
      department: [],
      dept: "",
      full_name: "",
      avatar: "",
      id_photo_path: "",
      passport_number: "",
      passport_photo_path: "",
      passport_region_id: "",
      passport_region_issued_from: "",
      job_code: "",
      id_no: "",
      id_issued_from: "",
      id_no_region_id: "",
      driving_license_no: "",
      driving_license_photo_path: "",
      driving_license_issued_from: "",
      driving_license_region_id: ""
      , citizen_country_id: ""
      , residence: "",
      city_id: "",
      birthdate: "",
      birth_city_id: "",
      full_address: "",
      contact_infos: [{
        "country_id": null,
        "phone_number": "",
        "contact_name": "",
        "contact_method": "",
        "contact_time": "",
        "is_main": true,
        "is_close_person": false,
        "is_reference_by": false
      },
      {
        "country_id": null,
        "phone_number": "",
        "contact_name": "",
        "contact_method": "",
        "contact_time": "",
        "is_main": true,
        "is_close_person": false,
        "is_reference_by": false
      },
      {
        "country_id": null,
        "phone_number": "",
        "contact_name": "",
        "contact_method": "",
        "contact_time": "",
        "is_main": true,
        "is_close_person": true,
        "is_reference_by": false
      },
      {
        "country_id": null,
        "phone_number": "",
        "contact_name": "",
        "contact_method": "",
        "contact_time": "",
        "is_main": true,
        "is_close_person": false,
        "is_reference_by": true
      },
      ],
      date_of_application: null,
      contract_image: null,
      contract_date: null,
      work_contract: null,
      contract_duration: null,
      work_contract_expire: null,
      license_id: null,
      liscense_name: "",
      work_office_region_id: null,
      ssk_office: null,
      ssk_number: null,
      approval_date: null,
      approval_code: null,
      start_work_date: null,
      office_work: null,
      office_work_name: null,
      uploaded_files: [],
      work_type_id: null,
      job_title_id: null,
      working_hours: null,
      start_working_at: null,
      end_working_at: null,
      break_time_hours: null,
      start_break_at: null,
      end_break_at: null,
      official_salary: null,
      official_salary_currency_id: null,
      salary: null,
      salary_currency_id: null,
      vacation_days: []
    }
    setOpenEmp(false);
    setDataEmp(obj);

  }
  const AddEmp = (e) => {
    setOpenEmp(false);
    dispatch({ type: "request-running-EMP-red", paylod: null })

    let obj = {
      department_id: null,
      department: [],
      dept: "",
      full_name: "",
      avatar: "",
      id_photo_path: "",
      passport_number: "",
      passport_photo_path: "",
      passport_region_id: "",
      passport_region_issued_from: "",
      job_code: "",
      id_no: "",
      id_issued_from: "",
      id_no_region_id: "",
      driving_license_no: "",
      driving_license_photo_path: "",
      driving_license_issued_from: "",
      driving_license_region_id: ""
      , citizen_country_id: ""
      , residence: "",
      city_id: "",
      birthdate: "",
      birth_city_id: "",
      full_address: "",
      contact_infos: [{
        "country_id": null,
        "phone_number": "",
        "contact_name": "",
        "contact_method": "",
        "contact_time": "",
        "is_main": true,
        "is_close_person": false,
        "is_reference_by": false
      },
      {
        "country_id": null,
        "phone_number": "",
        "contact_name": "",
        "contact_method": "",
        "contact_time": "",
        "is_main": true,
        "is_close_person": false,
        "is_reference_by": false
      },
      {
        "country_id": null,
        "phone_number": "",
        "contact_name": "",
        "contact_method": "",
        "contact_time": "",
        "is_main": true,
        "is_close_person": true,
        "is_reference_by": false
      },
      {
        "country_id": null,
        "phone_number": "",
        "contact_name": "",
        "contact_method": "",
        "contact_time": "",
        "is_main": true,
        "is_close_person": false,
        "is_reference_by": true
      },
      ],
      date_of_application: null,
      contract_image: null,
      contract_date: null,
      work_contract: null,
      contract_duration: null,
      work_contract_expire: null,
      license_id: null,
      liscense_name: "",

      contract_date: null,
      work_office_region_id: null,
      ssk_office: null,
      ssk_number: null,
      approval_date: null,
      approval_code: null,
      start_work_date: null,
      office_work: null,
      uploaded_files: [],
      work_type_id: null,
      job_title_id: null,
      working_hours: null,
      start_working_at: null,
      end_working_at: null,
      break_time_hours: null,
      start_break_at: null,
      end_break_at: null,
      official_salary: null,
      official_salary_currency_id: null,
      salary: null,
      salary_currency_id: null,
      vacation_days: [],
    }
    if (e) {
      setDataEmp({ ...obj, department_id: e.id });
    }
    else {
      setDataEmp(obj);
    }
    setModeEmp("add");
    setTimeout(() => {
      setOpenEmp(true);
    }, 100);

  }
  //EMPLOYEE END
  //JOB-TITLE START
  const openJob = useSelector(state => state.jobs.open)
  const modeJob = useSelector(state => state.jobs.mode)
  const setOpenJob = (e) => {
    dispatch({ type: "OPEN-JOB", payload: e })
  }

  const setModeJob = (e) => {
    dispatch({ type: "MODE-JOB", payload: e })
  }
  const setModalDataJob = (e) => {
    dispatch({ type: "MOD-DATA-JOB", payload: { name: e.name, icon: e.icon } })
  }
  const setDataJob = (e) => {
    dispatch({ type: "NEW-JOB", payload: { ...e } })
  }
  const UpdateJob = (e) => {
    dispatch({ type: "request-running-job-red", paylod: null })
    setMode("update");
    let obj = { translations: [], icon: null }

    if (e.translations.length !== languages.length) {
      obj.icon = e.icon

      languages.map((d) => {
        if (e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase()).length > 0)
          obj.translations = [...obj.translations, e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase())[0]]
        else
          obj.translations = [...obj.translations, { name: "", language_code: d.language_code }]
      })
      setDataJob({ ...e, translations: [...obj.translations] });
    }
    else {
      setDataJob({ ...e });
    }

    setModalDataJob({ name: e.translations.filter((a) => a.language_code === (localStorage.getItem("APP_LANG") ?? "en"))[0].name + " ", icon: null, code: "Job Title" });
    setModeJob("update");
    setOpenJob(true);
  }
  const ReadJob = (e) => {
    let obj = { translations: [], icon: null }

    if (e.translations.length !== languages.length) {
      obj.icon = e.icon

      languages.map((d) => {
        if (e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase()).length > 0)
          obj.translations = [...obj.translations, e.translations.filter((asd) => asd.language_code?.toLowerCase() === d.language_code?.toLowerCase())[0]]
        else
          obj.translations = [...obj.translations, { name: "", language_code: d.language_code }]
      })
      setDataJob({ ...e, translations: [...obj.translations] });
    }
    else {
      setDataJob({ ...e });
    }

    setModalDataJob({ name: e.translations.filter((e) => e.language_code === (localStorage.getItem("APP_LANG") ?? "en"))[0].name, icon: e.icon, code: e.code })
    setModeJob("read");
    setOpenJob(true);
  }
  const CloseJob = () => {
    let obj = { translations: [], icon: null, fill_photo_path: null, flat_photo_path: null, outline_photo_path: null, png_photo_path: null }
    languages.map((a) => {
      obj = { ...obj, translations: [...obj.translations, { name: "", language_code: a.language_code }] }
    })
    setOpenJob(false);
    setDataJob(obj);
    setModalDataJob({ name: "", icon: null })

  }
  const AddJob = (e) => {
    setLanguageFollower([{ name: trans("job_title"), id: 0 }]);
    dispatch({ type: "request-running-job-red", paylod: null })

    setOpenJob(false);
    let obj = { translations: [], icon: null, fill_photo_path: null, flat_photo_path: null, outline_photo_path: null, png_photo_path: null }
    languages.map((a) => {
      obj = { ...obj, translations: [...obj.translations, { name: "", language_code: a.language_code }] }
    })
    if (e)
      setModalDataJob({ name: e.translations.filter((a) => a.language_code === (localStorage.getItem("APP_LANG") ?? "en"))[0].name + trans("job_title"), icon: null, code: "Job Title" });
    else
      setModalDataJob({ name: trans("job_title"), icon: null, code: "Job Title" })
    setDataJob(obj);

    setModeJob("add");
    setTimeout(() => {
      setOpenJob(true);
    }, 100);
  }
  //JOB-TITLE END

  const addItem = (e) => {
    switch (view) {
      case "Employees":
        AddEmp()
      case "Offices":
        return (<></>)
      case "Work Form":
        return (<></>)
      case "Departments":
        Add(e)
      case "Job Title": {
        if (view === "Job Title")
          AddJob(e)
      }
        break;

      default:
        break;
    }
  }
  const showView = () => {

    switch (view) {
      case "Employees":
        return (<EmployeePage forCustomer={props.forCustomer} workFilter={workFilter} arr={filterCount.current} emp={() => getFiltered()} setLanguageFollowers={() => { setLanguageFollower([{ name: trans("employees"), id: 0 }]); setLang_id(null) }} viewDisp={display} lang={getLang()} active={props.active} view={view} setModalData={(e) => setModalDataEmp(e)} setLang_id={(e) => setLang_id(e)} lang_id={lang_id} open={openEmp} setOpen={(e) => setOpenEmp(e)} Add={(e) => AddEmp(e)} Close={(e) => CloseEmp(e)} Update={(e) => UpdateEmp(e)} Read={(e) => ReadEmp(e)} makeFollower={(e, n, id) => makeFollower(e, n, id)} setLanguageFollower={(level, val) => setLanguageFollower(level, val)} languageFollower={languageFollower} />)
      case "Offices":
        return (<>{!props.forCustomer&&<Offices />}</>)
      case "Work Form":
        return (!props.forCustomer&&<WorkForm />)
      case "Departments":
        return (!props.forCustomer&&<Departments lang={getLang()} active={props.active} view={view} setModalData={(e) => setModalData(e)} setLang_id={(e) => setLang_id(e)} lang_id={lang_id} open={open} setOpen={(e) => setOpen(e)} Add={(e) => Add(e)} Close={(e) => Close(e)} Update={(e) => Update(e)} Read={(e) => Read(e)} makeFollower={(e, n, id) => makeFollower(e, n, id)} setLanguageFollower={(level, val) => setLanguageFollower(level, val)} languageFollower={languageFollower} />)
      case "Job Title":
        return (!props.forCustomer&&<JobTitles lang={getLang()} active={props.active} view={view} setModalData={(e) => setModalDataJob(e)} setLang_id={(e) => setLang_id(e)} lang_id={lang_id} open={openJob} setOpen={(e) => setOpenJob(e)} Add={(e) => AddJob(e)} Close={(e) => CloseJob(e)} Update={(e) => UpdateJob(e)} Read={(e) => ReadJob(e)} makeFollower={(e, n, id) => makeFollower(e, n, id)} setLanguageFollower={(level, val) => setLanguageFollower(level, val)} languageFollower={languageFollower} />)
      default:
        break;

    }
  }
  const loading = () => {
    return (loading_department || loading_jobs || loading_department)
  }
  return (
    <div className={`languages-page ${props.active && "Active-page"}`}>

      <div className='lang-top'>
        <div className='lang-info'>
          <div className='lang-icon header-icon'>
            <img alt="HRM" src={Curre} />
          </div>
          <div className='lang-name' onClick={() => { }}>{trans("hrm")}</div>
          <div className='follower-list'>
            {languageFollower.map((fo, index) => (
              <div className='follower' onClick={() => makeFollower(index, fo.name, fo.id)}>
                <span><FollowerIcon expand={false} /></span>
                <span>{fo.name}</span>
              </div>

            ))}
          </div>
        </div>
        <div className='top-options'>
          <div className='top-option'>
            <span><Search /></span>
          </div>
          <div className='top-option langs'>
            <span><Translated /></span>
            <div className='lang-items'>
              {languages.map(langs => <div onClick={() => {
                _changeAppLang(langs.language_code)
                dispatch({ type: "TRANSLATE", payload: langs.language_code })
              }} onMouseLeave={() => setLang(null)} onMouseEnter={() => setLang(langs.language_code)} className={'lang-item' + ` ${translated_lang?.toLowerCase() === langs.language_code?.toLowerCase() && "selected-lang"}`}>{langs.language_code.toUpperCase()}</div>)}
            </div>
          </div>
          <div className='top-option'>
            <span><Sort /></span>
          </div>
          <div className='top-option'>
            <span><Filter /></span>
          </div>
          <div className='top-option'>
            <span><Application /></span>
          </div>
          <svg className={'lines'} xmlns="http://www.w3.org/2000/svg" width="0.5" height="25.5" viewBox="0 0 0.5 25.5">
            <line id="Line_992" data-name="Line 992" y1="25" transform="translate(0.25 0.25)" fill="none" stroke="#8e8e8e" strokeLinecap="round" strokeWidth="0.5" />
          </svg>
        </div>
        <div className={`lang-add currency-add`}>
          {!props.forCustomer&&<>
            <OfficesIcon active={view === "Offices"} change={() => { dispatch({ type: "HRM-VIEW", payload: "Offices" }); setLanguageFollower([{ name: trans("offices"), id: 0 }]); setLang_id(null) }} />
          <WorkFormIcon active={view === "Work Form"} change={() => { dispatch({ type: "HRM-VIEW", payload: "Work Form" }); setLanguageFollower([{ name: trans("work_form"), id: 0 }]); setLang_id(null) }} />
          <JobIcon active={view === "Job Title"} change={() => { dispatch({ type: "HRM-VIEW", payload: "Job Title" }); setLanguageFollower([{ name: trans("job_title"), id: 0 }]); setLang_id(null) }} />
          <DepartmentIcon active={view === "Departments"} change={() => { dispatch({ type: "HRM-VIEW", payload: "Departments" }); setLanguageFollower([{ name: trans("departments"), id: 0 }]); setLang_id(null) }} />
          </>}
          <Employee active={view === "Employees"} change={() => { dispatch({ type: "HRM-VIEW", payload: "Employees" }); setLanguageFollower([{ name: trans("employees"), id: 0 }]); setLang_id(null) }} />

          <svg style={{ marginRight: "20px", width: "1px" }} xmlns="http://www.w3.org/2000/svg" width="0.5" height="25.5" viewBox="0 0 0.5 25.5">
            <line id="Line_992" data-name="Line 992" y1="25" transform="translate(0.25 0.25)" fill="none" stroke="#8e8e8e" strokeLinecap="round" strokeWidth="0.5" />
          </svg>

          <svg className={(!languages.length > 0 || languageFollower.length !== 1) && 'disab'} onClick={() => { if (languages.length > 0 && languageFollower.length === 1) { addItem(); } setLang_id(null) }} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">

            <g id="Mask_Group_16" data-name="Mask Group 16" transform="translate(-324 -333)" >
              <path id="add-3" d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z" transform="translate(324 333)" fill="#404040" />
            </g>
          </svg>

        </div>
      </div>
      {view === "Employees" && <>
        <SearchBar searchName={searchName.current} setsearchName={(e) => onChange(e.target.value)} filterDept={filterDept.current} filterCount={filterCount.current} filterCountries={(a, b) => filterCountries(a, b)} filterDepartments={(a, b) => filterDepartments(a, b)} />
        <StatusBar workFilter={workFilter} />

        <DisplayView countries={filterCount.current} dept={filterDept.current} view={display} changeView={() => setDisplay(!display)} />
      </>}
      {showView()}
    </div>
  )
}

export default HRM