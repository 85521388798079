import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { pusher } from '../../../../../redux/chat/constants'
import EmployeeItem from './EmployeeItem'
import EmployeeModal from './EmployeeModal'
import EmployItem from './EmployItem'
function Employee(props) {

  const dispatch = useDispatch()
  const ActiveAdd =(e)=>{
    dispatch({type:"Active-add",payload:e})
  }
  const {lang_id, setLang_id}= props
  const requested_data = useSelector((state)=>state.employee.request_data)

  const employee = props.emp()
  const emp_loading = useSelector(state => state.employee.emp_loading)
  const depts = useSelector(state => state.employee.depts)
  const languages = useSelector(state => state.langs.languages)
  const {languageFollower,makeFollower, setLanguageFollower} = props
  const data = useSelector(state => state.employee.newDepartment)
  const loading = useSelector((state)=> state.employee.loading)
  const offline = useSelector((state)=>state.auth.offline)
  const mode = useSelector(state => state.employee.mode)
  const Active_Ids = useSelector(state => state.employee.Active_Ids)
 useEffect(()=>{

 },[lang_id,props.open])
 useEffect(()=>{
 
 },[])
 useEffect(()=>{
  props.setLanguageFollowers()
 },[props.viewDisp])
 useEffect(()=>{

  dispatch({type:"GET-DEPT"});
  dispatch({type:"GET-JOBS"});
  dispatch({type:"GET-REG"});


 },[])
 const open = useSelector((state)=>state.employee.open)

 const getFilteredEmps=(d)=>{
  if(props.workFilter.current.length===0){
    return d
  }
  else return d.filter((de)=>props.workFilter.current.includes(de.status))
 }
  return (
    <>

{ (lang_id===0||lang_id===null&&open)&&<EmployeeModal  forCustomer={props.forCustomer} Active_Ids={Active_Ids} ActiveAdd={()=>props.ActiveAdd(data.id)} languageFollower={props.languageFollower} setExpand={(e)=>{}} data={data} {...props}/>}
    {!props.viewDisp?<div className='lang-body'>
      {
        depts.map((dept)=>(
          <EmployeeItem
          lang={props.lang}
          ActiveAdd={(e)=>ActiveAdd(e)}
          open={props.open}
          Close={()=>props.Close()}
          Add={(e)=>props.Add(e)}
          Update={(e)=>props.Update(e)}
          Read={(e)=>props.Read(e)}
          setModalData={(e)=>props.setModalData(e)}
          setOpen={(e)=>props.setOpen(e)}
           child={dept}
           Active_Ids={Active_Ids}
           languageFollower={props.languageFollower}
           level={1}
           makeFollower={(level,value,id)=>makeFollower(level,value,id)}
           lang_id={lang_id}
           setLang_id={(e)=>setLang_id(e)}
           />
        ))
      }
      {/* {((props.open&&(props.lang_id===null||props.lang_id===0)&&mode==="add")||(requested_data&&requested_data.parent_department_id===null&&mode==="add"))   &&
       <EmployeeItem
          open={props.open}
          Close={()=>props.Close()}
          lang={props.lang}
          Add={(e)=>props.Add(e)}
          Update={(e)=>props.Update(e)}
          new={"new"}
          Read={(e)=>props.Read(e)}
          setOpen={(e)=>props.setOpen(e)}
           child={requested_data?requested_data: data}
           languageFollower={props.languageFollower}
           level={1}
           makeFollower={(level,value,id)=>makeFollower(level,value,id)}
           lang_id={lang_id}
           setLang_id={(e)=>setLang_id(e)}
           />} */}
    </div>:
      <div className='view-body'>
      {employee.length>0&&getFilteredEmps(employee).map((a)=>(
        <EmployItem
        forCustomer={props.forCustomer}
        arr={props.arr.map((d)=> {return d.id})}
         child={a}
         lang={props.lang}
         ActiveAdd={(e)=>ActiveAdd(e)}
         open={props.open}
         Close={()=>props.Close()}
         Add={(e)=>props.Add(e)}
         Update={(e)=>props.Update(e)}
         Read={(e)=>props.Read(e)}
         setModalData={(e)=>props.setModalData(e)}
         setOpen={(e)=>props.setOpen(e)}
       
          Active_Ids={Active_Ids}
          languageFollower={props.languageFollower}
          level={1}
          makeFollower={(level,value,id)=>makeFollower(level,value,id)}
          lang_id={lang_id}
          setLang_id={(e)=>setLang_id(e)}
        
        />
      ))}
    </div>
    }
    </>
  )
}

export default Employee