import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DepartmentItem from './DepartmentItem'
import DepartmentModal from './DepartmentModal'
function Departments(props) {
  const dispatch = useDispatch()
  const ActiveAdd =(e)=>{
    dispatch({type:"Active-add",payload:e})
  }
  const {lang_id, setLang_id}= props
  const requested_data = useSelector((state)=>state.department.request_data)

  const departments = useSelector(state => state.department.departments)
  const languages = useSelector(state => state.langs.languages)
  const {languageFollower,makeFollower, setLanguageFollower} = props
  const data = useSelector(state => state.department.newDepartment)
  const loading = useSelector((state)=> state.department.loading)

  const mode = useSelector(state => state.department.mode)
  const Active_Ids = useSelector(state => state.department.Active_Ids)
 useEffect(()=>{
 
 },[lang_id])
 useEffect(()=>{


 },[])
  return (
    <>

{ lang_id===0||lang_id===null&&props.open&&<DepartmentModal Active_Ids={Active_Ids} ActiveAdd={()=>props.ActiveAdd(data.id)} languageFollower={props.languageFollower} setExpand={(e)=>{}} data={data} {...props}/>}
    <div className='lang-body'>
      {
        departments.map((dept)=>(
          dept.translations&&dept.translations.length>0&&
          <DepartmentItem
          lang={props.lang}
          ActiveAdd={(e)=>ActiveAdd(e)}
          open={props.open}
          Close={()=>props.Close()}
          Add={(e)=>props.Add(e)}
          Update={(e)=>props.Update(e)}
          Read={(e)=>props.Read(e)}
          setModalData={(e)=>props.setModalData(e)}
          setOpen={(e)=>props.setOpen(e)}
           child={dept}
           Active_Ids={Active_Ids}
           languageFollower={props.languageFollower}
           level={1}
           makeFollower={(level,value,id)=>makeFollower(level,value,id)}
           lang_id={lang_id}
           setLang_id={(e)=>setLang_id(e)}
           />
        ))
      }
      {((props.open&&(props.lang_id===null||props.lang_id===0)&&mode==="add")||(requested_data&&requested_data.parent_department_id===null&&mode==="add"))   && <DepartmentItem
          open={props.open}
          Close={()=>props.Close()}
          lang={props.lang}
          Add={(e)=>props.Add(e)}
          Update={(e)=>props.Update(e)}
          new={"new"}
          Read={(e)=>props.Read(e)}
          setOpen={(e)=>props.setOpen(e)}
           child={requested_data?requested_data: data}
           languageFollower={props.languageFollower}
           level={1}
           makeFollower={(level,value,id)=>makeFollower(level,value,id)}
           lang_id={lang_id}
           setLang_id={(e)=>setLang_id(e)}
           />}
    </div>
    </>
  )
}

export default Departments