import React, { useState } from 'react'
import Langs from "./svgs/Langs.svg"
import LangItem from './LangItem'
// ATTENTION!! ADDING CSS FILE NEXT LINE
import "./fixes.css"
import FollowerIcon from './svgs/FollowerIcon'
import LanguageModal from './Modals/LanguageModal'
import DetailsModal from './Modals/DetailsModal'
import { useSelector } from 'react-redux'
import { langsdata } from '../../MainPage/constants'
function Languages(props) {
    const Language = useSelector((state)=>state.languages.SelectedLanguage)
    const allLanguages = useSelector((state)=>state.languages.allLanguages)
    const [open, setOpen] = useState(false)
    const [detailsModal,setDetailModal] = useState(false);
    const [languageFollower, setLanguageFollower] = useState([])
    const makeFollower = (level,value) =>{
        if(languageFollower.length>=level){
            let arr = languageFollower
            arr[level] = value
            arr = arr.filter((a,index) => index <= level)
            setLanguageFollower([...arr])
        }else{
            let arr = languageFollower
            setLanguageFollower([...languageFollower,value])
           
        }
    }
    const [img ,setImg] = useState(false)
    const [imgOut ,setImgOut] = useState(false)
    const [lang, setLang] = useState({name:"",icon:"",childs:[]})
    const [langcode, setLangCode] = useState("")
    const updateCode = (e)=>{
       
        if(e.target.value.length<=2)
        setLang({...lang,icon:e.target.value})
    }
    const [lang_id, setLang_id]= useState(null)
  return (
    <div className={'languages-page'+" "+`${props.active&&"Active-page"}`}>
        {/* ATTENTION!! ADDING PROPS TO THE NEXT LINE languageFollower */}
        <LanguageModal languageFollower={languageFollower} allLanguages={allLanguages} lang={lang} setLang={(e)=>setLang(e)} open={open} setOpen={(e)=>setOpen(e)} langcode={lang.icon} setLangCode={(e)=>{updateCode(e);}}/>
       
        <div className='lang-top'>
            <div className='lang-info'>
        {/* !!!!ATTENTION!!! JUST ADD header-icon TO THE NEXT ELMENT CLASSNAME */}
                <div className='lang-icon header-icon'>
                    <img alt="languages" src={Langs}/>
                </div>
                <div className='lang-name' onClick={()=>{setLanguageFollower([]); setLang_id(null)}}>Languages</div>
                <div className='follower-list'>
                {languageFollower.map((fo) =>(
                    <div className='follower'>
                        <span><FollowerIcon expand={false}/></span>
                        <span>{fo}</span>
                    </div>
                ))}
                </div>

            </div>
            <div className='lang-items'>
                {langsdata.map(lang => <div className='lang-item'>{lang.icon.toUpperCase()}</div>)}
            </div>
          {languageFollower.length===0?
            <div className={`lang-add ${open&&"open"}`} onClick={()=>setOpen(!open)}>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
                <defs>
                    <clipPath id="clip-path">
                    <rect id="Rectangle_4527" data-name="Rectangle 4527" width="15" height="15" transform="translate(324 333)" fill="none"/>
                    </clipPath>
                </defs>
                <g id="Mask_Group_16" data-name="Mask Group 16" transform="translate(-324 -333)" clip-path="url(#clip-path)">
                    <path id="add-3" d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z" transform="translate(324 333)" fill="#404040"/>
                </g>
                </svg>

            </div>:
                        <div className={`lang-add hidden-plus`}>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
                            <defs>
                                <clipPath id="clip-path">
                                <rect id="Rectangle_4527" data-name="Rectangle 4527" width="15" height="15" transform="translate(324 333)" fill="none"/>
                                </clipPath>
                            </defs>
                            <g id="Mask_Group_16" data-name="Mask Group 16" transform="translate(-324 -333)" clip-path="url(#clip-path)">
                                <path id="add-3" d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z" transform="translate(324 333)" fill="#404040"/>
                            </g>
                            </svg>
            
                        </div>}
        </div>
        <div className='lang-body'>
            {langsdata.map((child)=>(
            <LangItem detailsModal={detailsModal}
             setDetailModal={(e)=>setDetailModal(e)}
              open={open} setOpen={()=>setOpen(true)}
               lang={lang} setLang={(e)=>setLang(e)}
                defaultVal={()=>setLang({name:"",icon:"", childs:[]})}
                update={(e)=>{setLang(e); setOpen(true);}}
            languageFollower={languageFollower}
            updateCode={(e)=>updateCode(e)}
             makeFollower={(level,value)=>makeFollower(level,value)}
              level={0} accent={"lang"} lang_id={lang_id}
               setLang_id={(e)=>setLang_id(e)} child={child}/>
            ))}
              {/* ATTENTION!! ADDING PROPS TO THE NEXT LINE languageFollower */}
        {open &&languageFollower.length===0&&!Language.id&& <LangItem  languageFollower={languageFollower} new={"new"} child={Language} />}
        </div>
    </div>
  )
}

export default Languages