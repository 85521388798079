import React, { useEffect } from "react";
import Icon from "../Icon";
import ProfileNo from "../../../WhatsApp/assets/images/profileNo.png";
import grop from "../../../WhatsApp/assets/images/group.svg";
import cam from "../../../WhatsApp/assets/images/cam.svg";
import voc from "../../../WhatsApp/assets/images/voc.svg";
import wai from "../../../WhatsApp/assets/images/wai.svg";
import vid from "../../../WhatsApp/assets/images/vid.svg";
import con from "../../../WhatsApp/assets/images/cons.svg";
import file from "../../../WhatsApp/assets/images/fl.svg";
import parse from 'html-react-parser';
import date from "date-and-time";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import store from "../../../../../redux/store";
import axios from "axios";
const Contact = ({ onClick, contact, newChat }) => {
  const sub_contacts = useSelector((state) => state.whatsapp.activeChat?.whatsapp_contact?.sub_contacts)||[]

  const dispatch = useDispatch();
  const getLastMessage = () => {
    var maxValue = Number.MIN_VALUE;
    var lst 
   
    if(contact&&contact?.messages?.length>0)
     lst = contact.messages.filter((s)=>((s.whatsapp_chat_id===contact?.id)||(s.cid===contact.id)||(s.chat_id===contact?.id)))[0];
    for (var i = 0; i < contact?.messages?.length; i++) {
      if (new Date(contact?.messages[i].sent_at).getTime() > maxValue) { 
        maxValue = new Date(contact?.messages[i].sent_at).getTime();
        lst = contact?.messages[i];
      }
    }
    return lst;
  };
  let now = new Date();
  const showDate = (d) => {
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    let day = new Date(d)
    day = days[day.getDay()]
    let da = new Date(d)
    da = `${da.getFullYear()}-${(da.getMonth() + 1) > 9 ? (da.getMonth() + 1).toString() : ("0" + (da.getMonth() + 1).toString())}-${(da.getDate()) > 9 ? da.getDate() : "0" + parseInt(da.getDate()).toString()}`
    if (da === nowString)
      return date.format(new Date(d), "HH:mm");
    else if ((new Date(nowString) - new Date(d)) <= 86400000) {
      return ("Yesterday")
    }
    else if ((new Date(nowString) - new Date(d)) < (86400000 * 6))
      return (day)
    else
      return (date.format(new Date(d), "MMM DD"))
  }
  let nowString = `${now.getFullYear()}-${(now.getMonth() + 1) > 9 ? (now.getMonth() + 1).toString() : ("0" + (now.getMonth() + 1).toString())}-${(now.getDate()) > 9 ? now.getDate() : "0" + parseInt(now.getDate()).toString()}`
  const contacts = useSelector((state) => state.whatsapp.contacts)

  function fun(arr) { }
  let paret = useRef();
  const getText = (sds) => {
    let str = sds;
    let mentions=[];
    if (str?.includes("@")) {
      const regex = /@\d+/g;
      let ser = str.match(regex)
      mentions=ser
     
      // console.log(ser)
      if (ser&&ser.length>0) {

        ser.map((s) =>{
           str = str.replace(s,`<span class='blue_tag'> @${(contacts.filter((con) => ("@" + con.number) === s)[0]?.name||contacts.filter((con) => ("@" + con.number) === s)[0]?.pushname||sub_contacts.filter((con) => ("@" + con.number) === s)[0]?.name||sub_contacts.filter((con) => ("@" + con.number) === s)[0]?.pushname)||s}</span>`)
          })
        
      }
    }

  if(str!==null){
    return parse(str?.toString())

  }
  else{
    return sds
  }
  
  }
  const getMessage = () => {
    if (getLastMessage()?.type === "image" || getLastMessage()?.type === "sticker") {
      return (
        <>
          <svg data-src={cam}></svg> Image
        </>
      );
    } else if (getLastMessage()?.type === "ptt" || getLastMessage()?.type === "audio") {
      return (
        <>
          <svg data-src={voc}></svg> Voice
        </>
      );
    } else if (getLastMessage()?.type === "chat") {
      return <> {getText(getLastMessage()?.body)}</>;
    } else if (getLastMessage()?.type === "document") {
      return (
        <>
          <svg data-src={file}></svg>File
        </>
      );
    }
    else if (getLastMessage()?.type === "vcard") {
      return (<>
        <svg data-src={con}></svg> Contact
      </>)
    }
    else if (getLastMessage()?.type === "video") {
      return (<>
        <svg style={{ marginRight: "4px" }} data-src={vid}></svg> Video
      </>)
    }
  };
  useEffect(() => {
    // 
  }, [contact]);
  const getMessageTime = () => {
    if (getLastMessage()?.sent_at) {
      let d = new Date(getLastMessage()?.sent_at);
      d = d.getTime() + (3 * 60 * 60 * 1000)
      d = new Date(d)
      return showDate(d)
    }
  };
  const detectWAContact = async () => {
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    const base_user =
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_user
        : process.env.REACT_APP_USER_BASE_URL;
    let AxiosInstance = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    if (newChat) {
      if (contacts.filter((s) => "+" + s.number === contact.number).length > 0) {
        onClick()
      }
      else {
        let a
        const id = toast.loading("Please wait...")
        try {
          a = await AxiosInstance.get(`/api/v1/whatsapp/check_phone_number/${contact.number}`);
          // console.log(a.data)
        }catch(e){
          toast.update(id, { render: "this number dosen't have active whatsapp account", type: "error", isLoading: false,autoClose:true });
        }

        if (a?.data.data.status === "valid") {
          onClick()
          toast.dismiss(id)
        }
        else {
          toast.update(id, { render: "this number dosen't have active whatsapp account", type: "error", isLoading: false, autoClose: true, closeOnClick: true });

        }
      }
    }
    else {
      onClick()
    }
  }
  return (
    <div
      key={contact?.id}
      className="sidebar-contact"
      onClick={() => detectWAContact()}
    >
      <div className="sidebar-contact__avatar-wrapper">
        {contact?.isGroup && contact.isGroup === 1 ? (
          <svg className="avatar" data-src={grop}></svg>
        ) : (
          <img
            src={contact?.whatsapp_contact?.profile_photo ? contact?.whatsapp_contact.profile_photo : ProfileNo}
            alt={""}
            className="avatar"
          />
        )}
      </div>
      <div className="sidebar-contact__content">
        <div className="sidebar-contact__top-content">
          <h2 className="sidebar-contact__name">
            {" "}
            {contacts.filter((s) => s.id === contact.whatsapp_contact?.id)[0]?.name && contacts.filter((s) => s.id === contact.whatsapp_contact?.id)[0]?.name?.length > 0
              ? contacts.filter((s) => s.id === contact.whatsapp_contact?.id)[0]?.name
              : ((contacts.filter((s) => s.id === contact.whatsapp_contact?.id)[0]?.pushname)||(contact?.name ? contact.name : ("+" + contact?.id_from_whatsapp?.split("@")[0])))}{" "}
          </h2>
          <span className="sidebar-contact__time">
            {/*{formatTime(lastMessage?.time)}*/}
          </span>
        </div>
        <div className="sidebar-contact__bottom-content">
          <p className="sidebar-contact__message-wrapper">
            {(getLastMessage()?.ack && getLastMessage()?.fromMe) ? (
              <>
                {getLastMessage().mid ?
                  <><svg className={`sidebar-contact__message-icon`} data-src={wai}></svg></> :
                  <Icon
                    id={
                      getLastMessage()?.ack === "server"
                        ? "singleTick"
                        : "doubleTick"
                    }
                    aria-label={getLastMessage()?.ack}
                    className={`sidebar-contact__message-icon ${getLastMessage()?.ack === "read"
                      ? "sidebar-contact__message-icon--blue"
                      : ""
                      }`}
                  />}
              </>

            ) : null}
            <span
              className={`sidebar-contact__message ${contact?.unread ? "sidebar-contact__message--unread" : ""
                }`}
            >
              {contact?.typing ? <i> typing...</i> : getMessage()}
            </span>
          </p>
          <div className="sidebar-contact__icons">
            {contact?.pinned === 1 && (
              <Icon id="pinned" className="sidebar-contact__icon" />
            )}
            {contact?.unread > 0 && (
              <span className="sidebar-contact__unread">{contact?.unread}</span>
            )}
            {
              <span className="Dvjym">
                {getLastMessage() &&
                  getLastMessage()?.sent_at &&
                  getMessageTime()}
              </span>
            }
            <button aria-label="sidebar-contact__btn">
              <Icon
                id="downArrow"
                className="sidebar-contact__icon sidebar-contact__icon--dropdown"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
