import React, { useEffect, useRef, useState } from "react";
import sen from "../../../assets/svg/sen.svg";
import red from "../../../assets/svg/red.svg";
import rec from "../../../assets/svg/rec.svg";
import wave from "../../../assets/svg/wave.svg";
import play from "../../../assets/svg/play.svg";
import pause from "../../../assets/svg/pause.svg";
import record from "../../../assets/svg/record.svg";
import recordme from "../../../assets/svg/recordme.svg";
import misscall from "../../../assets/svg/misscall.svg";
import { useDispatch, useSelector } from "react-redux";
import avat from "../../../assets/svg/avat.png";
import { FILE_SERVER } from "../../../constants";
import Spinner from "../Spinner";
import OptionsMenu from "../WhatsApp/components/OptionsMenu";
function ChatMessage(props) {
    const [opens, setOpen] = React.useState(false);
    const getTimeInlocalTimeZone = (d) => {
     
      let m=new Date();
      if(d.getHours()===m.getHours()){
        return d
      }
      else{
      let a = new Date(d.getTime() + 3 * 60 * 60 * 1000);
  
      return a;}
    };
  const dispatch=useDispatch()
  const getStatues=()=>{
    let a=props.message.message_status.filter((a)=>a.user_id!==(localStorage.getItem("user")&&JSON.parse(localStorage.getItem("user")).id))
    if(a.length>0){
      return(a[0])

    }
    else{
      return({is_watched:false,is_received:0})
    }
  }
  const getMessageStatus=()=>{
    if(props.message.message_status.filter((a)=>a.user_id!==(localStorage.getItem("user")&&JSON.parse(localStorage.getItem("user")).id)).length>0&&props.message.message_status.filter((a)=>a.user_id!==(localStorage.getItem("user")&&JSON.parse(localStorage.getItem("user")).id))[0]?.is_watched===true){
    return(
      <>
                  {<div className='sent-date'>
                      {<><svg data-src={red}></svg>{getMessageTime(props.message.message_status.filter((a)=>a.user_id!==(localStorage.getItem("user")&&JSON.parse(localStorage.getItem("user")).id))[0].watched_at)}</>}</div>}
                    
                  
      </>
    )}
    else if(props.message.message_status.filter((a)=>a.user_id!==(localStorage.getItem("user")&&JSON.parse(localStorage.getItem("user")).id)).length>0&&props.message.message_status.filter((a)=>a.user_id!==(localStorage.getItem("user")&&JSON.parse(localStorage.getItem("user")).id))[0].is_received===1){
      return(
        <>
                    {<div className='sent-date'>
                        {<><svg data-src={rec}></svg>{getMessageTime(props.message.message_status.filter((a)=>a.user_id!==(localStorage.getItem("user")&&JSON.parse(localStorage.getItem("user")).id))[0].received_at)}</>}</div>}
                      
                    
        </>
      )
    }
    else {
      return(
        <>
        {<div className='sent-date'>
            {<><svg data-src={sen}></svg>{getMessageTime(props.message.created_at)}</>}</div>}
          
        
</>
      )
    }
      }
  const getMessageTime = (ti) => {
    let d
    if(ti){ 
    d=getTimeInlocalTimeZone(new Date(ti));
    return `${d.getHours()>9?d.getHours():"0"+d.getHours()}:${d.getMinutes()>9?d.getMinutes():"0"+d.getMinutes()}`;
 }
else{
  d=new Date();
    return `${d.getHours()>9?d.getHours():"0"+d.getHours()}:${d.getMinutes()>9?d.getMinutes():"0"+d.getMinutes()}`;
}
};
  useEffect(() => {
    if (refs.current && refs.current.ended) {
      setPlay(false);
      refs.current.currentTime = 0;
    }
  }, [refs]);
  const showTime = (m) => {
    let minutes = Math.round(m / 60);
    let seconds = Math.round(m - minutes * 60);
    return `${minutes > 9 ? minutes : "0" + minutes}:${
      seconds > 9 ? seconds : "0" + seconds
    }`;
  };
  const showBord = (type, height) => {
    let arr = [];
    let num = height / 7;
    if (type === "middle-chat") {
      num = (height + 10) / 7;
    }
    for (var i = 0; i < num; i++) {
      arr.push("");
    }
    return arr
}

      const refs=useRef()
      const refmessage=useRef()
    const activeChat=useSelector((state)=>state.chat.activeChat)
    const [playing,setPlay]=useState(false)
    const showMessage=()=>{
        if(parseInt(props.message.sender_user_id)===parseInt(JSON.parse(localStorage.getItem("user")).id)){
            if(props.message.message_type.name==="ImageMessage"){
                return( 
                  <div onMouseLeave={()=>setOpen(false)} className={"message-hold"+" "+`${opens&&"ac"}`}>
   <div onClick={()=>setOpen(true)} ref={refmessage} className={'message-body message-img-body '+props.type+' '+` ${opens&&"ac"}`}>
                                  

                                  <div className='border-element'>
                                    {refmessage.current&&showBord(props.type,refmessage.current.clientHeight).map((ad)=>(
                                        <div className='border-child'></div>
                                    ))}
                                </div>
                                    {props.type==="first-chat"&&<div className='bordse'></div>}
            
                                  {(props.type==="first-chat"||props.type==="lonely")&&
                                <div className='absolute-avatar'>
                                    {!(activeChat.channel_members.filter((a)=>parseInt(a.user_id)===parseInt(JSON.parse(localStorage.getItem("user")).id))[0]&&activeChat.channel_members.filter((a)=>parseInt(a.user_id)===parseInt(JSON.parse(localStorage.getItem("user")).id))[0].user&&activeChat.channel_members.filter((a)=>parseInt(a.user_id)===parseInt(JSON.parse(localStorage.getItem("user")).id))[0].user.avatar)?<img className='abs-avva' src={avat}/>:<img className='abs-avva' src={FILE_SERVER+(activeChat&&activeChat.channel_members&&activeChat.channel_members.filter((a)=>parseInt(a.user_id)===parseInt(JSON.parse(localStorage.getItem("user")).id))[0].user.avatar)}/>}
                                    
                                </div>}
                            <img className='message-img' src={props.message.message_content?(
                                props.message.type?(
                                props.message.message_content[0].file_path):(
                                FILE_SERVER+props.message.message_content[0].file_path)):"null"}/>
                            <div className='message-date hovers'>
                            {<div className='sent-date'>{<><svg data-src={sen}></svg>{getMessageTime(props.message.created_at)}</>}</div>}
                                   {getStatues().is_received===1&& <div className='recieve-date'><svg data-src={rec}></svg>{getMessageTime(props.message.message_status.filter((a)=>a.user_id!==(localStorage.getItem("user")&&JSON.parse(localStorage.getItem("user")).id))[0].received_at)}</div>}
                                    {getStatues()?.is_watched===true&&<div className='read-date'><svg data-src={red}></svg>{getMessageTime(props.message.message_status.filter((a)=>a.user_id!==(localStorage.getItem("user")&&JSON.parse(localStorage.getItem("user")).id))[0].watched_at)}</div>}
                                </div>
                              <div className="message-date">
                                {getMessageStatus()}
                                </div>  
                        </div>
                         <OptionsMenu forward={()=>dispatch({type:"FORWARD-MESSAGEs",payload:props.message})} click={()=>{;}}/>
                  </div>
             )
            }
            else if(props.message.message_type.name==="vid"){}
            if(props.message.message_type.name==="VoiceMessage"){
                return(
           <div onMouseLeave={()=>setOpen(false)} className={"message-hold"+" "+`${opens&&"ac"}`}>
                                        <OptionsMenu forward={()=>dispatch({type:"FORWARD-MESSAGEs",payload:props.message})}  click={()=>{;}}/>  
             { props.message.message_content&&props.message.message_content[0].file_path&&props.message.message_content[0].file_path!=="false"&&
               <div onClick={()=>setOpen(true)} ref={refmessage} className={'message-body audio-body '+props.type}>
                     <div className='border-element'>
                        {refmessage.current&&showBord(props.type,refmessage.current.clientHeight).map((ad)=>(
                            <div className='border-child'></div>
                        ))}
                    </div>
                    <audio crossOrigin = 'anonymous' onEnded={()=>{setPlay(false); refs.current.currentTime=0;}} controls={false} ref={refs} src={props.message.type?(props.message.message_content&&props.message.message_content[0].file_path):(FILE_SERVER+props.message.message_content[0].file_path)}>
                        <source src={props.message.type?(props.message.message_content[0].file_path):(FILE_SERVER+props.message.message_content&&props.message.message_content[0].file_path)}/>
                    </audio>
                      {(props.type==="first-chat"||props.type==="lonely")&&
                    <div className='absolute-avatar'>
                       {!(activeChat.channel_members.filter((a)=>parseInt(a.user_id)===parseInt(JSON.parse(localStorage.getItem("user")).id))[0]&&activeChat.channel_members.filter((a)=>parseInt(a.user_id)===parseInt(JSON.parse(localStorage.getItem("user")).id))[0].user&&activeChat.channel_members.filter((a)=>parseInt(a.user_id)===parseInt(JSON.parse(localStorage.getItem("user")).id))[0].user.avatar)?<img className='abs-avva' src={avat}/>:<img className='abs-avva' src={FILE_SERVER+(activeChat&&activeChat.channel_members&&activeChat.channel_members.filter((a)=>parseInt(a.user_id)===parseInt(JSON.parse(localStorage.getItem("user")).id))[0].user.avatar)}/>}
                        
                    </div>}
                <div className='audio-message'>
                   <svg onClick={()=>{setPlay(!playing); if(playing) refs.current.pause(); else refs.current.play()}} data-src={playing?pause:play} ></svg>
                    <div className='player-cont'>
                        <div className='wave-absolute'>
                            <div className='player-time'>{refs.current&&refs.current.duration&&showTime(refs.current.duration)}</div>
                            <div className='wave' style={{marginLeft:`${refs.current&&(parseFloat(refs.current.currentTime)*100)/parseFloat(refs.current.duration)*1.8}px`}}><svg data-src={wave}></svg></div>
                        </div>
                        <div className='player-line'></div>
                    </div>
                <svg data-src={recordme} className="play-icon-me"></svg>

                </div>
                <div className='message-date hovers'>
                {<div className='sent-date'>{<><svg data-src={sen}></svg>{getMessageTime(props.message.created_at)}</>}</div>}
                {getStatues().is_received===1&& <div className='recieve-date'><svg data-src={rec}></svg>{getMessageTime(props.message.message_status.filter((a)=>a.user_id!==(localStorage.getItem("user")&&JSON.parse(localStorage.getItem("user")).id))[0].received_at)}</div>}
                        {getStatues()?.is_watched===true&&<div className='read-date'><svg data-src={red}></svg>{getMessageTime(props.message.message_status.filter((a)=>a.user_id!==(localStorage.getItem("user")&&JSON.parse(localStorage.getItem("user")).id))[0].watched_at)}</div>}
                    </div>
                    <div className="message-date">
                    {getMessageStatus()}
                    </div> 
            </div>}
           </div>
            )
            }
            if(props.message.message_type.name==="TextMessage"){
                return(
           <div onMouseLeave={()=>setOpen(false)} className={"message-hold"+" "+`${opens&&"ac"}`}>
                    <OptionsMenu forward={()=>dispatch({type:"FORWARD-MESSAGEs",payload:props.message})} click={()=>{;}}/>
                 <div onClick={()=>setOpen(true)} ref={refmessage} className={'message-body text-body '+props.type}>

                    <div className='border-element'>
                        {refmessage.current&&showBord(props.type,refmessage.current.clientHeight).map((ad)=>(
                            <div className='border-child'></div>
                        ))}
                    </div>
                     {(props.type==="first-chat"||props.type==="lonely")&&
                    <div className='absolute-avatar'>
                      {!(activeChat.channel_members.filter((a)=>parseInt(a.user_id)===parseInt(JSON.parse(localStorage.getItem("user")).id))[0]&&activeChat.channel_members.filter((a)=>parseInt(a.user_id)===parseInt(JSON.parse(localStorage.getItem("user")).id))[0].user&&activeChat.channel_members.filter((a)=>parseInt(a.user_id)===parseInt(JSON.parse(localStorage.getItem("user")).id))[0].user.avatar)?<img className='abs-avva' src={avat}/>:<img className='abs-avva' src={FILE_SERVER+(activeChat&&activeChat.channel_members&&activeChat.channel_members.filter((a)=>parseInt(a.user_id)===parseInt(JSON.parse(localStorage.getItem("user")).id))[0].user.avatar)}/>}
                        
                    </div>}
                    {props.message.message_content&&props.message.message_content.content}
                    <div className='message-date hovers'>
                    {<div className='sent-date'>
                      {<><svg data-src={sen}></svg>{getMessageTime(props.message.created_at)}</>}</div>}
                    {getStatues().is_received===1&& <div className='recieve-date'><svg data-src={rec}></svg>{getMessageTime(props.message.message_status.filter((a)=>a.user_id!==(localStorage.getItem("user")&&JSON.parse(localStorage.getItem("user")).id))[0].received_at)}</div>}
                        {getStatues()?.is_watched===true&&<div className='read-date'><svg data-src={red}></svg>{getMessageTime(props.message.message_status.filter((a)=>a.user_id!==(localStorage.getItem("user")&&JSON.parse(localStorage.getItem("user")).id))[0].watched_at)}</div>}
                  
                    </div>
                    <div className="message-date">
                    {getMessageStatus()}
                    </div> 
                </div>
           </div>)
            }
        }
        else{
            if(props.message.message_type.name==="ImageMessage"){
               return(
              <div onMouseLeave={()=>setOpen(false)} className={"message-hold"+" "+`${opens&&"ac"}`}>
                    <OptionsMenu forward={()=>dispatch({type:"FORWARD-MESSAGEs",payload:props.message})} click={()=>{;}}/>

                   <div onClick={()=>setOpen(true)} ref={refmessage} className={'message-body message-img-body '+props.type}>

                 <div className='border-element'>
                        {refmessage.current&&showBord(props.type,refmessage.current.clientHeight).map((ad)=>(
                            <div className='border-child'></div>
                        ))}
                    </div>
                    {props.type==="first-chat"&&<div className='bordse'></div>}

                  {(props.type==="first-chat"||props.type==="lonely")&&
                    <div className='absolute-avatar'>
              {!(activeChat.channel_members.filter((a)=>parseInt(a.user_id)!==parseInt(JSON.parse(localStorage.getItem("user")).id))[0]&&activeChat.channel_members.filter((a)=>parseInt(a.user_id)!==parseInt(JSON.parse(localStorage.getItem("user")).id))[0].user&&activeChat.channel_members.filter((a)=>parseInt(a.user_id)!==parseInt(JSON.parse(localStorage.getItem("user")).id))[0].user.avatar)?<img className='abs-avva' src={avat}/>:<img className='abs-avva' src={FILE_SERVER+(activeChat&&activeChat.channel_members&&activeChat.channel_members.filter((a)=>parseInt(a.user_id)!==parseInt(JSON.parse(localStorage.getItem("user")).id))[0].user.avatar)}/>}
                        
                    </div>}
                    <img className='message-img' src={(FILE_SERVER+props.message.message_content[0].file_path)}/>
                    <div className='other-date'>
                        {getMessageTime(props.message.created_at)}
                   </div>
                </div>
              </div>)
            }
            else if(props.message.message_type.name==="vid"){}
            if(props.message.message_type.name==="VoiceMessage"){
                return(
              <div onMouseLeave={()=>setOpen(false)}  className={"message-hold"+" "+`${opens&&"ac"}`}>
            <OptionsMenu forward={()=>dispatch({type:"FORWARD-MESSAGEs",payload:props.message})} click={()=>{;}}/>

            { props.message.message_content&&props.message.message_content[0].file_path&&props.message.message_content[0].file_path!=="false"&&
               <div ref={refmessage} onClick={()=>setOpen(true)} className={'message-body audio-body him '+props.type}> 
       <div className='border-element'>
                        {refmessage.current&&showBord(props.type,refmessage.current.clientHeight).map((ad)=>(
                            <div className='border-child'></div>
                        ))}
                    </div>
                      {(props.type==="first-chat"||props.type==="lonely")&&
                    <div className='absolute-avatar'>
                  {!(activeChat.channel_members.filter((a)=>parseInt(a.user_id)!==parseInt(JSON.parse(localStorage.getItem("user")).id))[0]&&activeChat.channel_members.filter((a)=>parseInt(a.user_id)!==parseInt(JSON.parse(localStorage.getItem("user")).id))[0].user&&activeChat.channel_members.filter((a)=>parseInt(a.user_id)!==parseInt(JSON.parse(localStorage.getItem("user")).id))[0].user.avatar)?<img className='abs-avva' src={avat}/>:<img className='abs-avva' src={FILE_SERVER+(activeChat&&activeChat.channel_members&&activeChat.channel_members.filter((a)=>parseInt(a.user_id)!==parseInt(JSON.parse(localStorage.getItem("user")).id))[0].user.avatar)}/>}
              
                    </div>}
                <div className='audio-message '>
                <audio crossOrigin = 'anonymous' onEnded={()=>{setPlay(false); refs.current.currentTime=0;}} controls={false} ref={refs} src={FILE_SERVER+(props.message.message_content&&props.message.message_content[0].file_path)}>
                        <source src={FILE_SERVER+(props.message.message_content&&props.message.message_content[0].file_path)}/>
                    </audio>
                <svg data-src={record}></svg>
                <svg className='play-icon' onClick={()=>{setPlay(!playing); if(playing) refs.current.pause(); else refs.current.play()}} data-src={playing?pause:play}></svg>
                   
                    <div className='player-cont'>
                        <div className='wave-absolute'>
                        <div className='player-time'>{refs.current&&refs.current.duration&&showTime(refs.current.duration)}</div>
                            <div className='wave' style={{marginLeft:`${refs.current&&(parseFloat(refs.current.currentTime)*100)/parseFloat(refs.current.duration)*1.8}px`}}><svg data-src={wave}></svg></div>
                        </div>
                        <div className='player-line'></div>
                    </div>
                </div>
                <div className='other-date'>
                    {getMessageTime(props.message.created_at)}
               </div>
            </div>}
            </div>
            )
            }
            if(props.message.message_type.name==="TextMessage"){
                return(
             <div className={"message-hold"+" "+`${opens&&"ac"}`}>
                    <OptionsMenu forward={()=>dispatch({type:"FORWARD-MESSAGEs",payload:props.message})} click={()=>{;}}/>
                 <div onClick={()=>setOpen(true)} ref={refmessage} className={'message-body text-body '+props.type}>
                         <div className='border-element'>
                        {refmessage.current&&showBord(props.type,refmessage.current.clientHeight).map((ad)=>(
                            <div className='border-child'></div>
                        ))}
                    </div>
                   {(props.type==="first-chat"||props.type==="lonely")&&
                    <div className='absolute-avatar'>
                          {!(activeChat.channel_members.filter((a)=>parseInt(a.user_id)!==parseInt(JSON.parse(localStorage.getItem("user")).id))[0]&&activeChat.channel_members.filter((a)=>parseInt(a.user_id)!==parseInt(JSON.parse(localStorage.getItem("user")).id))[0].user&&activeChat.channel_members.filter((a)=>parseInt(a.user_id)!==parseInt(JSON.parse(localStorage.getItem("user")).id))[0].user.avatar)?<img className='abs-avva' src={avat}/>:<img className='abs-avva' src={FILE_SERVER+(activeChat&&activeChat.channel_members&&activeChat.channel_members.filter((a)=>parseInt(a.user_id)!==parseInt(JSON.parse(localStorage.getItem("user")).id))[0].user.avatar)}/>}
              

                    </div>}
                    {props.message.message_content&&props.message.message_content.content}

            <div className="other-date">{getMessageTime(props.message.created_at)}</div>
          </div>
             </div>
        );
      }
      if (props.message.message_type.name === "call") {
        return (
          <div className="call-body">
            {(props.type === "first-chat" || props.type === "lonely") && (
              <div className="absolute-avatar">
                <img className="abs-avva" src={FILE_SERVER+activeChat.user.avatar} />
              </div>
            )}
            <svg data-src={misscall}></svg>
            <div className="missed-body">
              Missed Call At {props.message.sent}
            </div>
          </div>
        );
      }
    }
  };

  return (<>
  {props.message.message_content&&<div id={`main-container-${props.message.id}`}
      className={
        "message-container" +
        ` ${props.marg && "mt25"} ${
          parseInt(props.message.sender_user_id) ===
            parseInt(JSON.parse(localStorage.getItem("user")).id) &&
          "self-align"
        } ${props.message.message_type.name === "call" && "center-align"}`
      }
    >
      {showMessage()}
    </div>}
  </>
    
  );
}

export default ChatMessage;
