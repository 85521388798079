import axios from "axios";
import { store } from "./redux/store";
const REACT_APP_USER_BASE_URL = "";

//************************************//

export const API = axios.create({
  baseURL: REACT_APP_USER_BASE_URL,
  headers: {
    Authorization:
      "Bearer " +
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiYTA2NzEyNTE1MGM4NDYyZDFhMjAzZTYzNTllYzY4NzYyYzg2ZDcyMTU4MDkxOGNkZWVkMDE0MDU1MDY0NDI3NjllYzc5MGY0MmEwNTczZTkiLCJpYXQiOjE2NjMzMjQ1OTIuNTU3MjIyLCJuYmYiOjE2NjMzMjQ1OTIuNTU3MjI0LCJleHAiOjE2NzExMDA1OTIuNTUxNTQ5LCJzdWIiOiI0NCIsInNjb3BlcyI6WyIxNSIsIjciLCI1IiwiMjIiLCI2Il19.YA9xil5L_RG_sT_HSqJIT5rdWRBHTNJegoDDBl1yceHFW3HgZMfiFoaUDo0XEblMHQlTnCCcd9zrj0SAYFFHLNmTVemozNP3wCrBSLY6rWTfv4GeQI1r-jukZE1VmjlQDGtm764-5D1NmKF_u71bGfkzJMGmkBQZqOFUIyVX72ByFYtiP47lCzoiryMJZxoRlzr4HkLcClFBjCFM0yOn9khLtvGSxEFpemQvg7Zs5K5EmFuYv2b2oFVugk7BpTTvSxzupoU3QNoXtXEP-SR28eETFrGQOUfwbTdDg_0Srj6fu6_rZHkN2Ofe98QlSf-SwkgSK5rq1pVD0bCO5k7rsfrFGpgkULsXkUHgA0EF3so77um3YrwGqtKouR1d1qrrPNvScJMHwiAf5uQ5qnRgeMDYScSFoKCELtWO2C7PjjAce2BFENOXK4EIDMzDzg5H69h5cOXSX5Ydp4j_ETIejJkHuh0qK8w9volvn7ml9xPg0Rr7Zqaduv7nyGsDcZXNI0UdyUl0KL0uPaJ8kgN6-crzKf75QIen1GGJBl8OqEfzg-y8z3vfDuy620tPAGwxGkJFH-n-ELJt59pwg620RI5raxT7EDKgjBSxbgV6wE5j_qAgyqmw93tRqc-lo4ikfFzZ6z2u83LLdxrML8l21mqUe-GO1B9kCcTo_h-qHq0",
    "Content-Type": "application/json",
  },
});

API.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
API.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    return Promise.reject(error.response);
  }
);

/**************API2*********************/
const base_user =
  store?.getState().whatsapp.fetched_url === true
    ? store?.getState()?.whatsapp.base_user
    : process.env.REACT_APP_USER_BASE_URL;
const base =
  store?.getState().whatsapp.fetched_url === true
    ? store?.getState()?.whatsapp.base_url
    : process.env.REACT_APP_BASE_URL;
export const API2 = axios.create({
  baseURL: `${base_user}`,
  headers: {
    Authorization:
      "Bearer " +
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiYTA2NzEyNTE1MGM4NDYyZDFhMjAzZTYzNTllYzY4NzYyYzg2ZDcyMTU4MDkxOGNkZWVkMDE0MDU1MDY0NDI3NjllYzc5MGY0MmEwNTczZTkiLCJpYXQiOjE2NjMzMjQ1OTIuNTU3MjIyLCJuYmYiOjE2NjMzMjQ1OTIuNTU3MjI0LCJleHAiOjE2NzExMDA1OTIuNTUxNTQ5LCJzdWIiOiI0NCIsInNjb3BlcyI6WyIxNSIsIjciLCI1IiwiMjIiLCI2Il19.YA9xil5L_RG_sT_HSqJIT5rdWRBHTNJegoDDBl1yceHFW3HgZMfiFoaUDo0XEblMHQlTnCCcd9zrj0SAYFFHLNmTVemozNP3wCrBSLY6rWTfv4GeQI1r-jukZE1VmjlQDGtm764-5D1NmKF_u71bGfkzJMGmkBQZqOFUIyVX72ByFYtiP47lCzoiryMJZxoRlzr4HkLcClFBjCFM0yOn9khLtvGSxEFpemQvg7Zs5K5EmFuYv2b2oFVugk7BpTTvSxzupoU3QNoXtXEP-SR28eETFrGQOUfwbTdDg_0Srj6fu6_rZHkN2Ofe98QlSf-SwkgSK5rq1pVD0bCO5k7rsfrFGpgkULsXkUHgA0EF3so77um3YrwGqtKouR1d1qrrPNvScJMHwiAf5uQ5qnRgeMDYScSFoKCELtWO2C7PjjAce2BFENOXK4EIDMzDzg5H69h5cOXSX5Ydp4j_ETIejJkHuh0qK8w9volvn7ml9xPg0Rr7Zqaduv7nyGsDcZXNI0UdyUl0KL0uPaJ8kgN6-crzKf75QIen1GGJBl8OqEfzg-y8z3vfDuy620tPAGwxGkJFH-n-ELJt59pwg620RI5raxT7EDKgjBSxbgV6wE5j_qAgyqmw93tRqc-lo4ikfFzZ6z2u83LLdxrML8l21mqUe-GO1B9kCcTo_h-qHq0",
    "Content-Type": "application/json",
  },
});
API2.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
API2.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    return Promise.reject(error.response);
  }
);

export const Axios = { API, API2 };
