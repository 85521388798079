import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChatMenu from "./ChatMenu";
import ChattingScreen from "./ChattingScreen";
function ChatWidget(props) {
  const dispatch = useDispatch();
  const chatData = useSelector((state) => state.chat.data);
  const activeChat = useSelector((state) => state.chat.activeChat);
  const main = useSelector((state) => state.chat.main);

  return (
    <>
      {
        <div
          onClick={(e) => {
            dispatch({ type: "OPEN-CHAT", payload: null });
            props.openChat(false);
          }}
          className={`lang-modalDisable ${props.open && "open"}`}
        ></div>
      }
      <div
        className={`lang-modal chat-modal ${props.open && "open"} ${"nopad"}`}
      >
        <div className="chat-page">
          <>
            <ChatMenu active={main === "main"} />
          </>
          <>
            <ChattingScreen active={main === "chat"} />
          </>
        </div>
      </div>
    </>
  );
}

export default ChatWidget;
