import { useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

const ContactItem=(props)=>{
    const companies=useSelector((state)=>state.whatsapp.companies)
    const roles=useSelector((state)=>state.whatsapp.roles)
    const edit=useRef(false)
    const setEdit=(s)=>{
        edit.current=s
    }
    const [filterItems,setFilterItems]=useState([1,4])
    const Filter=(id)=>{
        if(props.item.whatsapp_contact_company_id===id){
            dispatch({type:"UPD_CONTACT",payload:{...props.item,whatsapp_contact_company_id:null}})
        }
        else{
            dispatch({type:"UPD_CONTACT",payload:{...props.item,whatsapp_contact_company_id:id}})
        }
    }
    const FilterRoles=(id)=>{
        if(props.item.whatsapp_contact_role_id===id){
            dispatch({type:"UPD_CONTACT",payload:{...props.item,whatsapp_contact_role_id:null}})
        }
        else{
            dispatch({type:"UPD_CONTACT",payload:{...props.item,whatsapp_contact_role_id:id}})
        }
    }
    const setName=(v)=>{
        dispatch({type:"UPD_CONTACT",payload:{...props.item,name:v}})
    }
    const Save=()=>{
        setEdit(!edit.current)
        dispatch({type:"CHANGE_NAME",id:props.item.id,payload:{name:props.item.name,cid:props.item.whatsapp_contact_company_id,rid:props.item.whatsapp_contact_role_id}})
    }
    const dispatch=useDispatch()
    return(
        <div className={"contact-item"+` ${edit.current&&"contact-edit-it"}`}>
        <div className="contact-info">
            {!edit.current&&<div className="contact-img">{props.item.profile_photo&&<img src={props.item.profile_photo}/>}</div>}
            <div className="contact-detail">
                {!edit.current?<div className="contact-name">{props.item.name||props.item.pushname}</div>:<input value={props.item.name} onChange={(e)=>{setName(e.target.value)}}/>}
                <div className="contact-numb">{props.item.number}</div>
            </div>
{!edit.current?     <>
    {props.item.whatsapp_contact_company_id&&<div className="contact-comp">
                <div className="contact-name">{companies.filter((s)=>s.id===props.item.whatsapp_contact_company_id)[0]?.name}</div>
                <div className="comp-icon">
                {companies.filter((s)=>s.id===props.item.whatsapp_contact_company_id)[0]?.name?.substring(0,2)}
                </div>
            </div>}
           {props.item.whatsapp_contact_role_id&& <div className="contact-role">
                <div className="contact-name">{roles.filter((s)=>s.id===props.item.whatsapp_contact_role_id)[0]?.name}</div>
                <div className="role-icon">
                <svg id="_10x10_flag_photo" data-name="10x10 flag photo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="10" height="10" viewBox="0 0 10 10">
                <g id="Mask_Group_314" data-name="Mask Group 314">
                    <g id="manager">
                    <path id="Path_21330" data-name="Path 21330" d="M5,3.947A1.973,1.973,0,1,0,3.027,1.973,1.973,1.973,0,0,0,5,3.947Z" fill="#5d5d5d"/>
                    <path id="Path_21331" data-name="Path 21331" d="M7.648,4.9h0C7.388,4.839,6.6,4.729,6.6,4.729a.175.175,0,0,0-.216.107L5.2,8.086a.214.214,0,0,1-.4,0L3.615,4.836a.175.175,0,0,0-.164-.115c-.017,0-.826.113-1.1.18a1.678,1.678,0,0,0-1.2,1.661v2.7A.734.734,0,0,0,1.889,10H8.111a.734.734,0,0,0,.734-.734V6.554A1.736,1.736,0,0,0,7.648,4.9Z" fill="#5d5d5d"/>
                    <path id="Path_21332" data-name="Path 21332" d="M5.447,4.667a.252.252,0,0,0-.186-.077H4.739a.252.252,0,0,0-.186.077.242.242,0,0,0-.032.285l.279.42-.13,1.1.257.684a.078.078,0,0,0,.147,0l.257-.684-.13-1.1.279-.42a.242.242,0,0,0-.032-.285Z" fill="#5d5d5d"/>
                    </g>
                </g>
                </svg>

                </div>
            </div>}
            </>   :
            <div className={"contact-detail-edit "+`${props.small&&"col-res"}`}>
                 
                {!props.small?
                <>
                 {companies.map((s)=>(
                 <div className={`edit-f contact-filter-item ${props.item.whatsapp_contact_company_id===s.id&&"active-f"}`} onClick={()=>Filter(s.id)}>
                    <span className="filter-title">{s.name}</span>
                </div>
               ))}
                {roles.map((s)=>(
                <div className={`edit-f contact-filter-item ${props.item.whatsapp_contact_role_id===s.id&&"active-f"}`} onClick={()=>FilterRoles(s.id)}>
                    <span className="filter-title">{s.name}</span>
                </div>))}
               
                </>:
                <>
                <div className="comp-edit">
                {companies.map((s)=>(
                 <div className={`edit-f contact-filter-item ${props.item.whatsapp_contact_company_id===s.id&&"active-f"}`} onClick={()=>Filter(s.id)}>
                    <span className="filter-title">{s.name}</span>
                </div>
               ))}
                </div>
                <div className="role-edit">
                {roles.map((s)=>(
                <div className={`edit-f contact-filter-item ${props.item.whatsapp_contact_role_id===s.id&&"active-f"}`} onClick={()=>FilterRoles(s.id)}>
                    <span className="filter-title">{s.name}</span>
                </div>))}
                </div>
                </>}
            </div>
            }
            
        </div>
        <div className="contact-edit" >
       {!edit.current? <svg onClick={()=>setEdit(true)} id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
        <g id="Mask_Group_308" data-name="Mask Group 308" >
            <g id="edit-5" transform="translate(0 0.029)">
            <path id="Path_21336" data-name="Path 21336" d="M14.57,9.08a.393.393,0,0,0-.393.393v3.491A1.181,1.181,0,0,1,13,14.144H1.966A1.181,1.181,0,0,1,.786,12.964V2.719A1.181,1.181,0,0,1,1.966,1.54H5.456a.393.393,0,0,0,0-.786H1.966A1.968,1.968,0,0,0,0,2.719V12.964A1.968,1.968,0,0,0,1.966,14.93H13a1.968,1.968,0,0,0,1.966-1.966V9.474A.393.393,0,0,0,14.57,9.08Zm0,0" fill="#5d5d5d"/>
            <path id="Path_21337" data-name="Path 21337" d="M6.148,7.012l5.74-5.741,1.851,1.851L8,8.863Zm0,0" fill="#5d5d5d"/>
            <path id="Path_21338" data-name="Path 21338" d="M5.211,9.8l2.046-.567L5.778,7.754Zm0,0" fill="#5d5d5d"/>
            <path id="Path_21339" data-name="Path 21339" d="M14.251.3a.984.984,0,0,0-1.39,0l-.417.417L14.3,2.566l.417-.417a.984.984,0,0,0,0-1.39Zm0,0" fill="#5d5d5d"/>
            </g>
        </g>
        </svg>
        :
        <>
        <div className="save-contact" onClick={()=>Save()}>Save</div>
        <div className="cancel-edit" onClick={()=>setEdit(false)}>
        <svg id="Component_134_1" data-name="Component 134 – 1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g id="Rectangle_4545" data-name="Rectangle 4545" fill="none" stroke="#cecdcd" stroke-width="0.5">
                <rect width="20" height="20" rx="5" stroke="none"/>
                <rect x="0.25" y="0.25" width="19.5" height="19.5" rx="4.75" fill="none"/>
            </g>
            <line id="Line_962" data-name="Line 962" x1="12" transform="translate(5.758 5.757) rotate(45)" fill="none" stroke="#ff2e2e" strokeLinecap="round" stroke-width="1"/>
            <line id="Line_963" data-name="Line 963" x1="12" transform="translate(14.242 5.757) rotate(135)" fill="none" stroke="#ff2e2e" strokeLinecap="round" stroke-width="1"/>
            </svg>

        </div>
        </>
        }
        </div>
    </div>
    )
}
export default ContactItem