import { all, fork, put, takeEvery } from "@redux-saga/core/effects";
import AxiosInstance, {
  AxiosFileInstance,
} from "../../../helpers/axiosInstance";
import { toast } from "react-toastify";
import { ADD_DEPT_URL, GET_DEPT_URL, UPD_DEPT_URL } from "./constants";
import store from "../../store";
import { GET_LANGS_URL } from "../../LinkedLanguages/constants";
function* getAllDepartments(action) {
  let response;
  try {
    yield put({ type: "GENERAL-LOADING-COM" });

    response = yield AxiosInstance.post(
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url +
            "/ewallet_categories_content_service/public" +
            GET_DEPT_URL
        : process.env.REACT_APP_BASE_URL +
            "/ewallet_categories_content_service/public" +
            GET_DEPT_URL,
      JSON.stringify({
        company_type_id: action.id,
      })
    );
    let langs = yield AxiosInstance.get(GET_LANGS_URL);
    yield put({ type: "GET-LANG-REDUCER", payload: langs.data.data });
    yield put({ type: "GENERAL-DONE" });
    yield put({ type: "GET-COM-REDUCER", payload: response.data.data });
    yield put({ type: "GENERAL-DONE-COM" });
  } catch (e) {
    yield put({ type: "GENERAL-DONE-COM" });

    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchGetAllDepartments() {
  yield takeEvery("GET-COM", getAllDepartments);
}
function* AddDepartments(action) {
  let response, responses;
  try {
    yield put({ type: "GENERAL-DONE-COM" });
    yield put({ type: "request-running-COM", payload: action.payload });
    let a = action.id;
    if (a) {
    }
    if (action.serv) {
      response = yield AxiosInstance.post(
        store?.getState().whatsapp.fetched_url === true
          ? store?.getState()?.whatsapp.base_url +  "/ewallet_categories_content_service/public" +
          "/api/v1/service_subscriptions"
          : process.env.REACT_APP_BASE_URL +
              "/ewallet_categories_content_service/public" +
              "/api/v1/service_subscriptions",
        JSON.stringify(action.payload)
      );
    } else if (action.id) {
      response = yield AxiosInstance.post(
        store?.getState().whatsapp.fetched_url === true
          ? store?.getState()?.whatsapp.base_url +  "/ewallet_categories_content_service/public" +
          "/api/v1/services/create"
          : process.env.REACT_APP_BASE_URL +
              "/ewallet_categories_content_service/public" +
              "/api/v1/services/create",
        JSON.stringify(action.payload)
      );
    } else {
      response = yield AxiosInstance.post(
        store?.getState().whatsapp.fetched_url === true
          ? store?.getState()?.whatsapp.base_url+"/ewallet_categories_content_service/public"+ADD_DEPT_URL
          : process.env.REACT_APP_BASE_URL +
              "/ewallet_categories_content_service/public" +
              ADD_DEPT_URL,
        JSON.stringify(action.payload)
      );
    }
    yield put({ type: "request-running-COM-red", payload: action.payload });
    setTimeout(() => {
      store.dispatch({ type: "request-running-COM-red", payload: null });
    }, 4000);
    yield put({ type: "GENERAL-DONE-COM" });
    let id,
      view = action.view;
    if (view === "Communications Companies") id = 1;
    if (view === "Water Companies") id = 2;
    if (view === "Gas Companies") id = 3;
    if (view === "Electricty Companies") id = 4;

    yield put({ type: "GET-COM", id: id });
  } catch (e) {
    yield put({
      type: "GENERAL-ERR-COM",
      payload: { data: action.payload, message: e.message },
    });
    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchAddDepartments() {
  yield takeEvery("ADD-COM", AddDepartments);
}
function* UpdateDepartments(action) {
  let response, responses;
  try {
    yield put({ type: "GENERAL-DONE-COM" });
    yield put({ type: "request-running-COM", payload: action.payload });
    if (action.serv) {
      response = yield AxiosInstance.put(
        store?.getState().whatsapp.fetched_url === true
          ? store?.getState()?.whatsapp.base_url +  "/ewallet_categories_content_service/public" +
          "/api/v1/service_subscriptions/" +
          action.payload.id
          : process.env.REACT_APP_BASE_URL +
              "/ewallet_categories_content_service/public" +
              "/api/v1/service_subscriptions/" +
              action.payload.id,
        JSON.stringify(action.payload)
      );
    } else if (action.id) {
      response = yield AxiosInstance.post(
        store?.getState().whatsapp.fetched_url === true
          ? store?.getState()?.whatsapp.base_url+"/ewallet_categories_content_service/public" +
          "/api/v1/services/update"
          : process.env.REACT_APP_BASE_URL +
              "/ewallet_categories_content_service/public" +
              "/api/v1/services/update",
        JSON.stringify(action.payload)
      );
    } else {
      let ad;
      let regs = [];
      action.payload.regions.map((a) => {
        if (a && a.id) {
          regs.push(a.id);
        } else {
          regs.push(a);
        }
      });
      ad = { ...action.payload, regions: regs };
      response = yield AxiosInstance.post(
        store?.getState().whatsapp.fetched_url === true
          ? store?.getState()?.whatsapp.base_url +"/ewallet_categories_content_service/public" +
          UPD_DEPT_URL
          : process.env.REACT_APP_BASE_URL +
              "/ewallet_categories_content_service/public" +
              UPD_DEPT_URL,
        JSON.stringify(ad)
      );
    }
    yield put({ type: "request-running-COM-red", payload: action.payload });
    setTimeout(() => {
      store.dispatch({ type: "request-running-COM-red", payload: null });
    }, 4000);
    yield put({ type: "GENERAL-DONE-COM" });
    let id,
      view = action.view;
    if (view === "Communications Companies") id = 1;
    if (view === "Water Companies") id = 2;
    if (view === "Gas Companies") id = 3;
    if (view === "Electricty Companies") id = 4;

    yield put({ type: "GET-COM", id: id });
    yield put({ type: "GENERAL-DONE-COM" });
  } catch (e) {
    yield put({
      type: "GENERAL-ERR-COM",
      payload: { data: action.payload, message: e.message },
    });
    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchUpdDepartments() {
  yield takeEvery("UPD-COM", UpdateDepartments);
}
function* getReg(action) {
  try {
    action.payload.map(
      (a) =>
        function* () {
          let res = yield AxiosInstance.get("/api/v1/regions/show/" + a);
          yield put({
            type: "GET_COM_REG_RED",
            payload: res.data.data.region_translations.filter(
              (d) => d.language_code === "en"
            )[0].region_name,
          });
        }
    );
  } catch (e) {}
}
function* watchGetReg() {
  yield takeEvery("GET_COM_REG", getReg);
}

export function* CompaniesSaga() {
  yield all([
    fork(watchGetAllDepartments),
    fork(watchUpdDepartments),
    fork(watchAddDepartments),
  ]);
}
