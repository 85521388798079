
import accounting from  "../../../assets/svg/accounting.svg"
import sales from  "../../../assets/svg/sales.svg"
import CFO from  "../../../assets/svg/CFO.svg"
import operations from  "../../../assets/svg/operations.svg"
const initialState = {
  view:"Employees",
  departments:[],
  pageFollower:["Employees"],
  newDepartment:{translations:[{name:"",language_code:"en"},{name:"",language_code:"ar"},{name:"",language_code:"tr"}],icon:null,son_departments:[]},
  mode:"read",
  open:false,
  modalData:{name:"",icon:null,code:""},
  loading:false,
  Active_Ids:[],
  request_running:false,
  request_data:null,
  message:"",
  error:false,
  img_upload:{type:"",status:false}

}
 export const HRMReducer=(state = initialState, { type, payload }) => {
  switch (type) {
    case "IMG-UPLOAD-DEPT":{
      return({
        ...state,
        img_upload:{...payload}
      })
    }
    case "request-running-dept":{
      return({
        ...state,
        request_running:true,
        request_data:payload
      })
    }
    case "GENERAL-ERR-dept":{
      return({
        ...state,
        request_running:false,
        request_data:payload.data,
        error:true,
        message:payload.message
      })
    }
    case "request-running-dept-red":{
      
      return({
        ...state,
        request_running:false,
        request_data:payload,
        error:false,
        message:""
      })
    }
    case "GENERAL-LOADING-DEPT":{
      return({
        ...state,
        loading:true
      })
    }
    case "VIEW-DEPT":{
      return({
        ...state,
        view_language:payload
      })
    }
    case "GENERAL-DONE-DEPT":{
      return({
        ...state,
        loading:false,
        open:false,
        

      })
    }
    case "LEVEL-DONE-DEPT":{
      return({
        ...state,
        loading:false
      })
    }
    case "GET-DEPT-REDUCER":{
      return({...state,departments:[...payload],request_data:null,request_running:false,error:false,message:""})

    }
    case "Active-add":{
      return({
        ...state,
        Active_Ids:payload
      })
    }
  case "HRM-VIEW":
    return { 
      ...state,
       view:payload,
      pageFollower:[payload]
      }
    case "NEW-DEPARTMENT":{
      
    
      return{
        ...state,
        newDepartment:{...payload}
      }
    }
    case "OPEN":{
      return({
        ...state,
        open:payload
      })
    }
    case "MODE":{
      return({
        ...state,
        mode:payload
      })
    }
    case "MOD-DATA":{
      return({
        ...state,
        modalData:{...payload}
      })
    }
    
  default:
    return state
  }
}
  