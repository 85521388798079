import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ava from "../../../assets/svg/ava.jpg";
import avat from "../../../assets/svg/avat.png";
import { FILE_SERVER } from "../../../constants";
import ChatItem from "./ChatItem";
import UserItem from "./UserItem";
function ChatMenu(props) {
  const chatData = useSelector((state) => state.chat.data);
  const chatUsers = useSelector((state) => state.chat.chatUsers);
  const users = useSelector((state) => state.chat.users);
  const SearchEnable = useSelector((state) => state.chat.SearchEnable);
  const [searchText,setSearch]=useState("")
  const dispatch=useDispatch()
  const FocuseSearch=(p)=>{
    dispatch({type:"SEARCH_CHAT",payload:p})
  }
  const Search=(d)=>{
    if(d.length>0){
      FocuseSearch(true)
    dispatch({type:"START_CHAT",payload:d})}
    else{
      FocuseSearch(false)

    }
  }
  const BlurSearch=(d)=>{
   
      FocuseSearch(d)
      setSearch("")
  }
  return (
    <>
      <div
        className="chat-main"
        style={{ zIndex: props.active ? "999" : "777" }}
      >
        <div className="chat-main-top">
          <div className="user-avatar">
            {JSON.parse(localStorage.getItem("user")).avatar &&
            JSON.parse(localStorage.getItem("user")).avatar.length > 0 ? (
              <img
                src={
                  FILE_SERVER + JSON.parse(localStorage.getItem("user")).avatar
                }
              />
            ) : (
              <img src={avat} />
            )}
          </div>
          <div className="user-name-avt">
            {JSON.parse(localStorage.getItem("user")).full_name?JSON.parse(localStorage.getItem("user")).full_name:JSON.parse(localStorage.getItem("user")).username}
          </div>
        </div>
        <div className="search">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <g id="search-3" transform="translate(0 0)">
              <g
                id="Group_3684"
                data-name="Group 3684"
                transform="translate(14.255 4.331)"
              >
                <g id="Group_3683" data-name="Group 3683">
                  <path
                    id="Path_15701"
                    data-name="Path 15701"
                    d="M77.195,111.193a.741.741,0,0,1,1.048,0,5.064,5.064,0,0,1,1.448,4.1.741.741,0,0,1-.737.667c-.025,0-.05,0-.074,0a.741.741,0,0,1-.664-.811,3.588,3.588,0,0,0-1.022-2.91A.741.741,0,0,1,77.195,111.193Z"
                    transform="translate(-76.978 -110.976)"
                    fill="#8e8e8e"
                  />
                </g>
              </g>
              <g
                id="Group_3686"
                data-name="Group 3686"
                transform="translate(3.056 0)"
              >
                <g id="Group_3685" data-name="Group 3685">
                  <path
                    id="Path_15702"
                    data-name="Path 15702"
                    d="M8.472,0A8.472,8.472,0,1,1,0,8.472,8.482,8.482,0,0,1,8.472,0Zm0,15.462a6.99,6.99,0,1,0-6.99-6.99A7,7,0,0,0,8.472,15.462Z"
                    fill="#8e8e8e"
                  />
                </g>
              </g>
              <g
                id="Group_3688"
                data-name="Group 3688"
                transform="translate(0 13.133)"
              >
                <g id="Group_3687" data-name="Group 3687">
                  <path
                    id="Path_15703"
                    data-name="Path 15703"
                    d="M336.926,342.311l5.385-5.385a.741.741,0,0,1,1.048,1.048l-5.385,5.385a.741.741,0,0,1-1.048-1.048Z"
                    transform="translate(-336.708 -336.71)"
                    fill="#8e8e8e"
                  />
                </g>
              </g>
            </g>
          </svg>
          <input value={searchText} onChange={(e)=>{setSearch(e.target.value); Search(e.target.value)}}  placeholder="Search" className="" />
        </div>
        <div className="contact-head">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="388"
            height="20"
            viewBox="0 0 388 20"
          >
            <g
              id="Group_8319"
              data-name="Group 8319"
              transform="translate(-892 -203)"
            >
              <g id="Group_8318" data-name="Group 8318">
                <text
                  id="Your_message_list_you_have_6_contacts"
                  data-name="Your message list, you have 6 contacts"
                  transform="translate(912 217)"
                  fill="#5d5d5d"
                  fontSize="12"
                  fontFamily="SegoeUI-Light, Segoe UI"
                  fontWeight="300"
                >
                  <tspan x="0" y="0">
                    Your message list, you have 6 contacts
                  </tspan>
                </text>
              </g>
              <g
                id="Rectangle_4788"
                data-name="Rectangle 4788"
                transform="translate(892 203)"
                fill="none"
                stroke="#707070"
                strokeWidth="0.4"
              >
                <rect width="388" height="20" rx="5" stroke="none" />
                <rect
                  x="0.2"
                  y="0.2"
                  width="387.6"
                  height="19.6"
                  rx="4.8"
                  fill="none"
                />
              </g>
            </g>
          </svg>
        </div>
        {SearchEnable===false?<div className="chats-container">
          {chatData.map((a, i) => (
           ( !(a.messages.length===1&&a.messages[0].message_content&&a.messages[0].message_content[0]&&a.messages[0].message_content[0].file_path==="false"))&& <ChatItem chat={a} selected={()=>BlurSearch(false)}/>
          ))}
        </div>
        :<>
        <div className="chats-container">
        {users.filter((a)=>!chatUsers.includes(a.id)).map((a)=>(
          <UserItem chat={a} selected={()=>{BlurSearch(true); setTimeout(()=>{
            dispatch({type:"SEARCH_CHAT",payload:false})
            setSearch("")
          },1000)}}/>
        ))}
        {chatData.filter((a)=>
        a.channel_members.filter((us)=>us.user_id!==JSON.parse(localStorage.getItem("user")).id)[0].user&&
        ((a.channel_members.filter((us)=>us.user_id!==JSON.parse(localStorage.getItem("user")).id)[0].user.full_name&&a.channel_members.filter((us)=>us.user_id!==JSON.parse(localStorage.getItem("user")).id)[0].user.full_name.includes(searchText))||(a.channel_members.filter((us)=>us.user_id!==JSON.parse(localStorage.getItem("user")).id)[0].user.full_name&&a.channel_members.filter((us)=>us.user_id!==JSON.parse(localStorage.getItem("user")).id)[0].user.full_name.includes(searchText)||a.channel_members.filter((us)=>us.user_id!==JSON.parse(localStorage.getItem("user")).id)[0].user.username.includes(searchText)))).map((ch)=>(
          ( !(ch.messages.length===1&&ch.messages[0].message_content&&ch.messages[0].message_content.length&&ch.messages[0].message_content[0].file_path==="false")||(searchText.length>0))&& <ChatItem chat={ch} selected={()=>BlurSearch(false)}/>

        ))}
        </div>
        </>}
      </div>
    </>
  );
}

export default ChatMenu;
