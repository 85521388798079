import React, { useEffect } from "react";
import "./styles/main.css";
import avatar from "../../assets/images/profile-picture-girl-1.svg";
import searchIcon from "../../assets/images/search.svg";
import filter from "../../assets/images/filter.svg";
import edit from "../../assets/images/edit.svg";
import Icon from "../Icon";
import Alert from "./Alert";
import Contact from "./Contact";
import OptionsBtn from "../OptionsButton";
import ProfileNo from "../../assets/images/profileNo.png";
import grop from "../../assets/images/group.svg";
import { getMessagesWithChats } from "../../../../../redux/whatsapp/actions";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import SearchComponent from "./SearchComponent";
import ChatUsers from "./ChatUsers";
import { FILE_SERVER } from "../../../../../constants";
const Sidebar = (props) => {
  const parents = useRef(null)
  const news = useRef([])
  const bool = useRef(false)
  const { setOpen, openContacts } = props
  var search = useSelector((state) => state.whatsapp.search)
  var founds = useSelector((state) => state.whatsapp.founds)
  var contacts = useSelector((state) => state.whatsapp.contacts)

  var users = useSelector((state) => state.chat.users)
  const setSearch = (a) => {
    bool.current = false
    news.current = []
    dispatch({ type: "SEARCH_WA_SAGA", payload: a })
  }
  var searchConv = useSelector((state) => state.whatsapp.searchConv)
  const setSearchConv = (a) => {
    bool.current = false
    news.current = []
    dispatch({ type: "SEARCH_WA_CONV", payload: a })
  }
  const dispatch = useDispatch();
  // useEffect(() => {
  //   parents.current && autoAnimate(parents.current, { disrespectUserMotionPreference: true, duration: 300, easing: "ease-in" })
  // }, [parents])
  // useEffect(() => {
  //   // const interval = setInterval(() => {
  //   props?.chats?.map((chat) => {
  //     
  //     dispatch({
  //       type: "LAST_MSG",
  //       payload: {
  //         id: chat?.id,
  //         lastMsg: chat?.messages[0]?.body,
  //       },
  //     });
  //   });
  //   // }, 3000);
  // }, [props?.chats]);
  useEffect(() => { }, [props?.chats]);

  function handleSetUser(contact, id) {
    setOpen(false)
    dispatch({ type: "OPEN-CHAT", payload: null })
    props.setId(contact);
    props.setUser(contact);
    if (!contact.isNew) {
      dispatch({ type: "READ_CHAT", id: contact.id })
    }
    setSearch("")
  }
  const compares = (a, b) => {
    if (parseInt(a?.messages[0]?.sent_at) < parseInt(b?.messages[0]?.sent_at)) {
      return 1
    }
    else {
      if (a.messages.length === 0 || b.messages.length === 0) {
        return 1
      }
      else {
        return -1
      }
    }
  }
  const refs = useSelector((state) => state.whatsapp.refs)
  useEffect(() => {

  }, [refs])
  const isSearchFor = (ch) => {
    if (search?.length > 0) {
      if ((ch.whatsapp_contact?.name && ch.whatsapp_contact?.name?.toLowerCase()?.includes(search.toLowerCase())) || (ch.whatsapp_contact?.pushname && ch.whatsapp_contact?.pushname?.toLowerCase()?.includes(search.toLowerCase())) || (("+" + ch.id_from_whatsapp?.split("@")[0])?.includes(search))) {
        return true
      }
      else {
        return false
      }
    }
    else {
      return true
    }
  }
  const isSearchForC = (ch) => {
    if (searchConv?.length > 0) {
      if ((ch.whatsapp_contact?.name && ch.whatsapp_contact?.name?.toLowerCase().includes(searchConv?.toLowerCase())) || (ch.whatsapp_contact?.pushname && ch.whatsapp_contact?.pushname?.toLowerCase()?.includes(searchConv?.toLowerCase())) || (("+" + ch.id_from_whatsapp?.split("@")[0]).includes(searchConv))) {
        return true
      }
      else {
        return false
      }
    }
    else {
      return true
    }
  }
  const showUnread = () => {
    if (bool.current) {
      bool.current = false
      news.current = []
    }
    else {
      bool.current = true
      news.current = props.chats.filter((ds) => parseInt(ds.unread) > 0)
    }
  }
  const openAdmin = () => {
    if (props.chats.length > 0) {
      handleSetUser(props.chats.filter((s) => s.is_admin === 1)[0], props.chats.filter((s) => s.is_admin === 1)[0].id);
      bool.current = false; news.current = [];
    }

  }
  const getNew = () => {
    if (props.chats.length > 0) {
      return props?.chats?.filter((s) => s?.is_admin === 1)[0]?.unread
    }
  }
  const chatsArray = useSelector((state) => state.chat.data)
  const handleChat = (em) => {
    if (chatsArray.filter((s) => s.channel_members.filter((m) => m.user_id === em.id).length > 0).length > 0) {
      dispatch({ type: "OPEN-CHAT", payload: chatsArray.filter((s) => s.channel_members.filter((m) => m.user_id === em.id).length > 0)[0] })
    }
    else {
      dispatch({
        type: "OPEN-CHAT", payload: {
          id: Math.random(),
          messages: [],
          total_unread_message_count: 0,
          channel_members: [{ user_id: em.id, role_id: em.role_id, user: em }, { user_id: JSON.parse(localStorage.getItem("user")).id, role_id: JSON.parse(localStorage.getItem("user")).role_id, user: JSON.parse(localStorage.getItem("user")) }]
        }
      })


    }
  }
  const goToMessage = (i, c) => {
    dispatch({ type: "CHAT_ID", payload: null });
    var numb = i.id;
    let ids = numb;
    dispatch({ type: "CHAT_ID", payload: c, noScroll: true });
    if (props.chats.filter((s)=>s.id===c.id)[0]?.messages?.filter((one) =>
      one.id === ids
    ).length > 0) {
      
      setTimeout(() => {
        document.querySelector("#s" + ids)?.scrollIntoView({ block: "center", inline: "end" })

      }, 1500);
    } else {
      dispatch({ type: "GET_MES", qid: props.chats.filter((s)=>s.id===c.id)[0]?.messages[0]?.id, sid: ids, cid: c.id })
    }


  }
  return (
    <aside className="sidebar">
      <header className="header">

        <div className="sidebar__avatar-wrapper">
          <div className="avatar-circle">Vision</div>
        </div>
        <div className="contact-icon" onClick={() => setOpen(true)}>
          <svg id="_20X20_photo_Back" data-name="20X20 photo Back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20">
            <g id="Mask_Group_311" data-name="Mask Group 311">
              <g id="notebook-of-contacts">
                <path id="Path_21348" data-name="Path 21348" d="M4.769,10A.769.769,0,0,1,4,10.769H2.462A.769.769,0,0,1,1.692,10h0a.769.769,0,0,1,.769-.769H4A.769.769,0,0,1,4.769,10Zm0-4.923A.769.769,0,0,0,4,4.308H2.462a.769.769,0,0,0-.769.769h0a.769.769,0,0,0,.769.769H4a.769.769,0,0,0,.769-.769Zm0,9.846A.769.769,0,0,0,4,14.154H2.462a.769.769,0,0,0-.769.769h0a.769.769,0,0,0,.769.769H4a.769.769,0,0,0,.769-.769ZM18.308,2.462V17.538A2.462,2.462,0,0,1,15.846,20H5.077a2.462,2.462,0,0,1-2.462-2.462V16.308H4a1.385,1.385,0,0,0,0-2.769H2.615V11.385H4A1.385,1.385,0,1,0,4,8.615H2.615V6.461H4A1.385,1.385,0,1,0,4,3.692H2.615V2.462A2.462,2.462,0,0,1,5.077,0H15.846A2.462,2.462,0,0,1,18.308,2.462ZM8.55,7.3a2.374,2.374,0,1,0,2.374-2.374A2.374,2.374,0,0,0,8.55,7.3Zm6.066,5.8a2.637,2.637,0,0,0-2.637-2.637H9.868A2.638,2.638,0,0,0,7.231,13.1v1.055h7.385Z" fill="#5d5d5d" />
              </g>
            </g>
          </svg>
        </div>
        <div className="searchs-wrap">
          <SearchComponent svg={searchIcon} onChange={(e) => setSearch(e.target.value)} value={search} placeholder="Search In Table" />
          <SearchComponent svg={edit} onChange={(e) => setSearchConv(e.target.value)} value={searchConv} placeholder="Start New Chat" />
        </div>
        <div className="filter-icon" onClick={() => { showUnread() }}>
          <svg data-src={filter}></svg>
        </div>
      </header>
      {chatsArray?.length > 0 && <ChatUsers chats={chatsArray} />}
      <div className="sidebar__contacts" ref={parents}>

        {<Alert chatG={chatsArray.filter((s) => s.channel_type?.slug === "team")[0]} noAdmin={false} noGroup={false} openChat={() => {
          chatsArray.filter((s) => s.channel_type?.slug === "team").length > 0 &&
            dispatch({ type: "CHAT_ID", payload: null });
          dispatch({ type: "OPEN-CHAT", payload: chatsArray.filter((s) => s?.channel_type.slug === "team")[0] })
          dispatch({ type: "WATCH_CHANNEL_RED", payload: chatsArray.filter((s) => s?.channel_type.slug === "team")[0].id })
        }} chat={props.chats?.filter((s) => s.is_admin === 1)[0]} name={props.chats?.filter((s) => s.is_admin === 1)[0]?.whatsapp_contact?.name ? props?.chats?.filter((s) => s.is_admin === 1)[0]?.whatsapp_contact?.name : "+" + props.chats.filter((s) => s.is_admin === 1)[0]?.id_from_whatsapp?.split("@")[0]} new={getNew()} onClick={() => openAdmin()} />}
        {bool.current && news.current.map((contact, index) => (
          <Contact
            onClick={() => { handleSetUser(contact, contact?.id); setSearch(""); bool.current = false; news.current = []; }}
            key={index}
            contact={contact}
          />
        ))}
        {props.chats.length > 0 && search.length > 0 && founds?.length > 0 && founds.map((ch) => {
          return (
            <>
              {ch.messages.filter((cha) => cha?.body?.toLowerCase()?.includes(search.toLowerCase())).map((mes) => {
                return (
                  <>
                    {mes.body && <div className="sidebar-contact" onClick={() => goToMessage(mes, ch)}>
                      <div className="sidebar-contact__avatar-wrapper">
                        {ch?.isGroup && ch.isGroup === 1 ? (
                          <svg className="avatar" data-src={grop}></svg>
                        ) : (
                          <img
                            src={(mes?.fromMe !== 1) ? props.chats?.filter((sid) => sid.id === mes.whatsapp_chat_id)[0]?.whatsapp_contact?.profile_photo ? props.chats?.filter((sid) => sid.id === mes.whatsapp_chat_id)[0]?.whatsapp_contact?.profile_photo : ProfileNo : ProfileNo}
                            alt={""}
                            className="avatar"
                          />
                        )}
                      </div>
                      <div className="sidebar-contact__content" style={{ justifyContent: "flex-start", textAlign: "start" }}>
                        <div className="sidebar-contact__topcontent">
                          <h2 className="sidebar-contact__name">
                            {mes?.fromMe === 1
                              ? "You"
                              : mes.whatsapp_chat_id ? (props.chats?.filter((sid) => sid.id === mes.whatsapp_chat_id)[0]?.whatsapp_contact?.name || props.chats?.filter((sid) => sid.id === mes.whatsapp_chat_id)[0]?.whatsapp_contact?.pushname) : contacts.filter((s) => s.id === mes?.received_from_contact_id)[0]?.name
                                ? contacts.filter((s) => s.id === mes?.received_from_contact_id)[0]?.name ?? contacts.filter((s) => s.id === mes?.received_from_contact_id)[0]?.pushname :
                                ch.name ? ch.name : "+" + contacts.filter((s) => s.id === mes?.received_from_contact_id)[0]?.number}
                          </h2>

                        </div>
                        <div className="sidebar-contact__bottom-content" style={{ justifyContent: "flex-start" }}>
                          {mes?.ack && (
                            <Icon
                              id={
                                mes?.ack === "device"
                                  ? "singleTick"
                                  : "doubleTick"
                              }
                              aria-label={mes?.ack}
                              className={`sidebar-contact__message-icon ${mes?.ack === "read"
                                ? "sidebar-contact__message-icon--blue"
                                : ""
                                }`}
                            />
                          )}
                          {mes?.body}</div>
                      </div>
                    </div>}
                  </>
                )
              })}
            </>

          )
        })}
        {/* here we dont need that just make backend search by contacts for you */}

        {/* {props?.chats?.length > 0 && searchConv.length === 0 && search.length > 0 &&
          props?.chats?.filter((w) => w.id !== props?.chats[0]?.id).filter((ch) => isSearchFor(ch)).filter((x) => news.current.filter((nx) => nx.id === x.id).length === 0).map((contact, index) => (
            <Contact
              onClick={() => { handleSetUser(contact, contact?.id); setSearch(""); bool.current = false; news.current = [] }}
              key={index}
              contact={contact}
            />
          ))} */}

        {props?.chats?.length > 0 && search.length === 0 &&
          props?.chats?.filter((w) => w.id !== props.chats.filter((s) => s.is_admin === 1)[0]?.id).filter((ch) => isSearchForC(ch)).filter((x) => news.current.filter((nx) => nx.id === x.id).length === 0).map((contact, index) => (
            <Contact
              onClick={() => { handleSetUser(contact, contact?.id); setSearch(""); bool.current = false; news.current = [] }}
              key={index}
              contact={contact}
            />
          ))}
        {searchConv.includes("+") && props?.chats?.filter((ch) => isSearchForC(ch)).filter((x) => news.current.filter((nx) => nx.id === x.id).length === 0).length === 0 && (
          contacts.filter((s) => s.number.includes(searchConv.split("+")[1])).length > 0 ?
            <> {contacts.filter((s) => s.number.includes(searchConv.split("+")[1])).map((contact) => {
              return (
                <Contact newChat={false}
                  onClick={() => { handleSetUser({ isNew: true, id_from_whatsapp: contact.number + "@c.us+905525460000", id: contact.number + "@c.us+905525460000", number: contact.number, name: contact.name, messages: [], whatsapp_contact: contact }, null); setSearchConv(""); bool.current = false; news.current = []; }}
                  contact={{ id_from_whatsapp: "+" + contact.number + "@c.us+905525460000", id: contact.number + "@c.us+905525460000", name: searchConv, number: contact.number, messages: [], whatsapp_contact: contact }} />
              )
            }
            )}</>
            : <Contact newChat={true}
              onClick={() => { handleSetUser({ isNew: true, id_from_whatsapp: searchConv.split("+")[1] + "@c.us+905525460000", id: searchConv + "@c.us+905525460000", number: searchConv, name: searchConv, messages: [], whatsapp_contact: { id: "ms", number: searchConv, name: searchConv, whatsapp_contact_company_id: null, whatsapp_contact_role_id: null } }, null); setSearchConv(""); bool.current = false; news.current = []; }}
              contact={{ id_from_whatsapp: searchConv + "@c.us+905525460000", id: searchConv + "@c.us+905525460000", name: searchConv, number: searchConv, messages: [], whatsapp_contact: { id: "ms", number: searchConv, name: searchConv, whatsapp_contact_company_id: null, whatsapp_contact_role_id: null } }} />
        )}
        {(search.length > 0 || searchConv.length > 0) && users.map((s) => (
          <Contact
            onClick={() => { handleChat(s) }}
            contact={{ name: s.full_name ? s.full_name : s.username, profile_photo: FILE_SERVER + s.avatar, messages: [] }} />
        ))}
      </div>
    </aside>
  );
};

export default Sidebar;
