import React, { useEffect, useState } from "react";
import InputText from "./inputs/InputText";
import X from "../Languages/svgs/xicon.svg";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import acc from "./inputs/acc.svg";
import mes from "./inputs/mes.svg";
import minus from "./inputs/minus.svg";
import plus from "./inputs/plus.svg";

import call from "./inputs/call.svg";
import acall from "./inputs/acall.svg";
import dcall from "./inputs/dcall.svg";
import phone from "./inputs/phone.svg";
import cal from "./inputs/cal.svg";
import calb from "./inputs/calb.svg";
import dol from "./inputs/dol.svg";
import amm from "./inputs/amm.svg";
import bank from "./inputs/bank.svg";
import zain from "./inputs/zain.svg";
import share from "./inputs/share.svg";
import { textMarshal } from "text-marshal";
import office from "./inputs/office.svg";
import { getReg, getUser } from "../../../../constants";
import Spinner from "../../Spinner";
function TransferModal(props) {
  const [sourceFocuse, setSourceFocus] = useState(false);
  const countries = useSelector((state) => state.transfer.countries);
  const clients = useSelector((state) => state.transfer.clients);
  const client_load = useSelector((state) => state.transfer.client_load);

  const [nameFocuse, setNameFocuse] = useState(false);

  const getPhone = (a) => {
    let d = textMarshal({
      input: a,
      template: `+xx   xxx   xxxxxxxx`,
      disallowCharacters: [/[a-z]/],
    });
    return d.marshaltext;
  };
  const getAmount = (a) => {
    let d = textMarshal({
      input: a,
      template: `xxx,xxx.xx`,
      disallowCharacters: [/[a-z]/],
    });
    return d.marshaltext;
  };
  const languages = useSelector((state) => state.transfer.languages);
  const mode = useSelector((state) => state.transfer.mode);
  const loading = useSelector((state) => state.transfer.loading);
  const img_upload = useSelector((state) => state.transfer.img_upload);
  const data = props.data;
  const ic = data.language_code;
  const shake = (e) => {
    document.querySelector(".lang-modal.open").classList.add("shake-modal");
    setTimeout(() => {
      document
        .querySelector(".lang-modal.open")
        .classList.remove("shake-modal");
    }, 400);
  };
  const {
    open,
    setOpen,
    setExpand,
    setopenAccent,
    setLang,
    updateCode,
    langcode,
    lang,
  } = props;
  const dispatch = useDispatch();
  const ButtonValidate = () => {
    let bool = false,
      phones = false;

    if (data.beneficiary_contacts.filter((a) => a.number.length < 9).length > 0)
      phones = false;
    else phones = true;
    if (
      data.country_id &&
      data.client.id &&
      data.request_type &&
      data.transaction_payment_type_id &&
      data.commission_type_id &&
      data.amount_of_money &&
      data.amount_of_money.length > 0 &&
      data.source &&
      data.country_id
    ) {
      bool = true;
    } else {
      bool = false;
    }
    return bool && phones;
  };

  var pendingClick;
  useEffect(() => {
    if (loading && mode !== "read") {
      document.querySelectorAll("input").forEach((e) => {
        e.readOnly = true;
      });
    } else {
      if (mode !== "read") {
        document.querySelectorAll("input").forEach((e) => {
          e.readOnly = false;
        });
      }
    }
  }, [loading]);
  const sendRequest = () => {
    if (ButtonValidate()) {
      if (mode === "add") {
        dispatch({ type: "ADD-TRF", payload: data });
      } else {
        dispatch({ type: "UPD-TRF", payload: data });
      }
    } else {
      document.querySelectorAll(".modal-body input").forEach((inp) => {
        if (inp.type !== "date" && inp.value.length < 2) {
          inp.parentElement.classList.add("shake-modal");
          inp.classList.add("red-bord");
          setTimeout(() => {
            inp.parentElement.classList.remove("shake-modal");
          }, 400);
          setTimeout(() => {
            inp.classList.remove("red-bord");
          }, 1000);
        }
      });
      if (!data.request_type) {
        let a = document.querySelector(".trf.modal-icons");
        a.classList.add("shake-modal");

        setTimeout(() => {
          a.classList.remove("shake-modal");
        }, 400);
      }
    }
    if (!data.commission_type_id) {
      let a = document.querySelector(".comm-type .input-holder");
      a.classList.add("shake-modal");
      a.classList.add("red-bord");

      setTimeout(() => {
        a.classList.remove("shake-modal");
      }, 400);
      setTimeout(() => {
        a.classList.remove("red-bord");
      }, 1000);
    }
    if (!data.transaction_payment_type_idt_type_idt_type_id) {
      let a = document.querySelector(".payment-type .input-holder");
      a.classList.add("shake-modal");
      a.classList.add("red-bord");

      setTimeout(() => {
        a.classList.remove("shake-modal");
      }, 400);
      setTimeout(() => {
        a.classList.remove("red-bord");
      }, 1000);
    }
    if (!data.data) {
      let a = document.querySelector("svg.cal");
      a.classList.add("shake-modal");
      a.classList.add("red-bord");

      setTimeout(() => {
        a.classList.remove("shake-modal");
      }, 400);
      setTimeout(() => {
        a.classList.remove("red-bord");
      }, 1000);
    }
  };

  return (
    <>
      {mode !== "read" && (
        <div
          onClick={(e) => shake(e)}
          className={`lang-modalDisable ${open && "open"}`}
        ></div>
      )}
      <div
        style={{ height: "844px" }}
        className={`lang-modal trf ${open && "open"} ${props.fixModal}`}
      >
        <div className="modal-top">
          <div className="modal-top-title">
            {
              <svg
                className="no-dis"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="15"
                height="15"
                viewBox="0 0 15 15"
              >
                <g
                  id="Mask_Group_16"
                  data-name="Mask Group 16"
                  transform="translate(-324 -333)"
                >
                  <path
                    id="add-3"
                    d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z"
                    transform="translate(324 333)"
                    fill="#404040"
                  />
                </g>
              </svg>
            }
            {/* ATTENTION!! ADDING CONDITION NEXT LINE */}

            {mode === "add" ? (
              <div className="modal-title">
                {" "}
                {`New ${
                  props.languageFollower.length === 0
                    ? " Transfer Request"
                    : "Transfer Request"
                }`}
              </div>
            ) : (
              <div className="modal-title">
                {mode === "update" ? "Update" : "Transfer"}{" "}
                {props.languageFollower.length === 0 ||
                (props.languageFollower.length === 1 && mode !== "add")
                  ? `Transfer Request`
                  : `Transfer Request`}
              </div>
            )}
          </div>
          {mode !== "read" ? (
            <div className="modal-top-buttons">
              <div
                className={`add-button drafts ${
                  !ButtonValidate() && "disabled-button"
                }`}
                onClick={() => {
                  sendRequest();
                }}
              >
                {"save Draft"}
              </div>
              <div
                className="close-button"
                onClick={() => {
                  if (!loading) {
                    setOpen(false);
                    props.Close();
                  }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.9"
                  height="9.899"
                  viewBox="0 0 9.9 9.899"
                >
                  <g
                    id="Group_4637"
                    data-name="Group 4637"
                    transform="translate(-224.153 -513.36) rotate(45)"
                  >
                    <line
                      id="Line_962"
                      data-name="Line 962"
                      x1="12"
                      transform="translate(522.5 204.5)"
                      fill="none"
                      stroke="#ff2e2e"
                      strokeLinecap="round"
                      strokeWidth="1"
                    />
                    <line
                      id="Line_963"
                      data-name="Line 963"
                      x1="12"
                      transform="translate(528.5 198.5) rotate(90)"
                      fill="none"
                      stroke="#ff2e2e"
                      strokeLinecap="round"
                      strokeWidth="1"
                    />
                  </g>
                </svg>
              </div>
            </div>
          ) : (
            <div className="modal-top-buttons">
              {/* <div className='icon-lang marg' onClick={()=>{props.Add(data); props.ActiveAdd(); setExpand(true); }}>
             <div className='icon-lang-desc'>
             <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
             <defs>
               <filter id="Ellipse_319" x="0" y="0"  width="24" height="24" filterUnits="userSpaceOnUse">
                 <feOffset dy="3" input="SourceAlpha"/>
                 <feGaussianBlur stdDeviation="3" result="blur"/>
                 <feFlood floodOpacity="0.2"/>
                 <feComposite operator="in" in2="blur"/>
                 <feComposite in="SourceGraphic"/>
               </filter>
             </defs>
             <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Ellipse_319)">
               <circle id="Ellipse_319-2" data-name="Ellipse 319" cx="3" cy="3" r="3" transform="translate(9 6)" fill="#404040"/>
             </g>
           </svg>
         <div className='icon-lang-desc-bu' style={{whiteSpace:"nowrap",width:"auto",padding:"7px",left:"-37px"}}>Add {" Accent"}</div>
             </div>
           <svg className='gray-color' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
           <g id="Mask_Group_16" data-name="Mask Group 16" transform="translate(-324 -333)" clip-path="url(#clip-path)">
             <path id="add-3" d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z" transform="translate(324 333)" fill="#8e8e8e"/>
           </g>
         </svg>
           </div> */}
              <div
                className="icon-lang marg"
                onClick={() => {
                  props.Update(props.data);
                }}
              >
                <div className="icon-lang-desc">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <defs>
                      <filter
                        id="Ellipse_319"
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset dy="3" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="3" result="blur" />
                        <feFlood floodOpacity="0.2" />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                    </defs>
                    <g
                      transform="matrix(1, 0, 0, 1, 0, 0)"
                      filter="url(#Ellipse_319)"
                    >
                      <circle
                        id="Ellipse_319-2"
                        data-name="Ellipse 319"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(9 6)"
                        fill="#404040"
                      />
                    </g>
                  </svg>
                  {/* ATTENTION!! ADDING CONDITION NEXT LINE */}
                  <div
                    className="icon-lang-desc-bu"
                    style={{ whiteSpace: "nowrap", left: "-24px" }}
                  >
                    Edit
                  </div>
                </div>
                <svg
                  className="gray-color"
                  id="_15x15_photo_back"
                  data-name="15x15 photo back"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                >
                  <g data-name="Mask Group 65">
                    <g
                      id="Group_4666"
                      data-name="Group 4666"
                      transform="translate(0.063 0)"
                    >
                      <g id="user" transform="translate(0.258)">
                        <path
                          id="Path_16021"
                          data-name="Path 16021"
                          d="M13.436,24.162l1.583-.366L13.8,22.579Z"
                          transform="translate(-6.428 -9.162)"
                          fill="#8e8e8e"
                        />
                        <path
                          id="Path_16022"
                          data-name="Path 16022"
                          d="M9.213,18.943l.262-1.136v-.047l.006-.014v-.009l.008-.014v-.008L9.5,17.7l.006-.008.011-.012h0l3.531-3.531a5.421,5.421,0,0,0-4.867-3.239A5.239,5.239,0,0,0,4.19,12.762a7.346,7.346,0,0,0-1.668,4.726A13.977,13.977,0,0,0,8.178,19a10.04,10.04,0,0,0,1.035-.055Z"
                          transform="translate(-2.521 -4.985)"
                          fill="#8e8e8e"
                        />
                        <path
                          id="Path_16023"
                          data-name="Path 16023"
                          d="M0,0H5.927V2.156H0Z"
                          transform="translate(7.595 12.882) rotate(-45)"
                          fill="#8e8e8e"
                        />
                        <circle
                          id="Ellipse_318"
                          data-name="Ellipse 318"
                          cx="2.694"
                          cy="2.694"
                          r="2.694"
                          transform="translate(2.963)"
                          fill="#8e8e8e"
                        />
                        <path
                          id="Path_16024"
                          data-name="Path 16024"
                          d="M23.32,15.209a1.078,1.078,0,0,0-1.84-.762L23,15.966A1.07,1.07,0,0,0,23.32,15.209Z"
                          transform="translate(-9.309 -6.137)"
                          fill="#8e8e8e"
                        />
                      </g>
                      <rect
                        id="Rectangle_4536"
                        data-name="Rectangle 4536"
                        width="14.875"
                        height="14.875"
                        transform="translate(0 0.125)"
                        fill="none"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div
                className="close-button"
                onClick={() => {
                  props.Close();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.9"
                  height="9.899"
                  viewBox="0 0 9.9 9.899"
                >
                  <g
                    id="Group_4637"
                    data-name="Group 4637"
                    transform="translate(-224.153 -513.36) rotate(45)"
                  >
                    <line
                      id="Line_962"
                      data-name="Line 962"
                      x1="12"
                      transform="translate(522.5 204.5)"
                      fill="none"
                      stroke="#ff2e2e"
                      strokeLinecap="round"
                      strokeWidth="1"
                    />
                    <line
                      id="Line_963"
                      data-name="Line 963"
                      x1="12"
                      transform="translate(528.5 198.5) rotate(90)"
                      fill="none"
                      stroke="#ff2e2e"
                      strokeLinecap="round"
                      strokeWidth="1"
                    />
                  </g>
                </svg>
              </div>
            </div>
          )}
        </div>
        <div className="modal-body">
          <div className="modal-icon-section">
            <div className="modal-icon-name">Request Type</div>
            <div className="modal-icons trf">
              <div
                onClick={() =>
                  dispatch({
                    type: "NEW-TRF",
                    payload: { ...data, request_type: 1 },
                  })
                }
                className="modal-icon mar cityflag"
              >
                <svg
                  id="Group_7851"
                  data-name="Group 7851"
                  xmlns="http://www.w3.org/2000/svg"
                  width="70"
                  height="50"
                  viewBox="0 0 70 50"
                >
                  <g
                    id="Rectangle_4641"
                    data-name="Rectangle 4641"
                    fill="#f2fff7"
                    stroke="#5d5d5d"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    strokeWidth={data.request_type === 1 ? "0.5" : "0"}
                  >
                    <rect width="70" height="50" rx="10" stroke="none" />
                    <rect
                      x="0.25"
                      y="0.25"
                      width="69.5"
                      height="49.5"
                      rx="9.75"
                      fill="none"
                    />
                  </g>
                  <path
                    id="Path_19065"
                    data-name="Path 19065"
                    d="M1.67,1.568H4.8c3.4,0,5.4-2.115,5.4-5.72s-2-5.712-5.4-5.712H1.67a.676.676,0,0,0-.713.737V.831A.676.676,0,0,0,1.67,1.568ZM2.383.284V-8.581H4.7C7.3-8.581,8.737-7,8.737-4.136,8.737-1.3,7.295.284,4.7.284Z"
                    transform="translate(29.043 28.864)"
                    fill="#404040"
                  />
                  <path
                    id="Path_19071"
                    data-name="Path 19071"
                    d="M126.136,64.906a12.99,12.99,0,0,1-6.717-1.869.213.213,0,0,1,.22-.364,12.78,12.78,0,0,0,15.545-20.035,13.717,13.717,0,0,0-1.931-1.583.213.213,0,0,1,.233-.356,14.14,14.14,0,0,1,2,1.635,13.231,13.231,0,0,1-9.345,22.572Zm0,0"
                    transform="translate(-92.33 -28.227)"
                    fill="#555"
                  />
                  <path
                    id="Path_19073"
                    data-name="Path 19073"
                    d="M380.717,42.172a.213.213,0,0,1-.213-.213v-2.1a.211.211,0,0,1,.064-.152.226.226,0,0,1,.154-.061l2.348.054a.213.213,0,0,1,.208.218.217.217,0,0,1-.218.208l-2.13-.049v1.884A.213.213,0,0,1,380.717,42.172Zm0,0"
                    transform="translate(-339.623 -27.262)"
                    fill="#555"
                  />
                </svg>
                {parseInt(data.request_type) === 1 && (
                  <div className="req_type">Deposit</div>
                )}
              </div>
              <div
                onClick={() =>
                  dispatch({
                    type: "NEW-TRF",
                    payload: { ...data, request_type: 2 },
                  })
                }
                className="modal-icon mar cityflag"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="70"
                  height="50"
                  viewBox="0 0 70 50"
                >
                  <g
                    id="Group_8008"
                    data-name="Group 8008"
                    transform="translate(-987.5 -251)"
                  >
                    <g id="Group_8007" data-name="Group 8007">
                      <g
                        id="Rectangle_4691"
                        data-name="Rectangle 4691"
                        transform="translate(987.5 251)"
                        fill="#fff8f2"
                        stroke="#707070"
                        strokeLinecap="round"
                        stroke-linejoin="round"
                        strokeWidth={data.request_type === 2 ? "0.5" : "0"}
                      >
                        <rect width="70" height="50" rx="10" stroke="none" />
                        <rect
                          x="0.25"
                          y="0.25"
                          width="69.5"
                          height="49.5"
                          rx="9.75"
                          fill="none"
                        />
                      </g>
                    </g>
                    <path
                      id="Path_19067"
                      data-name="Path 19067"
                      d="M3.883,4.317a.764.764,0,0,0,.808-.661L7.14-4.69h.078L9.665,3.657a.764.764,0,0,0,.808.661c.42,0,.653-.21.785-.668L14-6.088a1.328,1.328,0,0,0,.062-.389.64.64,0,0,0-.692-.637.67.67,0,0,0-.692.56l-2.207,8.7h-.062L8.041-6.36c-.148-.528-.4-.754-.855-.754-.474,0-.723.225-.878.785L3.945,2.149H3.883l-2.207-8.7a.672.672,0,0,0-.692-.56.64.64,0,0,0-.692.637,1.328,1.328,0,0,0,.062.389L3.1,3.649C3.231,4.108,3.464,4.317,3.883,4.317Z"
                      transform="translate(1015.133 277.113)"
                      fill="#404040"
                    />
                    <path
                      id="Path_19077"
                      data-name="Path 19077"
                      d="M4.2,22.6a.212.212,0,0,1-.15-.062A13.205,13.205,0,0,1,17.113.533a.213.213,0,0,1-.119.409,12.78,12.78,0,0,0-12.647,21.3.213.213,0,0,1-.15.363Zm0,0"
                      transform="translate(1009.086 263.383)"
                      fill="#555"
                    />
                    <path
                      id="Path_19078"
                      data-name="Path 19078"
                      d="M29.048,374.039h0l-2.4-.027a.213.213,0,0,1,0-.426h0l2.185.025-.025-2.212a.213.213,0,0,1,.21-.215h0a.213.213,0,0,1,.213.21l.027,2.43a.213.213,0,0,1-.213.215Zm0,0"
                      transform="translate(984.246 -88.055)"
                      fill="#555"
                    />
                  </g>
                </svg>

                {data.request_type === 2 && (
                  <div className="req_type">Withdraw</div>
                )}
              </div>
              <div
                onClick={() =>
                  dispatch({
                    type: "NEW-TRF",
                    payload: { ...data, request_type: 3 },
                  })
                }
                className="modal-icon mar cityflag"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="70"
                  height="50"
                  viewBox="0 0 70 50"
                >
                  <g
                    id="Group_8009"
                    data-name="Group 8009"
                    transform="translate(-1067 -251)"
                  >
                    <g
                      id="Rectangle_4692"
                      data-name="Rectangle 4692"
                      transform="translate(1067 251)"
                      fill="#f2fbff"
                      stroke="#707070"
                      strokeLinecap="round"
                      stroke-linejoin="round"
                      strokeWidth={data.request_type === 3 ? "0.5" : "0"}
                    >
                      <rect width="70" height="50" rx="10" stroke="none" />
                      <rect
                        x="0.25"
                        y="0.25"
                        width="69.5"
                        height="49.5"
                        rx="9.75"
                        fill="none"
                      />
                    </g>
                    <path
                      id="Path_19068"
                      data-name="Path 19068"
                      d="M4.464,4.125a.654.654,0,0,0,.69-.713V-5.8H8.1a.61.61,0,0,0,.659-.621A.61.61,0,0,0,8.1-7.046H.83a.61.61,0,0,0-.659.621A.61.61,0,0,0,.83-5.8H3.774V3.412A.654.654,0,0,0,4.464,4.125Z"
                      transform="translate(1097.828 277.047)"
                      fill="#404040"
                    />
                    <g
                      id="Group_7778"
                      data-name="Group 7778"
                      transform="translate(1105.287 262.901) rotate(45)"
                    >
                      <path
                        id="Path_19079"
                        data-name="Path 19079"
                        d="M6.82,24.242A12.99,12.99,0,0,1,.1,22.373a.213.213,0,0,1,.22-.364A12.78,12.78,0,0,0,15.868,1.974,13.717,13.717,0,0,0,13.936.391a.213.213,0,0,1,.233-.356,14.14,14.14,0,0,1,2,1.635A13.231,13.231,0,0,1,6.82,24.242Zm0,0"
                        transform="translate(0 0.054)"
                        fill="#555"
                      />
                      <path
                        id="Path_19082"
                        data-name="Path 19082"
                        d="M.213,2.528A.213.213,0,0,1,0,2.315V.213A.211.211,0,0,1,.064.061.226.226,0,0,1,.218,0L2.565.054a.213.213,0,0,1,.208.218A.217.217,0,0,1,2.556.48L.426.431V2.315A.213.213,0,0,1,.213,2.528Zm0,0"
                        transform="translate(13.894 0)"
                        fill="#555"
                      />
                    </g>
                  </g>
                </svg>

                {data.request_type === 3 && (
                  <div className="req_type">Transfer</div>
                )}
              </div>
              <div
                onClick={() =>
                  dispatch({
                    type: "NEW-TRF",
                    payload: { ...data, request_type: 4 },
                  })
                }
                className="modal-icon mar cityflag"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="70"
                  height="50"
                  viewBox="0 0 70 50"
                >
                  <g
                    id="Group_8010"
                    data-name="Group 8010"
                    transform="translate(-1146.5 -251)"
                  >
                    <g
                      id="Rectangle_4693"
                      data-name="Rectangle 4693"
                      transform="translate(1146.5 251)"
                      fill="#fff2f2"
                      stroke="#707070"
                      strokeLinecap="round"
                      stroke-linejoin="round"
                      strokeWidth={data.request_type === 4 ? "0.5" : "0"}
                    >
                      <rect width="70" height="50" rx="10" stroke="none" />
                      <rect
                        x="0.25"
                        y="0.25"
                        width="69.5"
                        height="49.5"
                        rx="9.75"
                        fill="none"
                      />
                    </g>
                    <path
                      id="Path_19069"
                      data-name="Path 19069"
                      d="M4.463,4.269C6.94,4.269,8.55,2.988,8.55.941c0-1.564-.912-2.484-3.22-2.99L4.1-2.317c-1.51-.33-2.1-.928-2.1-1.8,0-1.127,1.066-1.8,2.446-1.8A2.382,2.382,0,0,1,6.94-4.311a.685.685,0,0,0,.69.537A.594.594,0,0,0,8.258-4.4,1.34,1.34,0,0,0,8.22-4.74,3.6,3.6,0,0,0,4.486-7.163c-2.292,0-3.9,1.28-3.9,3.105,0,1.572.958,2.576,3.174,3.059L4.985-.73C6.518-.393,7.131.22,7.131,1.148c0,1.089-1.089,1.878-2.591,1.878-1.4,0-2.484-.537-2.821-1.679A.648.648,0,0,0,1.051.8a.631.631,0,0,0-.636.713A1.718,1.718,0,0,0,.492,2C.906,3.333,2.385,4.269,4.463,4.269Z"
                      transform="translate(1177.166 277.164)"
                      fill="#404040"
                    />
                    <path
                      id="Path_19083"
                      data-name="Path 19083"
                      d="M4.2,22.6a.212.212,0,0,1-.15-.062A13.205,13.205,0,0,1,17.113.533a.213.213,0,0,1-.119.409,12.78,12.78,0,0,0-12.647,21.3.213.213,0,0,1-.15.363Zm0,0"
                      transform="translate(1168.295 263.383)"
                      fill="#555"
                    />
                    <path
                      id="Path_19084"
                      data-name="Path 19084"
                      d="M126.136,64.906a12.99,12.99,0,0,1-6.717-1.869.213.213,0,0,1,.22-.364,12.78,12.78,0,0,0,15.545-20.035,13.717,13.717,0,0,0-1.931-1.583.213.213,0,0,1,.233-.356,14.14,14.14,0,0,1,2,1.635,13.231,13.231,0,0,1-9.345,22.572Zm0,0"
                      transform="translate(1055.457 224.883)"
                      fill="#555"
                    />
                    <path
                      id="Path_19085"
                      data-name="Path 19085"
                      d="M29.048,374.039h0l-2.4-.027a.213.213,0,0,1,0-.426h0l2.185.025-.025-2.212a.213.213,0,0,1,.21-.215h0a.213.213,0,0,1,.213.21l.027,2.43a.213.213,0,0,1-.213.215Zm0,0"
                      transform="translate(1143.488 -88.055)"
                      fill="#555"
                    />
                    <path
                      id="Path_19086"
                      data-name="Path 19086"
                      d="M380.717,42.172a.213.213,0,0,1-.213-.213v-2.1a.211.211,0,0,1,.064-.152.226.226,0,0,1,.154-.061l2.348.054a.213.213,0,0,1,.208.218.217.217,0,0,1-.218.208l-2.13-.049v1.884A.213.213,0,0,1,380.717,42.172Zm0,0"
                      transform="translate(808.154 225.848)"
                      fill="#555"
                    />
                  </g>
                </svg>

                {data.request_type === 4 && (
                  <div className="req_type">Stock</div>
                )}
              </div>
            </div>
          </div>
          <div className="modal-icon-section" style={{ marginTop: "18px" }}>
            <div className="modal-icon-name">
              <svg
                id="_15x15_photo_back"
                data-name="15x15 photo back"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="15"
                height="15"
                viewBox="0 0 15 15"
              >
                <g id="Mask_Group_208" data-name="Mask Group 208">
                  <path
                    id="location-pin"
                    d="M7.5,0A5.652,5.652,0,0,0,1.875,5.666c0,4.439,5.1,9.023,5.313,9.216a.47.47,0,0,0,.624,0c.217-.193,5.313-4.777,5.313-9.216A5.652,5.652,0,0,0,7.5,0Zm0,8.75a3.125,3.125,0,1,1,3.125-3.125A3.129,3.129,0,0,1,7.5,8.75Z"
                    fill="#5d5d5d"
                  />
                </g>
              </svg>

              <div className="modal-name-icon">Source country_id</div>
            </div>
          </div>
          <div className="input-section">
            <div className={"input-holder" + ` ${data.country_id && "pad48"}`}>
              {data.country_id && (
                <svg
                  width={"23"}
                  height="15"
                  className="data-country_id"
                  data-src={
                    countries.filter((a) => a.id === data.country_id)[0].flag
                  }
                />
              )}
              <InputText
                setFocuse={(a) => setSourceFocus(a)}
                loca
                placeholder="country_id / City / State"
                setDate={(e) =>
                  dispatch({
                    type: "NEW-TRF",
                    payload: { ...data, date: e.target.value },
                  })
                }
                xIcon={data.date ? calb : cal}
                pad
                noFloat
                lang={data.source ? data.source : ""}
                clear={() =>
                  dispatch({
                    type: "NEW-TRF",
                    payload: { ...data, source: "" },
                  })
                }
                setLang={(e) => {
                  dispatch({
                    type: "NEW-TRF",
                    payload: {
                      ...data,
                      source: e.target.value,
                      country_id: null,
                    },
                  });
                }}
              />
              {data.source && data.source.length > 0 && sourceFocuse && (
                <div className="input-sync">
                  {getReg(countries, data.source).map((a) => (
                    <div
                      className="input-sync-item"
                      onClick={() =>
                        dispatch({
                          type: "NEW-TRF",
                          payload: {
                            ...data,
                            // source: a.name,
                            // country_id: a.icon,
                            country_id: a.id,
                            source: a.translations.filter(
                              (a) => a.language_code === "en"
                            )[0].name,
                          },
                        })
                      }
                    >
                      <svg
                        width={"23"}
                        height="15"
                        data-src={
                          process.env.REACT_APP_BASE_FILE_URL +
                          a.flag
                        }
                      ></svg>
                      <div className="input-sync-text">
                        {
                          a.translations.filter(
                            (a) => a.language_code === "en"
                          )[0].name
                        }
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          {data.date && (
            <>
              <div className="modal-icon-section" style={{ marginTop: "18px" }}>
                <div className="modal-icon-name">
                  <svg width={"15"} height="15" data-src={cal}></svg>

                  <div className="modal-name-icon">Date</div>
                </div>
              </div>
              <div className="input-section">
                <div className={"input-holder" + ``}>
                  <InputText
                    disabled={true}
                    setFocuse={(a) => setSourceFocus(a)}
                    loca
                    placeholder="Date"
                    setDate={(e) =>
                      dispatch({
                        type: "NEW-TRF",
                        payload: { ...data, date: e.target.value },
                      })
                    }
                    pad
                    noFloat
                    lang={data.date ? data.date : ""}
                    clear={() =>
                      dispatch({
                        type: "NEW-TRF",
                        payload: { ...data, date: "" },
                      })
                    }
                    setLang={(e) => {
                      dispatch({
                        type: "NEW-TRF",
                        payload: {
                          ...data,
                          source: e.target.value,
                          country_id: null,
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </>
          )}
          <div
            className="modal-icon-section trf-modal"
            style={{ marginTop: "18px" }}
          >
            <svg
              data-src={data.recipients.length > 0 ? minus : plus}
              className="plus-minus"
              onClick={() => {
                if (data.recipients && data.recipients.length === 0) {
                  dispatch({
                    type: "NEW-TRF",
                    payload: {
                      ...data,
                      recipients: [
                        {
                          name: "",
                          amount_of_money: data.amount_of_money,
                          beneficiary_contacts: [
                            {
                              number: "",
                              contact_method_id: 1,
                              is_agnet_number: true,
                            },
                          ],
                        },
                      ],
                    },
                  });
                } else {
                  dispatch({
                    type: "NEW-TRF",
                    payload: { ...data, recipients: [] },
                  });
                }
              }}
            ></svg>
            <div className="modal-icon-name">
              <svg
                id="_15x15_photo_back"
                data-name="15x15 photo back"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="15"
                height="15"
                viewBox="0 0 15 15"
              >
                <g id="Mask_Group_173" data-name="Mask Group 173">
                  <g
                    id="Group_13"
                    data-name="Group 13"
                    transform="translate(1.608 0)"
                  >
                    <path
                      id="Path_20"
                      data-name="Path 20"
                      d="M653,149.289c.4-.085.763-.413.823-1.141.049-.6-.1-.9-.329-1.058.625-2.552-1.1-3.052-1.1-3.052a2.59,2.59,0,0,0-3.809-.653,4.5,4.5,0,0,0-1.114.956,3.978,3.978,0,0,0-.852,2.665c-.308.115-.55.414-.489,1.148.063.761.459,1.085.878,1.148a3.044,3.044,0,0,0,5.993-.01Zm-3,1.874c-1.523,0-2.754-2.066-2.754-3.781,0-.23.007-.452.022-.654a5.225,5.225,0,0,0,4.263-1.571,5,5,0,0,1,1.214,2.17v.055c.01,1.713-1.216,3.783-2.739,3.783Z"
                      transform="translate(-644.076 -142.822)"
                      fill="#5d5d5d"
                    />
                    <path
                      id="Path_21"
                      data-name="Path 21"
                      d="M644.191,174.579l.177-.73a.426.426,0,0,1,.123-.211l-.053-.04-1.576-1.3-.959.23a3.481,3.481,0,0,0-2.767,3.328v2.066a.767.767,0,0,0,.794.731h4.059l.619-3.528a.43.43,0,0,1-.417-.54Z"
                      transform="translate(-639.136 -163.648)"
                      fill="#5d5d5d"
                    />
                    <path
                      id="Path_22"
                      data-name="Path 22"
                      d="M663.477,172.517l-.946-.23-1.573,1.3-.053.04a.427.427,0,0,1,.124.211l.177.73a.43.43,0,0,1-.417.531l.619,3.528h4.058a.737.737,0,0,0,.762-.731v-2.066a3.465,3.465,0,0,0-2.751-3.319Z"
                      transform="translate(-654.445 -163.648)"
                      fill="#5d5d5d"
                    />
                  </g>
                </g>
              </svg>
              <div className="modal-name-icon">Client</div>
            </div>
          </div>
          <div className="input-section">
            <div className={"input-holder" + ` ${data.client.id && "pad48"}`}>
              {data.client.id && !nameFocuse && (
                <span
                  style={{
                    left: `${data.client.name.length * 10 + 28}px`,
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                  className="currency-lael"
                >
                  ID <pre> </pre> {` ${data.client.id}`}
                </span>
              )}
              {data.client.photo && (
                <img
                  width={"20"}
                  height="20"
                  className="data-country_id"
                  src={data.client.photo}
                />
              )}
              <InputText
                setFocuse={(s) => setNameFocuse(s)}
                placeholder="Client Name / id"
                icon={acc}
                pad
                noFloat
                lang={data.client.name ? data.client.name : ""}
                clear={() =>
                  dispatch({
                    type: "NEW-TRF",
                    payload: { ...data, client: { name: "" }, name: "" },
                  })
                }
                setLang={(e) => {
                  dispatch({
                    type: "NEW-TRF",
                    payload: {
                      ...data,
                      client: { name: e.target.value },
                      name: e.target.value,
                    },
                  });
                  dispatch({
                    type: "CLIENT_ASYNC_SAGA",
                    payload: e.target.value,
                  });
                }}
              />
              {data.client.name &&
                data.client.name.length > 0 &&
                nameFocuse && (
                  <div className="input-sync">
                    {clients.map((a) => (
                      <div
                        className="input-sync-item"
                        onClick={() =>
                          dispatch({
                            type: "NEW-TRF",
                            payload: {
                              ...data,
                              client: a,
                              client_id: a.id,
                              name: a.name,
                            },
                          })
                        }
                      >
                        <img width={"20"} height="20" src={a.photo} />
                        <div className="input-sync-text">{a.name}</div>
                        <div
                          className="input-sync-text"
                          style={{ marginLeft: "12px" }}
                        >
                          ID
                        </div>
                        {
                          <div
                            className="input-sync-text"
                            style={{ marginLeft: "8px" }}
                          >
                            {a.id}
                          </div>
                        }
                      </div>
                    ))}
                    {client_load && (
                      <div className="input-sync-item spin-cont">
                        <Spinner />
                      </div>
                    )}
                  </div>
                )}
            </div>
          </div>
          {data.beneficiary_contacts.map((a, ind) => (
            <div className="input-section">
              <div className="input-holder">
                <div className="contact-method">
                  <svg className="sms-svg" data-src={call}></svg>

                  <svg className="sms-svg" data-src={mes}></svg>
                  <svg className="call-svg" data-src={acall}></svg>
                  <svg className="call-svg" data-src={dcall}></svg>
                </div>
                <InputText
                  clear={() => {
                    let d = data.beneficiary_contacts;
                    let arr = [];
                    if (a.number.length > 0) {
                      d.map((asd, inds) => {
                        if (inds === ind)
                          arr.push({
                            number: "",
                            contact_method_id: 1,
                            is_agnet_number: true,
                          });
                        else arr.push(asd);
                      });
                    } else {
                      arr = d.filter((ds, i) => i !== ind);
                    }
                    dispatch({
                      type: "NEW-TRF",
                      payload: { ...data, beneficiary_contacts: arr },
                    });
                  }}
                  addPhone={() =>
                    dispatch({
                      type: "NEW-TRF",
                      payload: {
                        ...data,
                        beneficiary_contacts: [
                          ...data.beneficiary_contacts,
                          {
                            number: "",
                            contact_method_id: 1,
                            is_agnet_number: true,
                          },
                        ],
                      },
                    })
                  }
                  isEnd={ind === data.beneficiary_contacts.length - 1}
                  placeholder="Client Phone"
                  phone
                  icon={phone}
                  pad
                  noFloat
                  lang={getPhone(a.number)}
                  setLang={(e) => {
                    let d = data.beneficiary_contacts;
                    let arr = [];
                    d.map((asd, inds) => {
                      if (inds === ind)
                        arr.push({
                          number: e.target.value,
                          contact_method_id: 1,
                          is_agnet_number: true,
                        });
                      else arr.push(asd);
                    });
                    dispatch({
                      type: "NEW-TRF",
                      payload: { ...data, beneficiary_contacts: arr },
                    });
                  }}
                />
              </div>
            </div>
          ))}
          {data.recipients.length > 0 && (
            <>
              <div
                className="modal-icon-section trf-modal"
                style={{ marginTop: "18px" }}
              >
                <div className="modal-icon-name">
                  <svg
                    id="_15x15_photo_back"
                    data-name="15x15 photo back"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                  >
                    <g id="Mask_Group_173" data-name="Mask Group 173">
                      <g
                        id="Group_13"
                        data-name="Group 13"
                        transform="translate(1.608 0)"
                      >
                        <path
                          id="Path_20"
                          data-name="Path 20"
                          d="M653,149.289c.4-.085.763-.413.823-1.141.049-.6-.1-.9-.329-1.058.625-2.552-1.1-3.052-1.1-3.052a2.59,2.59,0,0,0-3.809-.653,4.5,4.5,0,0,0-1.114.956,3.978,3.978,0,0,0-.852,2.665c-.308.115-.55.414-.489,1.148.063.761.459,1.085.878,1.148a3.044,3.044,0,0,0,5.993-.01Zm-3,1.874c-1.523,0-2.754-2.066-2.754-3.781,0-.23.007-.452.022-.654a5.225,5.225,0,0,0,4.263-1.571,5,5,0,0,1,1.214,2.17v.055c.01,1.713-1.216,3.783-2.739,3.783Z"
                          transform="translate(-644.076 -142.822)"
                          fill="#5d5d5d"
                        />
                        <path
                          id="Path_21"
                          data-name="Path 21"
                          d="M644.191,174.579l.177-.73a.426.426,0,0,1,.123-.211l-.053-.04-1.576-1.3-.959.23a3.481,3.481,0,0,0-2.767,3.328v2.066a.767.767,0,0,0,.794.731h4.059l.619-3.528a.43.43,0,0,1-.417-.54Z"
                          transform="translate(-639.136 -163.648)"
                          fill="#5d5d5d"
                        />
                        <path
                          id="Path_22"
                          data-name="Path 22"
                          d="M663.477,172.517l-.946-.23-1.573,1.3-.053.04a.427.427,0,0,1,.124.211l.177.73a.43.43,0,0,1-.417.531l.619,3.528h4.058a.737.737,0,0,0,.762-.731v-2.066a3.465,3.465,0,0,0-2.751-3.319Z"
                          transform="translate(-654.445 -163.648)"
                          fill="#5d5d5d"
                        />
                      </g>
                    </g>
                  </svg>
                  <div className="modal-name-icon">Recipient</div>{" "}
                  <div className="second-text">
                    Can Receive The Amount Instead Of : “ Client Name “
                  </div>
                </div>
              </div>
              <div className="input-section">
                <div
                  className={
                    "input-holder" + ` ${data.recipients[0].id && "pad48"}`
                  }
                >
                  {data.recipients[0].id && !nameFocuse && (
                    <span
                      style={{
                        left: `${data.client.name.length * 10 + 28}px`,
                        display: "flex",
                        alignItems: "flex-end",
                      }}
                      className="currency-lael"
                    >
                      ID <pre> </pre> {` ${data.client.id}`}
                    </span>
                  )}
                  {data.recipients[0].photo && (
                    <img
                      width={"20"}
                      height="20"
                      className="data-country_id"
                      src={data.recipients[0].photo}
                    />
                  )}
                  <InputText
                    setFocuse={(s) => setNameFocuse(s)}
                    placeholder="Client Name / id"
                    icon={acc}
                    pad
                    noFloat
                    lang={
                      data.recipients[0].name ? data.recipients[0].name : ""
                    }
                    clear={() =>
                      dispatch({
                        type: "NEW-TRF",
                        payload: {
                          ...data,
                          recipients: [{ ...data.recipients[0], name: "" }],
                        },
                      })
                    }
                    setLang={(e) => {
                      dispatch({
                        type: "NEW-TRF",
                        payload: {
                          ...data,
                          recipients: [
                            { ...data.recipients[0], name: e.target.value },
                          ],
                        },
                      });
                      dispatch({
                        type: "CLIENT_ASYNC_SAGA",
                        payload: e.target.value,
                      });
                    }}
                  />
                  {data.client.name &&
                    data.client.name.length > 0 &&
                    nameFocuse && (
                      <div className="input-sync">
                        {clients.map((a) => (
                          <div
                            className="input-sync-item"
                            onClick={() =>
                              dispatch({
                                type: "NEW-TRF",
                                payload: {
                                  ...data,
                                  recipients: [
                                    {
                                      ...data.recipients[0],
                                      a,
                                      client_id: a.id,
                                      name: a.name,
                                    },
                                  ],
                                },
                              })
                            }
                          >
                            <img width={"20"} height="20" src={a.photo} />
                            <div className="input-sync-text">{a.name}</div>
                            <div
                              className="input-sync-text"
                              style={{ marginLeft: "12px" }}
                            >
                              ID
                            </div>
                            {
                              <div
                                className="input-sync-text"
                                style={{ marginLeft: "8px" }}
                              >
                                {a.id}
                              </div>
                            }
                          </div>
                        ))}
                        {client_load && (
                          <div className="input-sync-item spin-cont">
                            <Spinner />
                          </div>
                        )}
                      </div>
                    )}
                </div>
              </div>
            </>
          )}
          {data.recipients.length > 0 &&
            data.recipients[0].beneficiary_contacts.map((a, ind) => (
              <div className="input-section">
                <div className="input-holder">
                  <div className="contact-method">
                    <svg className="sms-svg" data-src={call}></svg>

                    <svg className="sms-svg" data-src={mes}></svg>
                    <svg className="call-svg" data-src={acall}></svg>
                    <svg className="call-svg" data-src={dcall}></svg>
                  </div>
                  <InputText
                    clear={() => {
                      let d = data.recipients[0].beneficiary_contacts;
                      let arr = [];
                      if (a.number.length > 0) {
                        d.map((asd, inds) => {
                          if (inds === ind)
                            arr.push({
                              number: "",
                              contact_method_id: 1,
                              is_agnet_number: true,
                            });
                          else arr.push(asd);
                        });
                      } else {
                        arr = d.filter((ds, i) => i !== ind);
                      }
                      dispatch({
                        type: "NEW-TRF",
                        payload: {
                          ...data,
                          recipients: [
                            {
                              ...data.recipients[0],
                              beneficiary_contacts: arr,
                            },
                          ],
                        },
                      });
                    }}
                    addPhone={() =>
                      dispatch({
                        type: "NEW-TRF",
                        payload: {
                          ...data,
                          recipients: [
                            {
                              ...data.recipients[0],
                              beneficiary_contacts: [
                                ...data.recipients[0].beneficiary_contacts,
                                {
                                  number: "",
                                  contact_method_id: 1,
                                  is_agnet_number: true,
                                },
                              ],
                            },
                          ],
                        },
                      })
                    }
                    isEnd={
                      ind === data.recipients[0].beneficiary_contacts.length - 1
                    }
                    placeholder="Recipient Phone"
                    phone
                    icon={phone}
                    pad
                    noFloat
                    lang={getPhone(a.number)}
                    setLang={(e) => {
                      let d = data.recipients[0].beneficiary_contacts;
                      let arr = [];
                      d.map((asd, inds) => {
                        if (inds === ind)
                          arr.push({
                            number: e.target.value,
                            contact_method_id: 1,
                            is_agnet_number: true,
                          });
                        else arr.push(asd);
                      });
                      dispatch({
                        type: "NEW-TRF",
                        payload: {
                          ...data,
                          recipients: [
                            {
                              ...data.recipients[0],
                              beneficiary_contacts: arr,
                            },
                          ],
                        },
                      });
                    }}
                  />
                </div>
              </div>
            ))}
          <div className="modal-icon-section" style={{ marginTop: "18px" }}>
            <div className="modal-icon-name">
              <svg
                id="_15x15_photo_back"
                data-name="15x15 photo back"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="15"
                height="15"
                viewBox="0 0 15 15"
              >
                <g id="Mask_Group_177" data-name="Mask Group 177">
                  <g id="money-8" transform="translate(0 0)">
                    <g
                      id="Group_7795"
                      data-name="Group 7795"
                      transform="translate(0 6.965)"
                    >
                      <g id="Group_7794" data-name="Group 7794">
                        <path
                          id="Path_19112"
                          data-name="Path 19112"
                          d="M.2,7.519a.313.313,0,0,0-.186.4l.2.54L1.709,6.965Z"
                          transform="translate(0 -6.965)"
                          fill="#5d5d5d"
                        />
                      </g>
                    </g>
                    <g
                      id="Group_7797"
                      data-name="Group 7797"
                      transform="translate(1.619 12.319)"
                    >
                      <g id="Group_7796" data-name="Group 7796">
                        <path
                          id="Path_19113"
                          data-name="Path 19113"
                          d="M1.619,12.319l.9,2.475a.31.31,0,0,0,.162.176A.314.314,0,0,0,2.812,15a.306.306,0,0,0,.108-.019l.993-.366Z"
                          transform="translate(-1.619 -12.319)"
                          fill="#5d5d5d"
                        />
                      </g>
                    </g>
                    <g
                      id="Group_7799"
                      data-name="Group 7799"
                      transform="translate(8.343 7.374)"
                    >
                      <g id="Group_7798" data-name="Group 7798">
                        <path
                          id="Path_19114"
                          data-name="Path 19114"
                          d="M14.981,10.206l-1.03-2.832L11.479,9.846,12.7,9.4a.312.312,0,1,1,.216.586l-1.556.574a.312.312,0,0,1-.4-.185s0,0,0-.006L8.343,12.984,14.8,10.606A.312.312,0,0,0,14.981,10.206Z"
                          transform="translate(-8.343 -7.374)"
                          fill="#5d5d5d"
                        />
                      </g>
                    </g>
                    <g
                      id="Group_7801"
                      data-name="Group 7801"
                      transform="translate(0.001)"
                    >
                      <g id="Group_7800" data-name="Group 7800">
                        <path
                          id="Path_19115"
                          data-name="Path 19115"
                          d="M14.908,5.091l-5-5a.313.313,0,0,0-.442,0L.092,9.466a.313.313,0,0,0,0,.442l5,5A.309.309,0,0,0,5.312,15a.314.314,0,0,0,.221-.091l9.375-9.375A.313.313,0,0,0,14.908,5.091ZM3.659,8.659l-1.25,1.25a.313.313,0,1,1-.442-.442l1.25-1.25a.313.313,0,0,1,.442.442Zm5.4.4a1.18,1.18,0,0,1-.856.327,2.582,2.582,0,0,1-1.738-.83,2.863,2.863,0,0,1-.781-1.33,1.306,1.306,0,0,1,.278-1.264,1.3,1.3,0,0,1,1.264-.278,2.858,2.858,0,0,1,1.33.781C9.421,7.329,9.642,8.469,9.055,9.056Zm3.978-3.522-1.25,1.25a.313.313,0,0,1-.442-.442l1.25-1.25a.313.313,0,0,1,.442.442Z"
                          transform="translate(-0.001 0)"
                          fill="#5d5d5d"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>

              <div className="modal-name-icon">Amount</div>
            </div>
          </div>
          <div className="input-section">
            <div className="input-holder amoun">
              {data.amount_of_money && data.amount_of_money.length > 0 && (
                <span
                  style={{ left: `${data.amount_of_money.length * 6 + 64}px` }}
                  className="currency-lael"
                >
                  USD
                </span>
              )}
              <InputText
                amount_of_money
                placeholder="Amount"
                xIcon={dol}
                icon={amm}
                pad
                noFloat
                lang={
                  data.amount_of_money
                    ? data.amount_of_money.length > 0
                      ? `${data.amount_of_money}`
                      : data.amount_of_money
                    : ""
                }
                setLang={(e) => {
                  if (e.target.value.length < 15)
                    dispatch({
                      type: "NEW-TRF",
                      payload: { ...data, amount_of_money: e.target.value },
                    });
                }}
              />
            </div>
          </div>
          <div
            className="modal-icon-section"
            style={{ marginTop: "18px", marginLeft: "14.5px" }}
          >
            <div className="modal-icon-name">
              <svg
                id="_10x10_flag_photo"
                data-name="10x10 flag photo"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="10"
                height="10"
                viewBox="0 0 10 10"
              >
                <g id="Mask_Group_195" data-name="Mask Group 195">
                  <g id="options" transform="translate(0 0.011)">
                    <path
                      id="Path_19175"
                      data-name="Path 19175"
                      d="M2.346,5.313V3.125a1.867,1.867,0,0,1-.585,0V5.314A2.652,2.652,0,0,1,2.054,5.3a2.683,2.683,0,0,1,.292.016Z"
                      transform="translate(0 -0.011)"
                      fill="#404040"
                    />
                    <path
                      id="Path_19176"
                      data-name="Path 19176"
                      d="M4.115,6.288,6.282,4.121a1.877,1.877,0,0,1-.413-.413L3.7,5.875a2.665,2.665,0,0,1,.413.413Z"
                      transform="translate(0 -0.011)"
                      fill="#404040"
                    />
                    <path
                      id="Path_19177"
                      data-name="Path 19177"
                      d="M6.885,7.643H4.676a2.684,2.684,0,0,1,.016.292,2.656,2.656,0,0,1-.016.292H6.885a1.868,1.868,0,0,1,0-.585Z"
                      transform="translate(0 -0.011)"
                      fill="#404040"
                    />
                    <circle
                      id="Ellipse_335"
                      data-name="Ellipse 335"
                      cx="1.277"
                      cy="1.277"
                      r="1.277"
                      transform="translate(0.777)"
                      fill="#404040"
                    />
                    <circle
                      id="Ellipse_336"
                      data-name="Ellipse 336"
                      cx="1.277"
                      cy="1.277"
                      r="1.277"
                      transform="translate(6.098 1.328)"
                      fill="#404040"
                    />
                    <circle
                      id="Ellipse_337"
                      data-name="Ellipse 337"
                      cx="1.277"
                      cy="1.277"
                      r="1.277"
                      transform="translate(7.447 6.649)"
                      fill="#404040"
                    />
                    <path
                      id="Path_19178"
                      data-name="Path 19178"
                      d="M4.108,7.936A2.054,2.054,0,1,0,2.054,9.989,2.041,2.041,0,0,0,4.108,7.936Z"
                      transform="translate(0 -0.011)"
                      fill="#404040"
                    />
                  </g>
                </g>
              </svg>

              <div className="modal-name-icon">Payment Type</div>
            </div>
          </div>
          <div className="input-section payment-type">
            <div
              className="input-holder icons-ol"
              style={{ borderBottom: "#CECDCD 0.5px solid" }}
            >
              <div
                onClick={() =>
                  dispatch({
                    type: "NEW-TRF",
                    payload: {
                      ...data,
                      transaction_payment_type_idt_type_id: 1,
                    },
                  })
                }
                className="holder-option"
              >
                <svg
                  className={
                    data.transaction_payment_type_id === 1 && "fill-norm"
                  }
                  data-src={amm}
                  width="15"
                  height={"15"}
                />
                {data.transaction_payment_type_id === 1 && (
                  <div className="option-desc">Cash</div>
                )}
              </div>
              <div
                className="holder-option"
                onClick={() =>
                  dispatch({
                    type: "NEW-TRF",
                    payload: {
                      ...data,
                      transaction_payment_type_idt_type_id: 2,
                    },
                  })
                }
              >
                <svg
                  className={
                    data.transaction_payment_type_id === 2 && "fill-norm"
                  }
                  data-src={bank}
                  width="15"
                  height={"15"}
                />
                {data.transaction_payment_type_id === 2 && (
                  <div className="option-desc">Bank</div>
                )}
              </div>
              <div
                onClick={() =>
                  dispatch({
                    type: "NEW-TRF",
                    payload: { ...data, transaction_payment_type_id: 3 },
                  })
                }
                className="holder-option"
              >
                <svg data-src={zain} width="15" height={"15"} />
                {data.transaction_payment_type_id === 3 && (
                  <div className="option-desc">Zain</div>
                )}
              </div>
            </div>
          </div>
          <div
            className="modal-icon-section"
            style={{ marginTop: "18px", marginLeft: "14.5px" }}
          >
            <div className="modal-icon-name">
              <svg
                id="_10x10_flag_photo"
                data-name="10x10 flag photo"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="10"
                height="10"
                viewBox="0 0 10 10"
              >
                <g id="Mask_Group_196" data-name="Mask Group 196">
                  <g id="pie-chart">
                    <g
                      id="Group_7906"
                      data-name="Group 7906"
                      transform="translate(5.313)"
                    >
                      <g id="Group_7905" data-name="Group 7905">
                        <path
                          id="Path_19179"
                          data-name="Path 19179"
                          d="M5.605,0a.293.293,0,0,0-.293.293V4.414a.293.293,0,0,0,.293.293h4.1A.293.293,0,0,0,10,4.414,4.416,4.416,0,0,0,5.605,0Z"
                          transform="translate(-5.313)"
                          fill="#404040"
                        />
                      </g>
                    </g>
                    <g
                      id="Group_7908"
                      data-name="Group 7908"
                      transform="translate(0 1.191)"
                    >
                      <g id="Group_7907" data-name="Group 7907">
                        <path
                          id="Path_19180"
                          data-name="Path 19180"
                          d="M6.148,9.343,4.727,5.533V1.484a.293.293,0,0,0-.293-.293A4.433,4.433,0,0,0,0,5.586,4.45,4.45,0,0,0,4.434,10a4.373,4.373,0,0,0,1.543-.28A.293.293,0,0,0,6.148,9.343Z"
                          transform="translate(0 -1.191)"
                          fill="#404040"
                        />
                      </g>
                    </g>
                    <g
                      id="Group_7910"
                      data-name="Group 7910"
                      transform="translate(5.312 5.293)"
                    >
                      <g id="Group_7909" data-name="Group 7909">
                        <path
                          id="Path_19181"
                          data-name="Path 19181"
                          d="M9.707,5.293h-4.1a.293.293,0,0,0-.274.4l1.44,3.86a.293.293,0,0,0,.378.171A4.445,4.445,0,0,0,10,5.586.293.293,0,0,0,9.707,5.293Z"
                          transform="translate(-5.312 -5.293)"
                          fill="#404040"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>

              <div className="modal-name-icon">Commission Type</div>
            </div>
          </div>
          <div className="input-section comm-type">
            <div
              className="input-holder icons-ol"
              style={{ borderBottom: "#CECDCD 0.5px solid" }}
            >
              <div
                className="holder-option"
                onClick={() =>
                  dispatch({
                    type: "NEW-TRF",
                    payload: { ...data, commission_type_id: 1 },
                  })
                }
              >
                <svg
                  className={data.commission_type_id === 1 && "fill-norm"}
                  data-src={office}
                  width="15"
                  height={"15"}
                />
                {data.commission_type_id === 1 && (
                  <div className="option-desc">Our Self</div>
                )}
              </div>
              <div
                className="holder-option"
                onClick={() =>
                  dispatch({
                    type: "NEW-TRF",
                    payload: { ...data, commission_type_id: 2 },
                  })
                }
              >
                <svg
                  className={data.commission_type_id === 2 && "fill-norm"}
                  id="_15x15_photo_back"
                  data-name="15x15 photo back"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                >
                  <g id="Mask_Group_173" data-name="Mask Group 173">
                    <g
                      id="Group_13"
                      data-name="Group 13"
                      transform="translate(1.608 0)"
                    >
                      <path
                        id="Path_20"
                        data-name="Path 20"
                        d="M653,149.289c.4-.085.763-.413.823-1.141.049-.6-.1-.9-.329-1.058.625-2.552-1.1-3.052-1.1-3.052a2.59,2.59,0,0,0-3.809-.653,4.5,4.5,0,0,0-1.114.956,3.978,3.978,0,0,0-.852,2.665c-.308.115-.55.414-.489,1.148.063.761.459,1.085.878,1.148a3.044,3.044,0,0,0,5.993-.01Zm-3,1.874c-1.523,0-2.754-2.066-2.754-3.781,0-.23.007-.452.022-.654a5.225,5.225,0,0,0,4.263-1.571,5,5,0,0,1,1.214,2.17v.055c.01,1.713-1.216,3.783-2.739,3.783Z"
                        transform="translate(-644.076 -142.822)"
                        fill="#5d5d5d"
                      />
                      <path
                        id="Path_21"
                        data-name="Path 21"
                        d="M644.191,174.579l.177-.73a.426.426,0,0,1,.123-.211l-.053-.04-1.576-1.3-.959.23a3.481,3.481,0,0,0-2.767,3.328v2.066a.767.767,0,0,0,.794.731h4.059l.619-3.528a.43.43,0,0,1-.417-.54Z"
                        transform="translate(-639.136 -163.648)"
                        fill="#5d5d5d"
                      />
                      <path
                        id="Path_22"
                        data-name="Path 22"
                        d="M663.477,172.517l-.946-.23-1.573,1.3-.053.04a.427.427,0,0,1,.124.211l.177.73a.43.43,0,0,1-.417.531l.619,3.528h4.058a.737.737,0,0,0,.762-.731v-2.066a3.465,3.465,0,0,0-2.751-3.319Z"
                        transform="translate(-654.445 -163.648)"
                        fill="#5d5d5d"
                      />
                    </g>
                  </g>
                </svg>
                {data.commission_type_id === 2 && (
                  <div className="option-desc">Person</div>
                )}
              </div>
              <div
                className="holder-option"
                onClick={() =>
                  dispatch({
                    type: "NEW-TRF",
                    payload: { ...data, commission_type_id: 3 },
                  })
                }
              >
                <svg
                  className={data.commission_type_id === 3 && "fill-norm"}
                  data-src={share}
                  width="15"
                  height={"15"}
                />
                {data.commission_type_id === 3 && (
                  <div className="option-desc">Shared</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div
            onClick={() => sendRequest()}
            className={
              "modal-button-transfer" + ` ${!ButtonValidate() && "disable"}`
            }
          >
            Send Request
          </div>
        </div>
      </div>
    </>
  );
}

export default TransferModal;
