import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Curre from "../../../../assets/svg/Transfer.svg"
import FollowerIcon from '../Languages/svgs/FollowerIcon'
import TransferBody from './TransferBody'
import "./index.css"
function Transfer(props) {
    const [lang_id, setLang_id]= useState(null)
    const loading = useSelector((state)=> state.transfer.loading)
    const loading_percent = useSelector((state)=> state.transfer.loading_percent)
    const dispatch=useDispatch()
    const [lang,setLang]=useState(null)
    const translated_lang = useSelector((state)=>state.auth.lang)
    const getLang=()=>{
      if(lang){
        return(lang)
      }
      else{
        return translated_lang
      }
    }
    const makeFollower = (level,value,id) =>{
      
      if(id===0){
        setLang_id(null)
  
      }
      else setLang_id(id)
      if(languageFollower.length>=level){
          let arr = languageFollower
          arr[level] = {name:value,id:id}
          arr = arr.filter((a,index) => index <= level)
          setLanguageFollower([...arr])
      }else{
          let arr = languageFollower
          setLanguageFollower([...languageFollower,{name:value,id:id}])
        
      }
    }
    const languages = useSelector(state => state.transfer.languages)
    const [languageFollower, setLanguageFollower] = useState([])
  const open =useSelector(state=>state.transfer.open)
    const setOpen = (e)=>{
        dispatch({type:"OPEN-TRF",payload:e})
      }
      const mode =useSelector(state=>state.transfer.mode)
      const setMode = (e)=>{
        dispatch({type:"MODE-TRF",payload:e})
      }
       const setModalData = (e)=>{
        dispatch({type:"MOD-DATA-TRF",payload:{name:e.name,icon:e.icon}})
      }
      const setData = (e)=>{
        dispatch({type:"NEW-TRF",payload:{...e}})

      }
      const Update =(e)=>{
        dispatch({type:"request-running-red-TRF",paylod:null})
        setMode("update");
        let obj={request_type:null,name:"",source:null,country_id:null,client:{name:"",photo:null,id:null}, beneficiary_contacts:[{
          "number": "",
          "contact_method_id": 1,
          "is_agnet_number": true
      }],amount_of_money:"",transaction_payment_type_id:null,commission_type_id:null}
        setData(obj);
        
        setMode("update");
        setOpen(true);
      }
      const Read =(e)=>{
       
        let obj={request_type:null,name:"",source:null,country_id:null,client:{name:"",photo:null,id:null}, beneficiary_contacts:[{
          "number": "",
          "contact_method_id": 1,
          "is_agnet_number": true
      }],amount_of_money:"",transaction_payment_type_idt_type_id:null,commission_type_id:null}

       
    
      setData(obj)
        setMode("read");
        setOpen(true);
      }
      const Close = () =>{
        let obj={request_type:null,name:"",country_id:null,source:null,client:{name:"",photo:null,id:null}, beneficiary_contacts:[{
          "number": "",
          "contact_method_id": 1,
          "is_agnet_number": true
      }],amount_of_money:"",transaction_payment_type_id:null,commission_type_id_id:null}
        
        setOpen(false);
        setData(obj);
        setModalData({name:"",icon:null,code:""})
        
      }
      const Add = (e) =>{
        setOpen(false);
        dispatch({type:"request-running-red-TRF",paylod:null})
        let obj={recipients:[],request_type:null,name:"",country_id:null,source:null,client:{name:"",photo:null,id:null}, beneficiary_contacts:[{
          "number": "",
          "contact_method_id": 1,
          "is_agnet_number": true
      }],amount_of_money:"",transaction_payment_type_idt_type_id:null,commission_type_id:null}
        setData(obj);
        setMode("add");
        setTimeout(() => {
          setOpen(true);
        }, 100);
       
      }
  return (
    <div className={`languages-page ${props.active&&"Active-page"}`}>
   <div className='lang-top'>
       <div className='lang-info'>
           <div className='lang-icon header-icon'>
               <img alt="languages" src={Curre}/>
           </div>
           <div className='lang-name'>Transfer</div>
           <div className='follower-list'>
              {languageFollower.map((fo,index)=>(
                <div className='follower' onClick={()=>makeFollower(index,fo.name,fo.id)}>
                     <span><FollowerIcon expand={false}/></span>
                <span>{fo.name}</span>
                </div>
           
              ))}
            </div>
       </div>
      
       <div className={`lang-add currency-add`}>
       
        <svg style={{marginRight:"20px"}} id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
        <g id="Mask_Group_222" data-name="Mask Group 222">
          <g id="transfer-2">
            <path id="Path_19262" data-name="Path 19262" d="M14.531,9.375a.469.469,0,0,1-.469-.469,3.285,3.285,0,0,0-3.281-3.281H6.25V7.656a.469.469,0,0,1-.792.339L2.02,4.714a.468.468,0,0,1,0-.677L5.458.755a.469.469,0,0,1,.792.339V3.125h3.906A4.85,4.85,0,0,1,15,7.969v.938A.469.469,0,0,1,14.531,9.375Z" fill="#404040"/>
            <path id="Path_19263" data-name="Path 19263" d="M8.75,13.906V11.875H4.844A4.85,4.85,0,0,1,0,7.031V6.094a.469.469,0,0,1,.938,0A3.285,3.285,0,0,0,4.219,9.375H8.75V7.344a.469.469,0,0,1,.792-.339l3.438,3.281a.468.468,0,0,1,0,.677L9.542,14.245A.469.469,0,0,1,8.75,13.906Z" fill="#404040"/>
          </g>
        </g>
      </svg>

         
         <svg style={{marginRight:"20px",width:"1px"}} xmlns="http://www.w3.org/2000/svg" width="0.5" height="25.5" viewBox="0 0 0.5 25.5">
         <line id="Line_992" data-name="Line 992" y1="25" transform="translate(0.25 0.25)" fill="none" stroke="#8e8e8e" strokeLinecap="round" strokeWidth="0.5"/>
       </svg>

       <svg onClick={()=>Add()}  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
     
           <g id="Mask_Group_16" data-name="Mask Group 16" transform="translate(-324 -333)">
               <path id="add-3" d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z" transform="translate(324 333)" fill="#404040"/>
           </g>
           </svg>

       </div>
   </div>
   <TransferBody lang={getLang()} setModalData={(e)=>setModalData(e)} setLang_id={(e)=>setLang_id(e)} lang_id={lang_id} open={open} setOpen={(e)=>setOpen(e)} Add={(e)=>Add(e)} Close={(e)=>Close(e)} Update={(e)=>Update(e)} Read={(e)=>Read(e)}  makeFollower={(e,n,id)=>makeFollower(e,n,id)} setLanguageFollower={(level,val)=>setLanguageFollower(level,val)} languageFollower={languageFollower}/>
    
   </div>
  )
}

export default Transfer