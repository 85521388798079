import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FILE_SERVER, isNew } from '../../../../../constants'
import ProfileNo from "../../../WhatsApp/assets/images/profileNo.png";

function ChatUser(props) {
const chatsArray=useSelector((state)=>state.chat.data)
    const emps= useSelector(state => state.employee.employee)
    const getNewMessage=()=>{
        
     
        return(isNew(props.chat.messages))
        }
        const getStatus=(id)=>{
            if(emps.filter((s)=>s.id===id).length>0){
                if(emps.filter((s)=>s.id===id)[0].status!=="in"){
                    return "decative"
                } 
            }
        }
        const dispatch=useDispatch()
        const onClick=()=>{
          if(props.forwardChat)
          props.forwardChat()
          dispatch({ type: "CHAT_ID", payload: null });
          dispatch({type:"OPEN-CHAT",payload:props.chat})

        }
        
  return (
    <div onClick={()=>{onClick()}} className={`${isNew(props.chat.messages)>0&&"outer-shadow"} ${getStatus(props?.chat?.channel_members?.filter((a)=>parseInt(a.user_id)!==parseInt(JSON.parse(localStorage.getItem("user")).id))[0]?.user_id)} chat-user-item`}>
        <div className='chat-names'>
{props.chat?.channel_members?.filter((s)=>s?.user_id!==JSON.parse(localStorage.getItem("user")).id)[0]?.user?.full_name?.length>0?props.chat?.channel_members?.filter((s)=>s?.user_id!==JSON.parse(localStorage.getItem("user")).id)[0]?.user.full_name:props.chat.channel_members.filter((s)=>s.user_id!==JSON.parse(localStorage.getItem("user")).id)[0]?.user.username?props.chat.channel_members.filter((s)=>s.user_id!==JSON.parse(localStorage.getItem("user")).id)[0]?.user.username:"UNKNOWN USER"}
        </div>
        <img src={props.chat.channel_members.filter((a)=>parseInt(a.user_id)!==parseInt(JSON.parse(localStorage.getItem("user")).id))[0]?.user?.avatar?(FILE_SERVER+`${props.chat.channel_members&&props.chat.channel_members&&props.chat.channel_members.filter((a)=>parseInt(a.user_id)!==parseInt(JSON.parse(localStorage.getItem("user")).id))[0].user.avatar}`):ProfileNo}/>
{getNewMessage()>0&&
<>
<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
  <g id="Rectangle_5020" data-name="Rectangle 5020" fill="none" stroke="#0a5cf7" strokeWidth="1">
    <rect width="40" height="40" rx="10" stroke="none"/>
    <rect x="0.5" y="0.5" width="39" height="39" rx="9.5" fill="none"/>
  </g>
  </svg>
  <div className="unread-circle">
    {getNewMessage()}
  </div>
</>     }


    </div>
  )
}

export default ChatUser