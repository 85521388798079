import React, { useState, useEffect, useRef } from "react";
import Icon from "../../../components/Icon";
import { useDispatch, useSelector } from "react-redux";
import { useStopwatch } from "react-timer-hook";
import profile from "../../../assets/images/profileNo.png";

const attachButtons = [
  { icon: "attachRooms", label: "Choose room" },
  // { icon: "attachContacts", label: "Choose contact" },
  { icon: "attachDocument", label: "Choose document" },
  { icon: "attachCamera", label: "Choose Audio" },
  { icon: "attachImage", label: "Choose image", setFile: "setFile" },
];

const ChatInput = ({
  showAttach,
  sendVid,
  setShowAttach,
  showEmojis,
  onFocus,
  onBlur,
  replyMessage,
  setShowEmojis,
  newMessage,
  setNewMessage,
  isMention,
  setRec,
  handlePhoto,
  sendDocument,
  rec,
  submitNewMessage,
  sendAud,
  chat
}) => {
  const getMentioned = (v) => {
    if (!chat) {
      if (v[v.length - 1] === "@") {
        setOpenMenu(true)
      }
      else {
        setOpenMenu(false)
      }
    }
  }
  const activeChat = useSelector((state) => state.whatsapp.activeChat)
  let members = activeChat?.whatsapp_contact?.sub_contacts
  const [openMenu, setOpenMenu] = useState(false)
  function getCaret(el) {
    if (el.selectionStart) {
      return el.selectionStart;
    } else if (document.selection) {
      el.focus();
      var r = document.selection.createRange();
      if (r == null) {
        return 0;
      }
      var re = el.createTextRange(), rc = re.duplicate();
      re.moveToBookmark(r.getBookmark());
      rc.setEndPoint('EndToStart', re);
      return rc.text.length;
    }
    return 0;
  }
  const detectEnterPress = (e) => {
    getWidth(e.target.value,e.target);
    if ((e.key === "Enter" || e.keyCode === 13) && (newMessage.length > 0)) {
      var content = e.target.value;
      var caret = getCaret(e.target);
      if (e.shiftKey) {
        let value = content.substring(0, caret - 1) + "\n" + content.substring(caret, content.length);
        setNewMessage(value)
        e.stopPropagation();
      } else {
        e.target.style.height="59px"
        setShowEmojis(false);
        setShowAttach(false);
        submitNewMessage(content);
      }
    }
  };

  const dispatch = useDispatch();
  const [img, setImg] = useState(null);
  useEffect(() => {

  }, [img]);
  useEffect(() => {
    dispatch({
      type: "PHOTO",
      payload: img,
    });
  }, [img]);
  const trigger = () => {

    let Image = document.createElement("input");
    Image.onblur = () => {
      ;
    }
    Image.onchange = async (e) => {
      let i = Math.random();
      setShowAttach(false);
      handlePhoto(e.target.files[0])
    };
    Image.type = "file";
    Image.hidden = true;
    Image.accept = "image/*";
    Image.style = { position: "absolute", opacity: "0" };
    let i = document.body.appendChild(Image);
    i.click();
  }
  const triggerFile = () => {
    let Image = document.createElement("input");
    Image.onblur = () => {
      ;
    }
    Image.onchange = async (e) => {
      let i = Math.random();
      setShowAttach(false);
      sendDocument(e.target.files[0])
    };
    Image.type = "file";
    Image.hidden = true;
    Image.accept = "*";
    Image.style = { position: "absolute", opacity: "0" };
    let i = document.body.appendChild(Image);
    i.click();
  }
  const triggerVid = () => {
    let Image = document.createElement("input");
    Image.onblur = () => {
      ;
    }
    Image.onchange = async (e) => {
      let i = Math.random();
      setShowAttach(false);
      sendVid(e.target.files[0])
    };
    Image.type = "file";
    Image.hidden = true;
    Image.accept = "video/*";
    Image.style = { position: "absolute", opacity: "0" };
    let i = document.body.appendChild(Image);
    i.click();
  }
  const triggerAudio = () => {
    let Image = document.createElement("input");
    Image.onblur = () => {
      ;
    }
    Image.onchange = async (e) => {
      let i = Math.random();
      setShowAttach(false);
      sendAud(e.target.files[0])
    };
    Image.type = "file";
    Image.hidden = true;
    Image.accept = ".mp3,.mpeg";
    Image.style = { position: "absolute", opacity: "0" };
    let i = document.body.appendChild(Image);
    i.click();
  }
  const getWidth=(val,elem)=>{
    var textarea =elem,
    top = textarea.scrollTop,
    height = textarea.clientHeight;
    if(top > 0){
       textarea.style.height=`${top + height}px`
    }
    else{
      textarea.style.height=`56px`
    }
  }


function keypress(e){
  // console.log(e)
  let charStr=e.target.value[0]||e.key
    if(e.target.value.length<2){
    // need to wait for the character
    var langdic = {
      "arabic" : /[\u0600-\u06FF]/,
      "english" : /^[a-zA-Z]+$/
        //add other languages her
    }  
    let lang=''
    const keys = Object.entries(langdic); // read  keys and values from the dictionary
  Object.entries(langdic).forEach(([key, value]) => {  // loop to read all the dictionary items if not true
    if (value.test(charStr) == true){   //Check Unicode to see which one is true
      lang=key //print language name if unicode true   
    }
  });
  e.target.style.direction=lang==="arabic"?'rtl':'ltr'
    }
    if(e.key==="Backspace"&&e.target.value.length===1){
      e.target.style.direction='ltr'
    }
}
  return (
    <div className="chat__input-wrapper">
      {openMenu &&
        <div className="member-menu">
          {members?.map((me) => (
            <div className="member-item" onClick={() => { setNewMessage(newMessage + me.number); setOpenMenu(false) }}>
              <img src={me.profile_photo || profile} />
              <div className="member_name">{me.name?.length > 0 ? me.name : me.pushname}</div>
            </div>
          ))}
        </div>}
      {!rec && <>{showEmojis && (
        <button aria-label="Close emojis" onClick={() => setShowEmojis(false)}>
          <Icon id="cancel" className="chat__input-icon" />
        </button>
      )}
        <button aria-label="Emojis" onClick={() => setShowEmojis(!showEmojis)}>
          <Icon
            id="smiley"
            className={`chat__input-icon ${showEmojis ? "chat__input-icon--highlight" : ""
              }`}
          />
        </button>

        <div className="pos-rel">
          {!showEmojis && <button aria-label="Attach" onClick={() => setShowAttach(!showAttach)}>
            <Icon
              id="attach"
              className={`chat__input-icon ${showAttach ? "chat__input-icon--pressed" : ""
                }`}
            />
          </button>}

          <div
            className={`chat__attach ${showAttach ? "chat__attach--active" : ""}`}
          >
            {attachButtons.map((btn) => (
              <button
                className="chat__attach-btn"
                aria-label={btn.label}
                key={btn.label}
                onClick={() => {
                  if (btn.label === "Choose document") triggerFile()
                  if (btn.label === "Choose room") triggerVid();
                  if (btn.label === "Choose image") trigger();
                  if(btn.label==="Choose Audio") triggerAudio();
                }}

              >

                <Icon
                  htmlFor="file"
                  id={btn.icon}
                  className="chat__attach-icon"
                />
              </button>
            ))}

          </div>
        </div>
        <textarea

          className="chat__input"
          onFocus={()=>onFocus()}
          onBlur={()=>{
            onBlur()
            }}
          placeholder="Type a message"
          style={{ resize: "none" }}
         
          value={newMessage}
          onPaste={(e)=>{
            setTimeout(() => {
              getWidth(e.target.value,e.target);
            }, 300);
             }}
          onChange={(e) => {getWidth(e.target.value,e.target); getMentioned(e.target.value);
            if(e.key === "Enter" || e.keyCode === 13) ;
            else
            setNewMessage(e.target.value)
          
          }}
          onKeyUp={detectEnterPress}
          onKeyDown={keypress}
        ></textarea>
      </>}
      {newMessage ? (
        <button aria-label="Send message" onClick={() => { setShowEmojis(false); setShowAttach(false); submitNewMessage(newMessage); }}>
          <Icon id="send" className="chat__input-icon" />
        </button>
      ) :
       !rec ? (
        <button onClick={() => setRec(!rec)} aria-label="Record voice note">
          <Icon
            // onClick={() => }
            id="microphone"
            className="chat__input-icon"
          />
        </button>
      ) : (
        <button
          onClick={() => setRec(false)}
          aria-label="Send message"
        // onClick={submitNewMessage}
        >
          <Icon id="send" className="chat__input-icon" />
        </button>
      )}
    </div>
  );
};

export default ChatInput;
