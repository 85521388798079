import React from 'react'
import flat from "./svgs/flat.svg"
import FlatIcon from './svgs/FlatIcon'
function LangIcon(props) {
    
  return (
    <>
   {props.name.length<2?
   <>

   <img  className='language-flat' src={flat}/>


   </>:
       <FlatIcon thir={props.thir} icon={props.name}/>
   }
  </>)
}

export default LangIcon