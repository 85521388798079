import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import App from '../WhatsApp/App'

function Whatsapp(props) {
  const dispatch=useDispatch()
  const viewDisplay=useSelector((state)=>state.whatsapp.viewDisplay)
  const callInProgress=useSelector((state)=>state.chat.callInProgress)
  const WAwidth=useSelector((state)=>state.whatsapp.WAwidth)
  const setWAwidth=(s)=>{
    localStorage.setItem("WA-WIDTH",s)
    dispatch({type:"WA-WIDTH",payload:s})
  }
  const setViewDisply=(s)=>{
    dispatch({type:"VIEW_DISPLAY",payload:s})
  }
  return (
    <>
      {<div onClick={(e) => {
        if(!callInProgress){
        props.close(); dispatch({type:"CHAT_ID",payload:null})}}} className={`lang-modalDisable ${props.open && "open"}`}></div>}
    <div style={{width:WAwidth?`${WAwidth}px`:'450px'}} className={`lang-modal whats-modal ${props.open && "open"} ${"nopad"} ${viewDisplay&&"wide-view"}`}>
    <div className='whats-page'>
        <App WAwidth={WAwidth} setWAwidth={(s)=>setWAwidth(s)} viewDisplay={viewDisplay} make={(e)=>setViewDisply(e)} change={()=>setViewDisply(!viewDisplay)}/>
    </div>
     </div>
  </>
  )
}

export default Whatsapp