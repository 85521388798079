
import accounting from  "../../../assets/svg/accounting.svg"
import doll from  "../../../assets/svg/doll.svg"
import turk from  "../../../assets/svg/turk.svg"
import syr from  "../../../assets/svg/syr.svg"
import Irq from "../../../assets/svg/irq.svg"
import leb from "../../../assets/svg/leb.svg"
const initialState = {
  view:"Countries",
  test:[],
  countries:[],
  pageFollower:["Employees"],
  newDepartment:{id:1,translations:[{name:"",code:"",language_code:"en"},{name:"",code:"",language_code:"ku"},{name:"",code:"",language_code:"ar"},{name:"",code:"",language_code:"tr"}],flag:syr,phone_code:"",gmt_difference:"",post_code:"",languages:[],currencies:[]},
  mode:"read",
  open:false,
  modalData:{name:"",icon:null,code:""},
  loading:false,
  Active_Ids:[],
  request_running:false,
  request_data:null,
  message:"",
  error:false,
  img_upload:{type:"",status:false}

}
 export const CountriesReducer=(state = initialState, { type, payload }) => {

  switch (type) {
    
    case "GET-CUR-REDUCER":{
      let a=[]
      payload.map((s)=>{
        a.push({...s,code:s.flat_photo_path})
      })
      return({
        ...state,
        test:[...a]
      })
    }
    case "IMG-UPLOAD-REG":{
      return({
        ...state,
        img_upload:{...payload}
      })
    }
    case "request-running-REG":{
      return({
        ...state,
        request_running:true,
        request_data:payload
      })
    }
    case "GENERAL-ERR-REG":{
      return({
        ...state,
        request_running:false,
        request_data:payload.data,
        error:true,
        message:payload.message
      })
    }
    case "request-running-REG-red":{
      
      return({
        ...state,
        request_running:false,
        request_data:payload,
        error:false,
        message:""
      })
    }
    case "GENERAL-LOADING-REG":{
      return({
        ...state,
        loading:true
      })
    }
    case "VIEW-REG":{
      return({
        ...state,
        view_language:payload
      })
    }
    case "GENERAL-DONE-REG":{
      return({
        ...state,
        loading:false,
        open:false,
        

      })
    }
    case "LEVEL-DONE-REG":{
      return({
        ...state,
        loading:false
      })
    }
    case "GET-REG-REDUCER":{
      return({...state,countries:[...payload],request_data:null,request_running:false,error:false,message:""})

    }
    case "Active-add":{
      return({
        ...state,
        Active_Ids:payload
      })
    }
  case "REG-VIEW":
    return { 
      ...state,
       view:payload,
      pageFollower:[payload]
      }
    case "NEW-REG":{
      
    
      return{
        ...state,
        newDepartment:{...payload}
      }
    }
    case "OPEN-REG":{
      return({
        ...state,
        open:payload
      })
    }
    case "MODE-REG":{
      return({
        ...state,
        mode:payload
      })
    }
    case "MOD-DATA-REG":{
      return({
        ...state,
        modalData:{...payload}
      })
    }
    
  default:
    return state
  }
}
  