import { API2 } from "../../../API";
import { store } from "../../store";
import { set } from "react-hook-form";
const base_user =
  store?.getState().whatsapp.fetched_url === true
    ? store?.getState()?.whatsapp.base_user
    : process.env.REACT_APP_USER_BASE_URL;
const base =
  store?.getState().whatsapp.fetched_url === true
    ? store?.getState()?.whatsapp.base_url
    : process.env.REACT_APP_BASE_URL;
export const _LoginWhatsapp = (user_name, password, callback, callbackErr) => {
  const base_user =
  store?.getState().whatsapp.fetched_url === true
    ? store?.getState()?.whatsapp.base_user
    : process.env.REACT_APP_USER_BASE_URL;
  API2.post(`${base_user}/api/v1/auth/login`, {
    user_name: user_name,
    password: password,
  })
    .then(function (data) {

      callback(data);
      store.dispatch({
        type: "TOKEN_WHATSAPP",
        payload: data.access_token,
      });
    })
    .catch(function (res) {});
};
export const sendMsgForUser = (
  mobile,
  sms,
  id,
  type,
  isGroup,
  date,
  callback,
  callbackErr
) => {
  const token = store.getState()?.whatsapp?.userTokenWhatsapp;
  const chatsId = store.getState()?.whatsapp?.messages;
  store.dispatch({
    type: "CHECK",
    payload: sms,
  });
  store.dispatch({
    type: "LAST_MSG",
    payload: {
      lastMsg: sms,
      body: sms,
      true: "true",
      id: id,
      status: "sent",
      type: type,
      date: date,
      updated_at: date.toISOString(),
      is_from_api: 1,
    },
  });
  store.dispatch({ type: "SENDING" });
  setTimeout(() => {
    store.dispatch({ type: "SENDING_DONE" });
  }, 1000);
  !isGroup
    ? API2.post(`${base_user}/api/v1/whatsapp/send_message_for_user`, {
        receiver_mobile_phone: mobile,
        message: sms,
        
        type: type,
        updated_at: date.toISOString(),
        caption: null,
      })
    : API2.post(`${base_user}/api/v1/whatsapp/send_message_to_group`, {
      group_contact_id: mobile.split("+")[0],
        message: sms,
        
        type: type,
        updated_at: date.toISOString(),
        caption: null,
      })
        .then(function (data) {
          setTimeout(() => {}, 500);
          callback(data);
        })
        .catch(function (res) {
          store.dispatch({ type: "SENDING_DONE" });
        });
};
export const sendMediaForUser = (
  mobile,
  sms,
  id,
  type,
  isGroup,
  date,
  callback,
  callbackErr
) => {
  store.dispatch({
    type: "CHECK",
    payload: sms?.message_content,
  });
  store.dispatch({
    type: "LASTMSG",
    payload: sms?.message_content,
  });
  const token = store.getState()?.whatsapp?.userTokenWhatsapp;
  type === "ptt"
    &&
     API2.post(
        `${base_user}/api/v1/whatsapp/send_media_message`,
        !isGroup
          ? {
              receiver_mobile_phone: mobile,
              file_path: sms,
              
              type: type,
              caption: null,
            }
          : {
              receiver_chat_id: mobile.split("+")[0],
              file_path: sms,
              
              type: type,
              caption: null,
            },
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      )
};
export const getMessages = (phoneNumber, callback, callbackErr) => {
  const token = store.getState()?.whatsapp?.userTokenWhatsapp;
  API2.get(`${base_user}/api/v1/whatsapp/get_chat_messages_from_database`, {
    params: {
      
      phone_number: phoneNumber,
      limit: 10,
      datatables: true,
    },
  })
    .then(function ({ data }) {
      callback(data);
    })
    .catch(function (res) {});
};
export const getUnFetchedMessages = (callback, callbackErr) => {
  const token = store.getState()?.whatsapp?.userTokenWhatsapp;

  // store.dispatch({
  //   type: "FETCHED_DONE",
  //   payload: false,
  // });
  API2.get(`${base_user}/api/v1/whatsapp/get_un_fetched_messages`, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    params: {
      
    },
  })
    .then(function ({ data }) {
      store.dispatch({
        type: "UN_FETCHED_MESSAGE",
        payload: data,
      });
      let count = 0;
      data?.data?.length > 0 &&
        data?.data?.map((one) => {
          
          return one?.un_fetched_messages?.length > 0
            ? (count = count + 1)
            : null;
        });
      if (count > 0) {
        count = 0;
        store.dispatch({
          type: "FETCHED_DONE",
          payload: true,
        });
      }
      callback(data);
    })
    .catch(function (res) {});
};
export const MessagesFetched = (callback, callbackErr) => {
  const token = store.getState()?.whatsapp?.userTokenWhatsapp;
  API2.post(
    `${base_user}/api/v1/whatsapp/make_all_messages_fetched`,
    {
      
    },
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  )
    .then(function ({ data }) {
      // store.dispatch({
      //   type: "FETCHED_DONE",
      //   payload: false,
      // });
      callback(data);
    })
    .catch(function (res) {});
};
export const getMessagesWithChats = (callback, callbackErr) => {
  const token = store.getState()?.whatsapp?.userTokenWhatsapp;
  API2.get(`${base_user}/api/v1/whatsapp/get_chats_with_messages`, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    params: {
      
      limit: 10,
      datatables: true,
    },
  })
    .then(function ({ data }) {
      callback(data);
    })
    .catch(function (res) {});
};
export const getMessagesWithPagination = (callback, callbackErr) => {
  const token = store.getState()?.whatsapp?.userTokenWhatsapp;
  API2.get(`${base_user}/api/v1/whatsapp/get_chats_with_last_20_messages`, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    params: {
      
      limit: 20,
      datatables: true,
    },
  })
    .then(function ({ data }) {
      store.dispatch({
        type: "NEW_CHATS",
        payload: data,
      });
      callback(data);
    })
    .catch(function (res) {});
};
export const getMessagesOneChatWithPagination = (
  chat_id,
  page,
  callback,
  callbackErr
) => {
  const token = store.getState()?.whatsapp?.userTokenWhatsapp;
  API2.get(`${base_user}/api/v1/whatsapp/get_one_chat_with_last_20_messages`, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    params: {
      
      chat_id: chat_id,
      limit: 20,
      page: page,
      datatables: true,
    },
  })
    .then(function ({ data }) {
      callback(data);
    })
    .catch(function (res) {});
};

export const _refreshProfile = (chat_id, callback) => {
  const token = store.getState()?.whatsapp?.userTokenWhatsapp;
  API2.post(
    `${base_user}/api/v1/whatsapp/update_profile_photo`,
    {
      
      chat_id: chat_id,
    },
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  )
    .then(function ({ data }) {
      store.dispatch({
        type: "REFRESH_CHATS",
        payload: data,
      });
      callback(data);
    })
    .catch(function (res) {});
};
