// **  Initial State
import WA from "../../../assets/wa.mp3"
import store from "../../store"
const compares = (a, b) => {
  if (parseInt(new Date(a?.messages[0]?.sent_at).getTime()) < parseInt(new Date(b?.messages[0]?.sent_at).getTime())) {
    return 1
  }
  else {
    if (a.messages.length === 0 || b.messages.length === 0) {
      return 1
    }
    else {
      return -1
    }
  }
}
const initialState = {
  chats: [],
  activeChat: null,
  wa_loading: false,
  userTokenWhatsapp: null,
  username: "",
  password: "",
  is_fetch: null,
  sending: false,
  refs: false,
  scr: false,
  search: "",
  founds: [],
  fetched_url: false,
  base_url: null,
  base_user: null,
  first: false,
  searchConv: "",
  forwarderMessage: null,
  qoutedMessage: null,
  roles: [],
  companies: [],
  sec: false,
  contacts: [],
  WAwidth: localStorage.getItem("WA-WIDTH") ?? null,
  viewDisplay: localStorage.getItem("WA-WIDTH-VAR") === "true" ?? false,
  WAP: false
};
const sortChats = (arr) => {
  let l = arr.sort((a, b) => compares(a, b))
  return l
}
const isNewMess = (oldchats, newchats) => {
  let a = false
  oldchats.map((s) => {
    if (s.messages.filter((m) => m.fromMe === 0).length !== newchats.filter((sd) => sd.id === s.id)[0]?.messages.filter((m) => m.id?.fromMe === 0).length) {
      a = true
    }
  })
  return a
}
const getUpdatedChat = (oldChat, newChat) => {
  if (!oldChat) {
    return { ...newChat, unread: newChat.messages.length };
  } else {
    if (
      newChat.messages.filter(
        (mes) =>
          oldChat.messages.filter((nm) => nm.id === mes.id).length === 0
      ).length > 0
    ) {
      return {
        ...newChat,
        unread:
          oldChat.unread +
          newChat.messages.filter(
            (mes) =>
              oldChat.messages.filter((nm) => nm.id === mes.id).length ===
              0
          ).length,
      };
    } else {
      return { ...newChat, unread: oldChat.unread };
    }
  }
};

export const whatsappReducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN-WA": {
      return ({
        ...state,
        WAP: action.payload
      })
    }
    case "WA-WIDTH": {
      return ({
        ...state,
        WAwidth: action.payload
      })
    }
    case "VIEW_DISPLAY": {
      localStorage.setItem("WA-WIDTH-VAR", action.payload)
      return ({
        ...state,
        viewDisplay: action.payload
      })
    }
    case "GET_WA_ONE_CHAT_RED": {
      let arr = [];
      let active = state.activeChat
      let oldchat = state.chats.filter((s) => s.id === action.payload.id)[0]
      let newchat = action.payload
      let res
      if (oldchat?.id) {
        res = { ...newchat, messages: getUpdatedChat(oldchat, newchat).messages }
      }
      else {
        res = newchat
      }
      arr.push(res)
      arr = [...arr, ...state.chats.filter((s) => s.id !== newchat.id)]
      if (active?.id === res.id) {
        active = res
      }
      return ({
        ...state,
        chats: arr,
        activeChat: active
      })
    }
    case "REM_WA_ONE_CHAT": {

      return ({
        ...state,
        chats: state.chats.filter((s) => s.id !== action.id),
        activeChat: (state.activeChat?.id === action.id || state.activeChat === null) ? null : state.activeChat
      })
    }
    case "CONTACT_ADD": {
      let arr = [...state.contacts]
      if (state.contacts.filter((s) => s.id === action.payload.id).length === 0) {
        arr.push(action.payload)
      }

      return ({
        ...state,
        contacts: arr
      })
    }
    case "MessageReceivedEvent": {
      let scr = action.payload.message.whatsapp_chat_id === state.activeChat?.id ? !state.scr : state.scr;
      if (action.payload.with_notification) {
        let not = new Audio(WA);
        not.volume = 0.5
        if (!state.WAP)
          not.play()
      }
      const message = action.payload?.message
      const message_id = action.payload?.message?.id
      const chat_id = action.payload?.message?.whatsapp_chat_id
      let new_active_chat = {}
      let new_chats = []
      if (state.activeChat?.id === chat_id) {
        let new_messages = state.activeChat?.messages?.map((mes) => {
          if (parseInt(mes.id) === parseInt(message_id)) {
            return ({
              ...mes,
              ack: message?.ack,
              id_from_whatsapp: message?.id_from_whatsapp,
              mid: null,
              sent_at: message.sent_at,
              body: message.body,
              media: message.media,
              type: message.type
            })
          } else {
            return ({
              ...mes
            })
          }
        })
        if (state.activeChat?.messages?.filter(mes => mes.id === message_id).length === 0) {
          new_messages.push({
            ...message,
            fromMe: message.fromMe === true ? 1 : 0

          })
        }
        new_active_chat = {
          ...state.activeChat,
          messages: new_messages
        }
        new_chats = state.chats.map((chat) => {
          if (chat.id === chat_id) {
            return ({
              ...new_active_chat
            })
          } else {
            return ({
              ...chat
            })
          }
        })
        return ({
          ...state,
          activeChat: new_active_chat,
          chats: new_chats,
          scr: scr
        })
      }
      else {

        if (state.chats.filter((s) => s.id === chat_id).length > 0) {
          let new_messages = state.chats?.filter((s) => s.id === chat_id)[0]?.messages?.map((mes) => {
            if (parseInt(mes.id) === parseInt(message_id)) {
              return ({
                ...mes,
                ack: message?.ack,
                id_from_whatsapp: message?.id_from_whatsapp,
                mid: null,
                sent_at: message.sent_at,
                body: message.body,
                media: message.media,
                type: message.type
              })
            } else {
              return ({
                ...mes
              })
            }
          })
          if (state.chats?.filter((s) => s.id === chat_id)[0]?.messages?.filter(mes => mes.id === message_id).length === 0) {
            new_messages.push({
              ...message,
              fromMe: message.fromMe === true ? 1 : 0
            })
            new_active_chat = {
              ...state.chats?.filter((s) => s.id === chat_id)[0],
              unread: action.payload.message.id_from_whatsapp.substring(0, 1) === "f" ? (state.chats?.filter((s) => s.id === chat_id)[0].unread === false ? 1 : state.chats?.filter((s) => s.id === chat_id)[0].unread + 1) : (state.chats?.filter((s) => s.id === chat_id)[0].unread === false ? 0 : parseInt(state.chats?.filter((s) => s.id === chat_id)[0].unread)),
              messages: new_messages
            }
            new_chats = state.chats.map((chat) => {
              if (chat.id === chat_id) {
                return ({
                  ...new_active_chat
                })
              } else {
                return ({
                  ...chat
                })
              }
            })
            new_chats = [{ ...new_chats.filter((s) => s.id === chat_id)[0] }, ...new_chats.filter((s, i) => (s.id !== chat_id))]
          }
          else {
            new_active_chat = {
              ...state.chats?.filter((s) => s.id === chat_id)[0],
              messages: new_messages
            }
            new_chats = state.chats.map((chat) => {
              if (chat.id === chat_id) {
                return ({
                  ...new_active_chat
                })
              } else {
                return ({
                  ...chat
                })
              }
            })
          }

          return ({
            ...state,
            activeChat: state.activeChat,
            chats: new_chats,
            scr: scr

          })
        }
        else {
          let newchat = { isGroup: action.payload.contact.isGroup, id_from_whatsapp: action.payload.contact.id_from_whatsapp, id: chat_id, whatsapp_contact: action.payload.contact, whatsapp_contact_id: action.payload.contact.id, messages: [action.payload.message], unread: action.payload.message.id_from_whatsapp.substring(0, 1) === "f" ? 1 : 0 };
          let arr = [];
          arr.push(newchat)
          state.chats.map((s, i) => {
            if (s.id_from_whatsapp.split("@")[0] !== action.payload.contact.id_from_whatsapp.split("@")[0])
              arr.push(s)
          })
          let active = state.activeChat
          if (state.chats.filter((s) => s.id_from_whatsapp.split("@")[0] === action.payload.contact.id_from_whatsapp.split("@")[0]).length > 0) {
            active = newchat
          }
          return ({
            ...state,
            chats: arr,
            activeChat: active,
            scr: scr
          })
        }
      }
    }
    case "FORWARD-MESSAGE": {
      return ({
        ...state,
        forwarderMessage: action.payload
      })
    }
    case "SEARCH_WA_CONV": {
      return ({
        ...state,
        searchConv: action.payload
      })
    }
    case "WORD_SEARCH": {
      return ({
        ...state,
        wordsearch: action.payload
      })
    }
    case "FETCHED_URL": {
      return {
        ...state,
        fetched_url: true,
      };
    }
    case "FETCH_URL": {
      return {
        ...state,
        base_url: action?.payload?.base,
        base_user: action?.payload?.base_user,
      };
    }
    case "SEARCH_WA": {
      let arr = []
      action.payload.data.map((m, index) => {
        if (index === 0) arr.push({ ...m })
        else if (m.messages) arr.push({ ...m })
      })
      return {
        ...state,
        search: action.payload.search,
        founds: sortChats(arr)
      };
    }
    case "GI_RED": {
      let arr = [];
      let ac = state.activeChat;
      if (ac && ac?.id === action.payload.id) {
        ac = { ...state.activeChat, info: action.payload.data };
      }
      state.chats.map((ch) => {
        if (ch.id === action.payload.id) {
          arr.push({ ...ch, info: action.payload.data });
        } else {
          arr.push(ch);
        }
      });
      return {
        ...state,
        chats: sortChats(arr),
        activeChat: ac,
      };
    }
    case "SA": {
      state.chats.unshift(action.payload);

      return {
        ...state,
      };
    }
    case "SEND_TEXT": {
      if (action.payload) {
        let arr = [];
        let first_var = action.payload.id?.includes("true") ? !state.first : state.first
        let act = state.activeChat.messages;

        act.sort((a, b) => parseInt(new Date(a.sent_at).getTime()) > parseInt(new Date(b.sent_at).getTime()));
        if (state.chats.filter((c) => c.id === state.activeChat.id).length === 0) {
          if (state.chats.length === 0 || state.chats.filter((c) => c.id === state.activeChat.id).length === 0) {
            arr.push({ ...state.activeChat, messages: [...state.activeChat.messages, ...act, action.payload], })
          }
          else {
            arr.push({
              ...state.activeChat,
              messages: act,
            })
          }
        }
        state.chats.map((ch) => {
          if (ch.id === action.payload.chat_id) {
            let mes = [];
            ch.messages.map((m) => {
              mes.push(m);
            });
            mes.unshift(action.payload);
            arr.unshift({ ...ch, messages: mes });
          } else {
            arr.push(ch);
          }
        });
        let acts = state.activeChat
        if (arr.filter((d) => d.id === acts.id).length === 0) {
          return {
            ...state,
            activeChat: {
              ...state.activeChat,
              messages: act,
            },
            chats: arr,
            refs: !state.refs,
            first: first_var
          };
        }
        else
          return {
            ...state,
            activeChat: arr.filter((s) => s.id === state.activeChat.id)[0],
            chats: arr,
            refs: !state.refs,
            first: first_var
          };

      } else {
        return {
          ...state,
          sending: true
        };
      }
    }
    case "SEND_P": {
      return {
        ...state,
        sending: true,
      };
    }
    case "UPLOAD_SUC": {
      let arr = [];
      let ac = state.activeChat;
      state.chats.map((ch) => {
        if (ch.id === action.cid) {
          let mes = [];
          ch.messages.map((m) => {
            if (m?.mid === action.mid) {
              mes.push({ ...m, mid: action.mid, id: action.id });
            } else {
              mes.push(m);
            }
          });
          arr.push({ ...ch, messages: mes });
        } else {
          arr.push(ch);
        }
      });
      if (state.activeChat?.id === action.cid) {
        let mes = [];
        state.activeChat.messages.map((m) => {
          if (m?.mid === action.mid) {
            mes.push({ ...m, mid: action.mid, id: action.id, });
          } else {
            mes.push(m);
          }
        });
        ac = { ...ac, messages: mes };
      }
      return {
        ...state,
        chats: arr,
        activeChat: ac,
        refs: !state.refs,
        first: !state.first,
        sending: false
      };
    }
    case "SENDING_END": {
      return {
        ...state,
        sending: false,
      };
    }
    case "GET_PAGE_CHAT": {
      return {
        ...state,
        is_fetch: true,
        sending: false
      };
    }
    case "GET_PAGE_CHAT_RED": {
      return {
        ...state,
        is_fetch: false,
      };
    }
    case "fetch": {
      return {
        ...state,
        is_fetch: true,
      };
    }
    case "GET_PAGE": {
      let news = action?.payload.messages;
      let active = state.activeChat;
      let id = action?.payload?.id;
      let full = state.chats?.filter(chat => chat.id === id)[0]?.messages;
      let newarr = [];
      full?.map((a) => {
        if (newarr?.filter((sd) => sd.id === a.id).length === 0)
          newarr?.push(a);
      });

      news?.map((a) => {
        if (newarr?.filter((sd) => sd.id === a.id).length === 0)
          newarr?.push(a);
      });
      newarr?.sort((a, b) => parseInt(new Date(a.sent_at).getTime()) - parseInt(new Date(b.sent_at).getTime()));
      let newsd = [];
      state.chats.map((ff, index) => {
        if (ff.id !== id) {
          newsd.push(ff)
        }
        else {
          newsd.push({
            ...state.chats.filter(chat => chat.id === id)[0],
            messages: [...new Set(newarr)],
          })
        }
      })
      if (state.activeChat?.id === action.payload?.messages[0]?.whatsapp_chat_id) {
        active = {
          ...state.activeChat,
          messages: [...new Set(newarr)],
        }
      }
      return {
        ...state,
        activeChat: active,
        is_fetch: false,
        chats: newsd
      };

    }
    case "GET_CHAT_WA_RED": {
      let arr = []
      action.payload.map((m, index) => {
        if (index === 0) arr.push({ ...m })
        else if (m.messages) arr.push({ ...m })
      })
      return {
        ...state,
        chats: sortChats(arr),
      };
    }
    case "UPD_CHAT_WA_RED": {

      let active = state.activeChat;
      let chats = [];
      let newMes = [];
      let scr = state.scr;
      if (active && active?.id) {
        if (
          action.payload.filter(
            (newChat) => newChat.id === state.activeChat?.id
          )[0]?.messages[0]?.id !== active.messages[0]?.id
        ) {
          scr = true;
        } else scr = false;

        newMes = action.payload
          .filter((newChat) => newChat.id === state.activeChat?.id)[0]
          ?.messages.filter(
            (a) =>
              active?.messages?.filter((f) => f.id === a.id).length === 0
          );

        active.messages = [...active.messages, ...newMes];
      }
      action.payload.map((a) => {
        if (state.activeChat && a.id === state.activeChat.id) {
          active = getUpdatedChat(state.chats.filter((s) => s.id === a.id)[0], a)
          chats.push(getUpdatedChat(state.chats.filter((s) => s.id === a.id)[0], a));
        } else
          chats.push(
            getUpdatedChat(state.chats.filter((s) => s.id === a.id)[0], a)
          );
      });
      if (chats.length !== state.chats.length || isNewMess(chats, state.chats)) {
        if (action.not === true) {

          let not = new Audio(WA);
          not.volume = 0.5
          not.play()
        }
      }
      return {
        ...state,
        chats: [{ ...chats[0] }, ...sortChats(chats.filter((s, ind) => ind !== 0))],
        // activeChat: active,
        //  sec:!state.sec,
        // refs: !state.refs,

      };
    }
    case "READ_CHAT": {
      let chats = [];
      state.chats.map((a) => {
        if (a.id === action.id) {
          chats.push({ ...a, unread: 0 });
        } else {
          chats.push(a);
        }
      });
      let act = state.activeChat
      if (act?.id) {
        act = { ...state.activeChat, unread: 0 }
      }
      return {
        ...state,
        chats: chats,
        activeChat: act
      };
    }
    case "READ_CHAT_PUSHER": {
      let chats = [];
      state.chats.map((a) => {
        if (a.id === action.id) {
          chats.push({ ...a, unread: 0 });
        } else {
          chats.push(a);
        }
      });
      let act = state.activeChat
      if (act?.id === action.id) {
        act = { ...state.activeChat, unread: 0 }
      }
      return {
        ...state,
        chats: chats,
        activeChat: act
      };
    }
    case "CHAT_ID": {
      let first_var = action.noScroll ? state.first : !state.first
      let active = state.activeChat
      if (action.payload === null) {
        active = null
      }

      else if (state.chats.filter((s) => s.id === action.payload.id).length === 0) {

        active = action.payload
      }
      else {
        active = state.chats.filter((s) => s.id === action.payload.id)[0]
      }
      return {
        ...state,
        activeChat: active,

        is_fetch: null,
        first: first_var
      };
    }
    case "LOGIN_SAGA":
      return {
        ...state,
        username: action.payload?.username,
        password: action.payload?.password,
      };
    case "TOKEN_WHATSAPP":
      return {
        ...state,
        userTokenWhatsapp: action.payload,
      };
    case "CHANGE_NAME": {
      let arr = []
      let contact_id = null
      state.chats.map((s) => {
        if (s.id === state?.activeChat?.id) {
          contact_id = s.whatsapp_contact.id
          arr.push({ ...s, name: action.payload.name, whatsapp_contact: { ...s?.whatsapp_contact, name: action.payload.name, whatsapp_contact_company_id: action.payload.cid, whatsapp_contact_role_id: action.payload.rid } })
        }
        else {
          arr.push(s)
        }
      })
      let arrs = [];
      if (state.contacts.filter((s) => s.id === contact_id).length > 0) {
        state.contacts.map((s) => {
          if (s.id === contact_id) {
            arrs.push({ ...s, name: action.payload.name, whatsapp_contact_company_id: action.payload.cid, whatsapp_contact_role_id: action.payload.rid })
          }
          else {
            arrs.push(s)
          }
        })

      }
      else {
        arrs = state.contacts
      }
      return ({
        ...state,
        chats: sortChats(arr),
        activeChat: arr.filter((s) => s.id === state.activeChat?.id)[0],
        contacts: arrs
      })
    }
    case "GET_MES_RED": {
      let activeC, qoute = null, arr = []
      if (state.activeChat?.id === action.cid) {
        activeC = { ...state.activeChat, messages: [...action.payload, ...state.activeChat.messages] }
        qoute = action.id
      }

      state.chats.map((s) => {
        if (s.id === action.cid) {
          arr.push({ ...s, messages: [...action.payload, ...s.messages] })
        }
        else {
          arr.push(s)
        }
      })

      return ({
        ...state,
        chats: sortChats(arr),
        activeChat: activeC,
        qoutedMessage: qoute,
        is_fetch: false
      })
    }
    case "GET_MES": {
      return ({
        ...state,
        is_fetch: true
      })
    }
    case "GET_ROLS": {
      return ({
        ...state,
        roles: action.payload
      })
    }
    case "GET_COMPA": {
      return ({
        ...state,
        companies: action.payload
      })
    }
    case "GET_CONTACT": {
      return ({
        ...state,
        contacts: action.payload
      })
    }
    case "UPD_CONTACT": {
      let arr = [];
      state.contacts.map((s) => {
        if (s.id === action.payload.id) {
          arr.push(action.payload)
        }
        else arr.push(s)
      })
      return ({
        ...state,
        contacts: arr
      })
    }
    default:
      return state;
  }
};
