
const initialState = {

    transfers:[],
    pageFollower:[],
    newLang:{date:null,name:"",request_type:null,source:null,country_id:null,client:{name:"",photo:null,id:null}, beneficiary_contacts:[{
      "number": "",
      "contact_method_id": 1,
      "is_agnet_number": true
  }],amount_of_money:null,payment_type:null,commission_type_id:null,recipients:[]},
    mode:"read",
    open:false,
    modalData:{name:"",icon:null,code:""},
    Active_Ids:[],
    loading:false,
    request_running:false,
    request_data:null,
    message:"",
    error:false,
    view_language:"en",
    loading_percent:0,
    sucess_message:null,
    img_upload:{type:"",status:false},
    countries:[],
    clients:[],
    client_load:false,
    transaction_payments:[],
    transaction_commissions:[],
    communications_type:[]
  }
  
   export const TransferReducer=(state = initialState, { type, payload, message }) => {
    switch (type) {
      case "REGION_ASYNC":{
        return({
          ...state,
          countries:payload
        })
      }
      case "TRF-TYPES":{
        return({...state, transaction_payments:payload})
      }
      case "TRF-COMM":{
        return({...state, transaction_commissions:payload})
      }
      case "TRF-COMM-TYPE":{
        return({...state, communications_type:payload})
      }
      case "CLIENT_ASYNC":{
        return({
          ...state,
          clients:payload,
          client_load:false
        })
      }
      case "CLIENT_ASYNC_SAGA":{
        return({
          ...state,
          client_load:true
        })
      }
      case "IMG-UPLOAD-TRF":{
        return({
          ...state,
          img_upload:{...payload}
        })
      }
      case "SET-TRF-RED":{
        let arr=payload
       
        return({
          ...state,
          transfers:[...arr],
         
        })
      }
      case "SET-LANGUAGE-R":{
        
        return({
          ...state,
          sucess_message:parseInt(payload)===0?"Activated":"Deactivated"
        })
      }
      case "GENERAL-LOADING":{
        return({
          ...state,
          loading:true,
          loading_percent:payload?payload:0
        })
      }
      case "request-running-TRF":{
        return({
          ...state,
          request_running:true,
          request_data:payload
        })
      }
      case "GENERAL-ERR":{
        return({
          ...state,
          request_running:false,
          request_data:payload.data,
          loading:false,
          error:true,
          message:payload.message
        })
      }
      case "request-running-red-TRF":{
        
        return({
          ...state,
          request_running:false,
          request_data:payload,
          error:false,
          
  
          message:""
        })
      }
      case "VIEW-LANGUAGE":{
        return({
          ...state,
          view_language:payload
        })
      }
      case "GENERAL-DONE":{
        return({
          ...state,
          loading:false,
          loading_percent:0,
          open:false
  
        })
      }
      case "LEVEL-DONE":{
        return({
          ...state,
          loading:false
        })
      }
      case "GET-TRF-REDUCER":{
        let langs = []
       
        return({...state,trasfers:[...payload],request_data:null,request_running:false,error:false,message:""})
      }
      case "NEW-TRF":{
        return{
          ...state,
          newLang:{...payload}
        }
      }
      case "OPEN-TRF":{
        return({
          ...state,
          open:payload
        })
      }
      case "MODE-TRF":{
        return({
          ...state,
          mode:payload,
          sucess_message:null
        })
      }
      case "MOD-DATA-TRF":{
        return({
          ...state,
          modalData:{...payload}
        })
      }
      
    default:
      return state
    }
  }
    