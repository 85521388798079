import { store } from "../redux/store";

export const BACKEND_URL = "";
export const BACKEND_FIles_URL = process.env.REACT_APP_BASE_FILE_URL;
const base_user =
  store?.getState().whatsapp.fetched_url === true
    ? store?.getState()?.whatsapp.base_user
    : process.env.REACT_APP_USER_BASE_URL;
const base =
  store?.getState().whatsapp.fetched_url === true
    ? store?.getState()?.whatsapp.base_url
    : process.env.REACT_APP_BASE_URL;
