import axios from 'axios';
import React, { useRef } from 'react'
import { useState } from 'react';
import * as types from "../../../redux/auth/constants"
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { logout } from '../../../redux/auth/actions';
import store from '../../../redux/store';
import { withRouter } from 'react-router-dom';
function Setting(props) {
  const dispatch=useDispatch()
  const getAdmin=async ()=>{
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    const base_user =
    store?.getState().whatsapp.fetched_url === true
      ? store?.getState()?.whatsapp.base_user
      : process.env.REACT_APP_USER_BASE_URL;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
   let a= await AxiosInstace.get("/api/v1/get_admin_mobile_phone")
   setNum(a.data.data)
  }
    let [num,setNum]=useState("")
    const save=async ()=>{
        let a
        const token = store.getState()?.whatsapp?.userTokenWhatsapp;
        const base_user =
          store?.getState().whatsapp.fetched_url === true
            ? store?.getState()?.whatsapp.base_user
            : process.env.REACT_APP_USER_BASE_URL;
        let AxiosInstance = axios.create({
          baseURL: `${base_user}`,
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        });
        const id = toast.loading("Please wait...")
        try{
           
            a=await AxiosInstance.get(`/api/v1/set_admin_mobile_phone/${num}`);
            toast.update(id, { render: "Done", type: "success", isLoading: false,autoClose:true,closeOnClick:true  });
            dispatch({ type: "GET_WA_CHATS", isFirst: true })
        }
        catch(e){
            toast.update(id, { render: "Failed! check the Phone Number And try again", type: "error", isLoading: false,autoClose:true,closeOnClick:true });
        }
    }
    const changeSecret=async ()=>{
        let a
        const token = store.getState()?.whatsapp?.userTokenWhatsapp;
        const base_user =
          store?.getState().whatsapp.fetched_url === true
            ? store?.getState()?.whatsapp.base_user
            : process.env.REACT_APP_BASE_URL_BRODCAST;
        let AxiosInstance = axios.create({
          baseURL: `${base_user}`,
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        });
        const id = toast.loading("Please wait...")
        try{
          logout(() => { ; })
            a=await axios.get(store.getState().whatsapp.fetched_url === true
            ? store.getState()?.whatsapp.base_url +
                "/wallet-users-backend/public" +
                "/broadcast-new-secrets"
            : types.remote_server + "/broadcast-new-secrets");
            toast.update(id, { render: "Done", type: "success", isLoading: false,autoClose:true,closeOnClick:true });
            logout()
        }
        catch(e){
          toast.update(id, { render: "Done", type: "success", isLoading: false,autoClose:true,closeOnClick:true });
          
          dispatch({ type: "log_out" })
          localStorage.removeItem("user");
          props.history.push("/");
          props.history.push("/auth/signup"); }
    }
    const chats=useSelector((state)=>state.whatsapp.chats);
    useEffect(()=>{
        
       
    },[props.open])
    useEffect(()=>{
      getAdmin()
    },[])
  return (
    <>
      {<div onClick={(e) => {props.close();}} className={`lang-modalDisable ${props.open && "open"}`}></div>}
    <div className={`lang-modal whats-modal ${props.open && "open"} ${"nopad"}`}>
    <div className='whats-page'>
        <div className='setting-page'>
            <div className='setting-header'>General Setting</div>
            <div className='hr-element'><div/></div>
            <div className='whatsap-admin'>
                <div className='label-admin'>WhatsApp Admin</div>
                <div className='input-admin-wrap'>
                    <input value={parseInt(num)} onChange={(e)=>{setNum(e.target.value)}} type={"number"} placeholder='Admin Phone Number' className='input-admin' />
                </div>
            </div>
            <div className='save-button-setting'>
                <div className='save-contact sec-button' onClick={()=>changeSecret()}>Brodcast New Secrets </div>
        <div className='save-contact' onClick={()=>save()}>Save</div>
    </div>

        </div>
       
    </div>
  
     </div>
  </>
  )
}

export default withRouter(Setting)