import { all, fork, put, takeEvery } from "@redux-saga/core/effects";
import AxiosInstance, { AxiosFileInstance } from "../../helpers/axiosInstance";
import { toast } from "react-toastify";
import store from "../store";
import axios from "axios";
import { upload } from '../../uploadService'
import { getLanguagesFiles } from "./actions";
function* setFiles(action) {
    try {
        yield action.payload.langs.map(lang => {
            let code = lang.language_code.toUpperCase();
            let file_array = action.payload.data.map(one => { return { [`${one.key}`]: one[`${code}`] } })
            let file_object = file_array.reduce(function (result, item) {
                var key = Object.keys(item)[0];
                result[key] = item[key];
                return result;
            }, {});
            let file = new File([JSON.stringify(file_object)], code, { type: "application/json" })
            let formData = new FormData();
            upload(file).then(file_path => {
                store.dispatch({ type: "SET_FILE", payload: { lang: code, file: file_path } })
                store.dispatch({ type: "store_lang_file", payload: { lang: lang.language_code?.toLowerCase(), file:file_object } })
            })
      
        })

    } catch (e) {
    }
}
function* watchSetFiles() {
    yield takeEvery("UploadLanguagesFiles", setFiles);
}
export default function* TranslateSaga() {
    yield all([
        fork(watchSetFiles)
    ]);
}