
import accounting from  "../../../assets/svg/accounting.svg"
import doll from  "../../../assets/svg/doll.svg"
import turk from  "../../../assets/svg/turk.svg"
import syr from  "../../../assets/svg/syr.svg"
import Irq from "../../../assets/svg/irq.svg"
import leb from "../../../assets/svg/leb.svg"
const initialState = {
  view:"Countries",
  test:[],
  offices:[],
  pageFollower:["Employees"],
  newDepartment:{region_translations:[{region_name:"",language_code:"en"}],map_location:"",country_id:"",post_code:"",lat:"",long:"",location_desc:"",building:"",block:"",floor:"",no:"",phone1:"",phone2:"",gsm1:"",gsm2:"",website:"",mail:""},
  mode:"read",
  open:false,
  modalData:{name:"",icon:null,code:""},
  loading:false,
  Active_Ids:[],
  request_running:false,
  request_data:null,
  message:"",
  error:false,
  img_upload:{type:"",status:false},
  parent_ids:null,
  floading:false

}
 export const OfficesReducer=(state = initialState, { type, payload }) => {
  switch (type) {
    case "CORDES":{
      return({...state,
      newDepartment:{...state.newDepartment, region_latlongs:[{region_lat:payload.lat, region_long:payload.lng}]}
      })
    }
    case "OFF-POST-REDUCER":{
      return({
        ...state,
        parent_ids:payload,
        newDepartment:{...state.newDepartment,parent_region_id:payload.id,country_id:payload.country_id?payload.country_id:payload.id}
      })
    }
    case "CORD":{
      return({
        ...state,
        newDepartment:{...state.newDepartment, region_latlongs:payload.cor, phone_code:payload.phone_code}
      })
    }
    case "OFF-LOADING":{
      return({
        ...state,
        floading:true
      })
    }
    case "OFF-DONE":{
      return({
        ...state,
        floading:false
      })
    }
    case "IMG-UPLOAD-OFF":{
      return({
        ...state,
        img_upload:{...payload}
      })
    }
    case "request-running-OFF":{
      return({
        ...state,
        request_running:true,
        request_data:payload
      })
    }
    case "GENERAL-ERR-OFF":{
      return({
        ...state,
        request_running:false,
        request_data:payload.data,
        error:true,
        message:payload.message
      })
    }
    case "request-running-OFF-red":{
      
      return({
        ...state,
        request_running:false,
        request_data:payload,
        error:false,
        message:""
      })
    }
    case "GENERAL-LOADING-OFF":{
      return({
        ...state,
        loading:true
      })
    }
    case "VIEW-OFF":{
      return({
        ...state,
        view_language:payload
      })
    }
    case "GENERAL-DONE-OFF":{
      return({
        ...state,
        loading:false,
        open:false,
        

      })
    }
    case "LEVEL-DONE-OFF":{
      return({
        ...state,
        loading:false
      })
    }
    case "GET-OFF-REDUCER":{
      const arr =[]
      
      return({...state,offices:payload,request_data:null,request_running:false,error:false,message:""})

    }
    case "Active-add":{
      return({
        ...state,
        Active_Ids:payload
      })
    }
  case "COM-VIEW":
    return { 
      ...state,
       view:payload,
      pageFollower:[payload]
      }
    case "NEW-OFF":{
      
    
      return{
        ...state,
        newDepartment:{...payload}
      }
    }
    case "OPEN-OFF":{
      return({
        ...state,
        open:payload
      })
    }
    case "MODE-OFF":{
      return({
        ...state,
        mode:payload
      })
    }
    case "MOD-DATA-OFF":{
      return({
        ...state,
        modalData:{...payload}
      })
    }
    
  default:
    return state
  }
}
  