import React from 'react'
import { useDispatch } from 'react-redux';

const SearchComponent = (props) => {
  const dispatch = useDispatch()
  return (
    <div className="search-component">
      <svg data-src={props.svg}></svg>
      <input onChange={(e) => { props.onChange(e); }} placeholder={props.placeholder} />
    </div>
  )
}
export default SearchComponent