import React from 'react'
import { useSelector } from 'react-redux'
import { trans } from '../../../../../Internationalization'
function NumCatsICon(props) {
  const active = { props }
  const view = useSelector((state) => state.currency.view)
  return (
    <div style={{ marginRight: "20px" }} className='icon-lang' onClick={() => props.change()}>
      <div className='icon-lang-desc'>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
          <defs>
            <filter id="Ellipse_319" x="0" y="0" width="24" height="24" filterUnits="userSpaceOnUse">
              <feOffset dy="3" input="SourceAlpha" />
              <feGaussianBlur stdDeviation="3" result="blur" />
              <feFlood floodOpacity="0.2" />
              <feComposite operator="in" in2="blur" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Ellipse_319)">
            <circle id="Ellipse_319-2" data-name="Ellipse 319" cx="3" cy="3" r="3" transform="translate(9 6)" fill="#8E8E8E" />
          </g>
        </svg>
        <div className='icon-lang-desc-bu tfs'>{trans("num_cat")}</div>
      </div>
      <svg className={`${props.active && "activated-hrm"}`} id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
        <g id="Mask_Group_99" data-name="Mask Group 99">
          <g id="number-blocks">
            <path id="Path_16060" data-name="Path 16060" d="M1.81,12.931H2.845v.517H1.81Z" fill="#8E8E8E" />
            <path id="Path_16061" data-name="Path 16061" d="M3.879,10.345H.776A.776.776,0,0,0,0,11.121v3.1A.776.776,0,0,0,.776,15h3.1a.776.776,0,0,0,.776-.776v-3.1A.776.776,0,0,0,3.879,10.345Zm-.517,1.81H2.845V11.9H1.81v.517H3.1a.259.259,0,0,1,.259.259v1.034a.259.259,0,0,1-.259.259H1.552a.259.259,0,0,1-.259-.259V11.638a.259.259,0,0,1,.259-.259H3.1a.259.259,0,0,1,.259.259Z" fill="#8E8E8E" />
            <path id="Path_16062" data-name="Path 16062" d="M.776,9.828h3.1a.776.776,0,0,0,.776-.776v-2.6a1.281,1.281,0,0,1-.781-.366L2.96,5.172H.776A.776.776,0,0,0,0,5.948v3.1A.776.776,0,0,0,.776,9.828Zm.776-1.552H2.586a.259.259,0,0,0,0-.517H2.069a.259.259,0,0,1,0-.517h.517a.259.259,0,1,0,0-.517H1.552V6.207H2.586A.776.776,0,0,1,3.164,7.5a.776.776,0,0,1-.578,1.293H1.552Z" fill="#8E8E8E" />
            <path id="Path_16063" data-name="Path 16063" d="M12.931,12.931h-.517a.259.259,0,0,0,0,.517h.517a.259.259,0,0,0,0-.517Z" fill="#8E8E8E" />
            <path id="Path_16064" data-name="Path 16064" d="M9.052,10.345h-3.1a.776.776,0,0,0-.776.776v3.1A.776.776,0,0,0,5.948,15h3.1a.776.776,0,0,0,.776-.776v-3.1A.776.776,0,0,0,9.052,10.345Zm-1.04,3.677-.505-.112L7.953,11.9h-.87l.145.436-.491.163-.259-.776a.259.259,0,0,1,.246-.34H8.276a.259.259,0,0,1,.253.315Z" fill="#8E8E8E" />
            <path id="Path_16065" data-name="Path 16065" d="M5.7,6.087a1.281,1.281,0,0,1-.53.314v2.65a.776.776,0,0,0,.776.776h3.1a.776.776,0,0,0,.776-.776v-3.1a.776.776,0,0,0-.776-.776H6.617Zm1.534.171-.2.984h.719V6.207h.517V7.241h.517v.517H8.276V8.793H7.759V7.759H6.724a.259.259,0,0,1-.253-.31l.259-1.293Z" fill="#8E8E8E" />
            <path id="Path_16066" data-name="Path 16066" d="M14.224,0h-3.1a.776.776,0,0,0-.776.776v3.1a.776.776,0,0,0,.776.776h3.1A.776.776,0,0,0,15,3.879V.776A.776.776,0,0,0,14.224,0Zm-.517,2.328a.259.259,0,0,1-.259.259H12.155V3.1h1.552v.517H11.9a.259.259,0,0,1-.259-.259V2.328a.259.259,0,0,1,.259-.259H13.19V1.552H11.638V1.034h1.81a.259.259,0,0,1,.259.259Z" fill="#8E8E8E" />
            <path id="Path_16067" data-name="Path 16067" d="M12.931,11.9h-.517a.259.259,0,0,0,0,.517h.517a.259.259,0,0,0,0-.517Z" fill="#8E8E8E" />
            <path id="Path_16068" data-name="Path 16068" d="M14.224,5.172h-3.1a.776.776,0,0,0-.776.776v3.1a.776.776,0,0,0,.776.776h3.1A.776.776,0,0,0,15,9.052v-3.1A.776.776,0,0,0,14.224,5.172Zm-.517,1.552H12.155v.517h1.293a.259.259,0,0,1,.259.259V8.534a.259.259,0,0,1-.259.259h-1.81V8.276H13.19V7.759H11.9a.259.259,0,0,1-.259-.259V6.466a.259.259,0,0,1,.259-.259h1.81Z" fill="#8E8E8E" />
            <path id="Path_16069" data-name="Path 16069" d="M14.224,10.345h-3.1a.776.776,0,0,0-.776.776v3.1a.776.776,0,0,0,.776.776h3.1A.776.776,0,0,0,15,14.224v-3.1A.776.776,0,0,0,14.224,10.345Zm-.517,2.845a.776.776,0,0,1-.776.776h-.517a.772.772,0,0,1-.574-1.293.773.773,0,0,1,.574-1.293h.517a.772.772,0,0,1,.574,1.293.771.771,0,0,1,.2.517Z" fill="#8E8E8E" />
            <path id="Path_16070" data-name="Path 16070" d="M4.24,5.721a.8.8,0,0,0,1.1,0L7.531,3.527a.776.776,0,0,0,0-1.1L5.337.235a.776.776,0,0,0-1.1,0L2.045,2.429a.776.776,0,0,0,0,1.1ZM3.615,2.429a.259.259,0,0,1,.076-.183l.366-.366a.266.266,0,0,1,.366,0L6.069,3.527,5.7,3.892,4.24,2.429l-.107.107v.624H3.615Z" fill="#8E8E8E" />
          </g>
        </g>
      </svg>
    </div>
  )
}

export default NumCatsICon