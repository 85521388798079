import { all, fork, put, takeEvery } from "@redux-saga/core/effects";
import AxiosInstance, {
  AxiosFileInstance,
} from "../../../helpers/axiosInstance";
import { toast } from "react-toastify";
import { ADD_JOB_URL, GET_JOB_URL, UPD_JOB_URL } from "./constants";
import store from "../../store";
import { GET_LANGS_URL } from "../../LinkedLanguages/constants";
function* getJobs(action) {
  let response;
  try {
    yield put({ type: "GENERAL-LOADING-NUM" });
    response = yield AxiosInstance.post(
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url +
            "/wallet-market-backend/public" +
            GET_JOB_URL
        : process.env.REACT_APP_BASE_URL +
            "/wallet-market-backend/public" +
            GET_JOB_URL,
      JSON.stringify({
        is_for_currency: 1,
      })
    );
    yield put({ type: "GET-NUM-REDUCER", payload: response.data.data });
    yield put({ type: "GENERAL-DONE-NUM" });
    yield put({ type: "GENERAL-DONE-NUM" });
  } catch (e) {
    yield put({ type: "GENERAL-DONE-NUM" });
    // if(e.message){
    //     toast.error(e.message)
    // }
    // else
    // toast.error("failed! please check conection")
    toast.error("failed! BACK END ERROR");
  }
}

function* watchgetJobs() {
  yield takeEvery("GET-NUM", getJobs);
}
function* AddJobs(action) {
  let response, responses;
  try {
    yield put({ type: "GENERAL-DONE-NUM" });
    yield put({ type: "request-running-num", payload: action.payload });
    response = yield AxiosInstance.post(
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url +
            "/wallet-market-backend/public" +
            ADD_JOB_URL
        : process.env.REACT_APP_BASE_URL +
            "/wallet-market-backend/public" +
            ADD_JOB_URL,
      JSON.stringify({
        ...action.payload,
        is_for_currency: 1,
        is_default: 0,
        is_for_length: 0,
        is_for_pieces: 0,
        is_for_size: 0,
        is_for_weight: 0,
      })
    );

    yield put({ type: "request-running-num-red", payload: action.payload });
    setTimeout(() => {
      store.dispatch({ type: "request-running-num-red", payload: null });
      store.dispatch({
        type: "EDIT-NUM-ADD",
        payload: response.data.data[`measurement units`],
      });
    }, 2000);
    yield put({ type: "GENERAL-DONE-NUM" });
  } catch (e) {
    yield put({
      type: "GENERAL-ERR-num",
      payload: { data: action.payload, message: e.message },
    });

    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchAddJobs() {
  yield takeEvery("ADD-NUM", AddJobs);
}
function* UpdateJobs(action) {
  let response, responses;
  try {
    yield put({ type: "GENERAL-DONE-NUM" });
    yield put({
      type: "request-running-num",
      payload: { ...action.payload, is_for_currency: 1, is_default: 0 },
    });
    response = yield AxiosInstance.post(
      store?.getState().whatsapp.fetched_url === true
        ? store?.getState()?.whatsapp.base_url +
            "/wallet-market-backend/public" +
            UPD_JOB_URL
        : process.env.REACT_APP_BASE_URL +
            "/wallet-market-backend/public" +
            UPD_JOB_URL,
      JSON.stringify(action.payload)
    );
    yield put({ type: "request-running-num-red", payload: action.payload });
    setTimeout(() => {
      store.dispatch({ type: "request-running-num-red", payload: null });
      store.dispatch({
        type: "EDIT-NUM-UPD",
        payload: response.data.data[`measurement units`],
      });
    }, 2000);
    yield put({ type: "GENERAL-DONE-NUM" });

    yield put({ type: "GENERAL-DONE-NUM" });
  } catch (e) {
    yield put({
      type: "GENERAL-ERR-num",
      payload: { data: action.payload, message: e.message },
    });

    if (e.message) {
      toast.error(e.message);
    } else toast.error("failed! please check conection");
  }
}

function* watchUpdJobs() {
  yield takeEvery("UPD-NUM", UpdateJobs);
}

export function* NumsSaga() {
  yield all([fork(watchgetJobs), fork(watchUpdJobs), fork(watchAddJobs)]);
}
